import co from 'co';

import {calculateDistanceFromStart, CalculateDistanceProps} from '../../../App';
import {calculateDuration} from '../../utils/routeUtils';
import Log from '../../utils/Log';

interface DistanceTimeInterface {
  index: number;
  routeLength: number;
  arrivingTime: number;
}

function* timeGenerator(coordinates: number[], routeCoords: number[][], timeArray: number[]) {
  const distanceProps: CalculateDistanceProps = yield calculateDistanceFromStart(coordinates, routeCoords);
  const arrivingTime = yield calculateDuration(0, distanceProps.index, timeArray);

  return {...distanceProps, arrivingTime};
}

export const PointTimeAndDistanceGenerator = (coordinates: number[], routeCoords: number[][], timeArray: number[]): Promise<DistanceTimeInterface | never> => {
  if (!coordinates || !routeCoords || !timeArray) {
    return Promise.reject('some parameters not found');
  }
  return co(timeGenerator(coordinates, routeCoords, timeArray))
    .catch(e => {
      Log.error(e);
    });
};
