import React, {useCallback, useContext, useState} from 'react';
import MapBoxHandler from 'pages/maps/MapBoxHandler';
import {Avatar, createStyles, makeStyles, Theme} from '@material-ui/core';
import {useAddPoint} from 'pages/maps/mapHooks/addPointHookt';
import {observer} from 'mobx-react-lite';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

import {AddressInfoInterface} from 'interfaces/address/AddressInfo';
import {PointInterface} from 'interfaces/PointInterface';


import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';


import {Feature} from '@turf/turf';

import {AddingPointType, SearchAddressContext} from 'shared/components/Address/SearchAddressProvider';
import {pointFromAddress} from 'shared/utils/turfUtils';
import {useRootStore} from 'shared/stores/RootStore';
import AddressDisplayName from 'shared/components/Address/AddressDisplayName';

import {ForecastWeatherInterface} from '../../../interfaces/weather/ForecastWeatherInterface';
import {getForecast} from '../../requests/weather.request';
import If from '../If/If';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedItem: {backgroundColor: theme.palette.grey.A100, borderRadius: '4px'},
    nonSelected: {backgroundColor: 'none'},
    white: {
      backgroundColor: theme.palette.common.white,
    },
  }));

const SearchResultList: React.FC = () => {
  const {addressStore,favoritePlacesStore} = useRootStore();
  const {hide, options} = useContext(SearchAddressContext);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const classes = useStyles();
  const {addPointWithModal} = useAddPoint();

  const setAddress = useCallback((addressInfo: AddressInfoInterface) => {
    const point = pointFromAddress(addressInfo);

    // get forecast for point
    getForecast(point?.geometry?.coordinates as number[])
      .then(data => {
        if (point?.properties) {
          point.properties.forecast = (data as ForecastWeatherInterface).list;
        }
        //add point to map
        if(options.addingType === AddingPointType.AddPointToMap) {
          addPointWithModal(point as Feature<PointInterface>);
        }
        //add point to favorite places
        if(options.addingType === AddingPointType.AddToFavorites) {
          favoritePlacesStore.addNewPlace(point as Feature<PointInterface>);
        }
        hide();
      });
  }, [addPointWithModal, favoritePlacesStore, hide, options.addingType]);

  const moveMapToPoint = useCallback((index, point) => {
    setSelectedIndex(index);
    const {lon, lat} = point;

    MapBoxHandler.flyTo([lon, lat], 15);
  }, []);

  return (
    <List dense>
      {addressStore.addresses.map((item: AddressInfoInterface, index: number) => (
        <ListItem key={index} className={index === selectedIndex ? classes.selectedItem : classes.nonSelected}>
          <ListItemIcon>
            {item?.icon &&
						<Avatar
							className={index === selectedIndex ? classes.white : classes.nonSelected}
						>
							<img alt="" src={item?.icon}/>
						</Avatar>
            }
          </ListItemIcon>
          <ListItemText
            secondary={<AddressDisplayName {...item} />}
            primaryTypographyProps={{component: 'div'}}
          />
          <Divider orientation="vertical" color={'primary'}/>
          <ListItemIcon>
            <IconButton onClick={() => setAddress(item)} color={'primary'}>
              <AddIcon/>
            </IconButton>
          </ListItemIcon>
          <ListItemSecondaryAction>
            <If condition={options.addingType === AddingPointType.AddPointToMap}>
              <IconButton edge="end" onClick={() => moveMapToPoint(index, item)}>
                <SearchIcon/>
              </IconButton>
            </If>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};


export default observer(SearchResultList);
