import React, {useRef} from 'react';
import TimeInput from 'material-ui-time-picker';

type Props = {
  onChange: (date) => void;
  date?: Date;
}

const RouteTimeInput: React.FC<Props> = ({onChange, date}) => {
  const ref = useRef();

  return (
    <TimeInput
      ref={ref}
      mode="24h"
      value={date}
      onChange={onChange}
    />
  );
};

export default RouteTimeInput;
