import {Feature} from '@turf/turf';

import {http} from '../functions/HttpAuth';
import {PointInterface} from '../../interfaces/PointInterface';

const favoriteUrl = '/api/favorites';

export const getFavorites = () => {
  return http.get(favoriteUrl);
};

export const addFavoritePlace = (point: Feature<PointInterface>, isDefault = false) => {
  return http.post(favoriteUrl, {point, isDefault});
};

export const deletePlace = (_id: string) => {
  return http.delete(favoriteUrl + `/${_id}`);
};

export const setAsHomePoint = (_id: string) => {
  return http.put(favoriteUrl + '/home-point', {_id});
};
