import React from 'react';
import {withRouter} from 'react-router-dom';

import List from '@material-ui/core/List';

import './style.css';
import UserIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import HomeIcon from '@material-ui/icons/Home';

import {DASHBOARD, MAP_LINK, MOTORCYCLES_LINK, PROFILE_LINK, USER_MAPS_LIST} from '../../const/NavigationLinks';

import {SubHeader} from './subheader';
import ListElement from './ListElement';
import LogoutItem from './LogoutItem';

const ListDrawer: React.FC = () => {
  return (
    <div className={'list-drawer'}>
      <List component="nav">
        <ListElement
          label={'Dashboard'}
          icon={<HomeIcon/>}
          url={DASHBOARD}/>
      </List>
      <List component="nav" subheader={<SubHeader label={'Maps'}/>}>
        <ListElement
          label={'New Route'}
          icon={<UserIcon/>}
          state={{newRoute: true}}
          url={MAP_LINK}/>
        <ListElement
          label={'My routes'}
          url={USER_MAPS_LIST}
          icon={<UserIcon/>}/>
      </List>
      <List component="nav" subheader={<SubHeader label={'Profile'}/>}>
        <ListElement
          label={'Garage'}
          icon={<UserIcon/>}
          url={MOTORCYCLES_LINK}/>
      </List>
      <List component="nav" subheader={<SubHeader label={'Account'}/>}>
        <ListElement
          label={'Profile'}
          url={PROFILE_LINK}
          icon={<UserIcon/>}/>
        <LogoutItem
          label={'Logout'}
          icon={<UserIcon/>}/>
      </List>
    </div>
  );
};

export default withRouter(ListDrawer);
