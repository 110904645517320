import React, {useCallback, useMemo} from 'react';
import {useObserver} from 'mobx-react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';

import AddressDisplay from '../../../../shared/components/Address/AddressDisplay';
import {useRootStore} from '../../../../shared/stores/RootStore';
import {Spacing} from '../../../../shared/const/spacing';
import {WayPointInsertingType} from '../../../../interfaces/PointInterface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.info.dark,
      marginRight: Spacing + 'px',
    },
  }));

export const StartFromHomeItem: React.FC = () => {
  const {favoritePlacesStore} = useRootStore();
  const {wayPointsStore} = useRootStore();
  const classes = useStyles();

  const addPoint = useCallback(
    () => {
      if (favoritePlacesStore.homePoint) {
        const point = favoritePlacesStore.homePoint.point;
        wayPointsStore.addWayPoint(point, WayPointInsertingType.AddOnBeginning);
      }
    },
    [favoritePlacesStore.homePoint, wayPointsStore],
  );

  const primaryLabel = useMemo(() => {
    if (wayPointsStore.wayPointsCount === 0) {
      return 'Start from home';
    }
  }, [wayPointsStore.wayPointsCount]);

  return useObserver(() => (
    <ListItem button onClick={addPoint}>
      <Avatar className={classes.avatar}>
        <HomeIcon/>
      </Avatar>
      <ListItemText
        primary={primaryLabel}
        secondary={<AddressDisplay addressInfo={favoritePlacesStore.homePoint?.point?.properties?.addressInfo}/>}
        secondaryTypographyProps={{component: 'div'}}
      />
    </ListItem>
  ));
};

export default StartFromHomeItem;
