export const EVENT_ADD_POINT = 'addPoint';
export const EVENT_MOVE_POINT = 'movePoint';
export const EVENT_ENTER_POINT = 'enterPoint';
export const EVENT_CLICK_POINT = 'enterPoint';
export const EVENT_ROUTE_LAYER_CLICK = 'enterLayerClick';
export const EVENT_WEATHER_POINT_CLICK = 'weatherPointClick';
export const EVENT_SHOW_MAP_CONTEXT_MENU = 'showContextMenu';

export enum mapEventType {
  AddPoint = 'addPoint',
  MovePoint = 'movePoint',
  ShowContextMenu = 'showContextMenu',
  LeaveContextMenu = 'leaveContextMenu',
  SelectWeatherPoint = 'selectWeatherPoint',
  EnterMapPoint = ' enterMapPoint',
  EnterFuelPoint = 'enterFuelPoint',
  LeaveMapPoint = 'leaveMapPoint',
  WayPointClick = 'wayPointClick',
  RouteClick = 'routeClick',
  OnRouteEnter = 'onRouteEnter',
  OnRouteLeave = 'onRouteLeave'
}

export enum MapEventObject {
  Map = 'map',
  WeatherModal = 'weatherModal',
  WayPoint = 'wayPointObject',
  FuelPoint='fuelPoint',
  Route = 'route'
}

export enum MapRouteDateDialog {
  openMapDialog = 'openMapDialog'
}
