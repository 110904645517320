import {toJS} from 'mobx';

import {LegsInterface, RouteInterface} from '../../interfaces/RoutesInterface';

import Log from './Log';


export const getPartOfArray = (startIndex: number, endIndex: number, arr: number[]): number[] =>
  arr.slice(startIndex, endIndex);

export function routeLegsClone(route: RouteInterface): LegsInterface[] {
  return toJS(route.properties.legs);
}

export function gerRouteCoordinates(route: RouteInterface): number[][] {
  return toJS(route.geometry.coordinates);
}

export const calculateDuration = (routeStartIndex: number, routeStopIndex: number, routeDuration: number[]): Promise<number> => {
  const part = getPartOfArray(routeStartIndex, routeStopIndex, routeDuration);

  if (!part.length) {
    Log.warn('nearestPointOnRouteWorker duration fail');
  }

  const duration = part.reduce((acc: number, val: number) => acc + val);
  return Promise.resolve(Math.round(duration));
};
