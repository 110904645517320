import {CircularProgress} from '@material-ui/core';
import React from 'react';

import {AddressInfoInterface} from 'interfaces/address/AddressInfo';

import AddressDisplay from 'shared/components/Address/AddressDisplay';
import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';


type Props = {
  addressInfo: AddressInfoInterface | undefined
}

export const AddressWrapped: React.FC<Props> =({addressInfo})=>{
	return (
		<ConditionalWrapper
			condition={!!addressInfo}
			skeleton={<CircularProgress color="inherit" size={80} style={{opacity: '0.1'}}/>}
		>
			<div style={{maxHeight: '80px', overflow: 'hidden', padding: '10%'}}>
				<AddressDisplay
					align={'center'}
					addressInfo={addressInfo}
					direction="column"
					variant={'subtitle2'}
				/>
			</div>
		</ConditionalWrapper>
	);
};
