import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {InjectedStylesInterface, StyleType} from '../../interfaces/Styles';
import {UserContext} from '../../providers/UserWrapper';

import UserPreferences from './UserPreferences';
import UserAvatarForm from './UserAvatarForm';
import ProfileForm from './ProfileForm';

type InjectedProps = InjectedStylesInterface

const Profile = (props) => {
  const {classes} = props as InjectedProps;
  const {profile} = useContext(UserContext);

  return (
    <div className={classes.container}>
      <UserAvatarForm avatar={profile.avatar}/>
      <UserPreferences title={'Profile'}>
        <ProfileForm/>
      </UserPreferences>
      <UserPreferences title={'Start route location'}>
        <div className={classes.buttonsContainer}>
          <Button>
            Change home address{' '}
          </Button>
          <Button>Show on map</Button>
        </div>
      </UserPreferences>
    </div>
  );
};

const styles: StyleType = () => ({
  container: {
    marginBottom: 8,
    padding: 8,
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8 * 2,
    padding: 8 * 2,
    justifyContent: 'flex-end',
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8 * 3,
  },
});

export default withRouter(withStyles(styles)(Profile));
