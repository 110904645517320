import React, {useCallback, useContext} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';


import Dialog from '@material-ui/core/Dialog';

import {PointInterface} from 'interfaces/PointInterface';

import AddIcon from '@material-ui/icons/Add';

import {Feature} from '@turf/turf';

import {useRootStore} from 'shared/stores/RootStore';
import AddressDisplay from 'shared/components/Address/AddressDisplay';
import {Distances} from 'shared/components/Distance/Distance';

import {PointContext} from '../MapProviders/MapPointProvider';
import {useAddPoint} from '../mapHooks/addPointHookt';

export const PointOrderModalChooser: React.FC = () => {
  const {pointModalVisible, tempPoint, hidePointModalChooser} = useContext(PointContext);
  const {addPointOnBeginning, addPointOnEnd, addPointByIndex} = useAddPoint();
  const {wayPointsStore} = useRootStore();

  const onLastClick = useCallback(() => {
    addPointOnEnd(tempPoint as Feature<PointInterface>);
    hidePointModalChooser();
  }, [addPointOnEnd, hidePointModalChooser, tempPoint]);

  const onFirstClick = useCallback(() => {
    addPointOnBeginning(tempPoint as Feature<PointInterface>);
    hidePointModalChooser();
  }, [addPointOnBeginning, hidePointModalChooser, tempPoint]);

  const closeModal = useCallback(() => {
    hidePointModalChooser();
  }, [hidePointModalChooser]);

  const addOnIndex = useCallback((index) => {
    hidePointModalChooser();
    addPointByIndex(tempPoint as Feature<PointInterface>, index + 1);
  }, [addPointByIndex, hidePointModalChooser, tempPoint]);

  return (
    <Dialog open={pointModalVisible} maxWidth={'sm'}>
      <DialogTitle>Add new point on the route</DialogTitle>
      <DialogContent>
        <Timeline align={'left'}>
          <RouteItem onClick={onFirstClick} label={'Add as starting point'} showConnector={true}/>
          {wayPointsStore.wayPoints.map((point, index) => {
            const {properties} = point;
            return (
              <React.Fragment key={index}>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <AddressDisplay addressInfo={properties?.addressInfo}/>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" variant="outlined"/>
                    <TimelineConnector/>
                  </TimelineSeparator>
                  <TimelineContent>
                    <Distances distances={properties?.distance}/>
                  </TimelineContent>
                </TimelineItem>
                {index !== wayPointsStore.wayPointsCount - 1 && (
                  <TimelineItem>
                    <TimelineOppositeContent/>
                    <TimelineSeparator>
                      <div onClick={() => addOnIndex(properties?.index)}>
                        <TimelineDot color="primary" variant="outlined"><AddIcon/></TimelineDot>
                      </div>
                      <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Button onClick={() => addOnIndex(properties?.index)}>Add between</Button>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </React.Fragment>
            );
          })}
          <RouteItem onClick={onLastClick} label={'Add as finish point'} showConnector={false}/>
        </Timeline>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};


type RouteItemType = {
  onClick: () => void;
  label: string;
  showConnector: boolean;
}

const RouteItem: React.FC<RouteItemType> = ({onClick, label, showConnector = true}) => {

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={'primary'} variant="outlined">
          <AddIcon/>
        </TimelineDot>
        {showConnector && <TimelineConnector/>}
      </TimelineSeparator>
      <TimelineContent>
        <Button onClick={onClick}>{label}</Button>
      </TimelineContent>
    </TimelineItem>
  );
};
