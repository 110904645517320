import {http} from '../functions/HttpAuth';

export const createMap = mapData => http.post('/api/map/', mapData);

export const updateMap = mapData => http.put('/api/map/', mapData);

export const loadUserMaps = (): Promise<any> => http.get('/api/map/');
export const deleteUserMaps = (_id: string): Promise<any> => http.post('/api/map/remove', {_id});
export const updateUserMap = (_id: string, isFav: boolean): Promise<any> => http.post('/api/map/update', {_id, isFav});

export const createThumbnail = (mapSource, width = 800, height = 600, withPoints = true, withRoutes = true): Promise<{thumbFilename: string}> => {
  const url = `${process.env.REACT_APP_IMAGE_API}/map/thumbnail`;
  const body = {
    mapSource,
    width,
    height,
    withPoints,
    withRoutes,
  };

  return http.post(url, body);
};

export const loadUserMapRecord = (slug: string) => http.get(`/api/map/${slug}`);
