import React, {useCallback, useContext, useEffect, useState} from 'react';

import {
  getUserHomeLocationRequest,
  setHomeLocationRequest,
  UserHomeLocationProps,
} from '../shared/requests/location.requests';
import Log from '../shared/utils/Log';
import {doNothing} from '../shared/utils/functionsUtils';
import {useLogoutListener} from '../hooks/useLogoutListener';

import {AuthContext} from './AuthWrapper';

export interface UserLocationProps {
  userHomeLocationData?: UserHomeLocationProps;
  updateUserHomeLocation: () => void;
}

export const initialUserLocation: UserLocationProps = {
  userHomeLocationData: undefined,
  updateUserHomeLocation: doNothing,
};

interface GeolocationPositionProps {
  coords: {
    latitude: number;
    longitude: number;
  }
}

export const UserHomeLocationContext = React.createContext<UserLocationProps>(initialUserLocation);

export const UserLocationWrapper = ({children}) => {
  const [userHomeLocationData, setUserLocationData] = useState<UserHomeLocationProps | undefined>(initialUserLocation.userHomeLocationData);
  const {isAuth} = useContext(AuthContext);

  const getPositionFromNavigator = (): Promise<GeolocationPositionProps> => {
    Log.info('Getting position from browser navigator');
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    );
  };
  const setNewStartingLocation = useCallback((point) => {
    setHomeLocationRequest(point)
      .then(() => {
        Log.info('setNewStartingLocation');
      });
  }, []);

  const getLocationOnError = useCallback(async () => {
    try {
      const navigatorLocation = await getPositionFromNavigator();
      Log.info(`setting location: ${navigatorLocation.coords.latitude}, ${navigatorLocation.coords.longitude}`);
      const coordinates = [navigatorLocation.coords.longitude, navigatorLocation.coords.latitude];
      await setNewStartingLocation(coordinates);
    } catch (e) {
      Log.error('navigator browser error');
    }
  }, [setNewStartingLocation]);

  const getHomeLocation = useCallback(async () => {
    try {
      const location = await getUserHomeLocationRequest();
      setUserLocationData(location);
    } catch (e) {
      Log.error('no user starting location set');
      await getLocationOnError();
    }
  }, [getLocationOnError]);


  const updateUserHomeLocation = useCallback(doNothing, []);

  useEffect(() => {
    (isAuth && !userHomeLocationData) && getHomeLocation();
  }, [getHomeLocation, isAuth, userHomeLocationData]);

  const onLogout = useCallback(() => {
    setUserLocationData(undefined);
  }, []);

  useLogoutListener(onLogout);

  return (
    <UserHomeLocationContext.Provider
      value={{
        userHomeLocationData,
        updateUserHomeLocation,
      }}>{children}</UserHomeLocationContext.Provider>
  );
};
