import React, {useCallback} from 'react';
import {Feature, Layer} from 'react-mapbox-gl';
import {observer} from 'mobx-react-lite';

import {useRootStore} from 'shared/stores/RootStore';
import {randomString} from 'shared/functions/randomString';

import {clearMapEvent, selectWeatherPoint} from '../MapEvents';
import {getForecastData} from '../../../shared/utils/forecastUtils';


const layerLayout = {
  'icon-image': '{icon}',
  'text-field': '{temp}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [1, 0.9],
  'icon-offset': [20, 0],
  'icon-anchor': 'left',
  'text-anchor': 'top-left',
};

interface Props {
  id: string;
  before: string;
}

export const WeatherLayer: React.FC<Props> = ({id, before}) => {

  const {wayPointsStore} = useRootStore();

  const onClick = useCallback((point) => {
    clearMapEvent();
    selectWeatherPoint(point);
  }, []);

  const displayWeatherForecast = (weatherPoint) => {
    const properties = getForecastData(weatherPoint);
    return <Feature
      key={randomString()}
      onClick={() => onClick(weatherPoint)}
      coordinates={weatherPoint.geometry.coordinates}
      properties={properties}
    />;
  };

  return (
    <Layer
      id={id}
      key={id}
      before={before}
      type="symbol"
      layout={layerLayout}
    >
      {wayPointsStore.mergedPoints.map((point) => (
        displayWeatherForecast(point)
      ))}
    </Layer>
  );
};

export default observer(WeatherLayer);
