import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, Typography} from '@material-ui/core';

import {createStyles, makeStyles} from '@material-ui/core/styles';

import {useRootStore} from '../../stores/RootStore';
import If from '../If/If';

import {AddNewFavoritePlace} from './AddNewFavoritePlace';
import FavoritePlaceCard from './FavoritePlaceCard';


const useStyles = makeStyles(() => createStyles({
  container: {
    marginTop: '15px',
    marginBottom: '15px',
  },
}));

const maxFavoritesPlacesCount = 5;
const cellWidth = 2;

export const FavoritesPlacesList: React.FC = observer(() => {
  const {favoritePlacesStore} = useRootStore();
  const classes = useStyles();

  useEffect(() => {
    favoritePlacesStore.fetchPlaces();
  }, [favoritePlacesStore]);

  const emptyCells = () => {
    if (favoritePlacesStore.favoritesPlaces.length === maxFavoritesPlacesCount) {
      return [];
    }
    return new Array(maxFavoritesPlacesCount - favoritePlacesStore.favoritesPlaces.length - 1).fill(0);
  };

  return (
    <>
      <Typography variant='body1'>Favorite places</Typography>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        justify="space-between"
        spacing={1}
        className={classes.container}
      >
        {favoritePlacesStore.favoritesPlaces.map(({point, _id, defaultStartingPoint}, index) => (
          <Grid item xs={cellWidth} key={`${index}-${_id}`}>
            <FavoritePlaceCard point={point} _id={_id} index={index} defaultStartingPoint={defaultStartingPoint}/>
          </Grid>
        ))}
        <If condition={favoritePlacesStore.favoritesPlaces.length < maxFavoritesPlacesCount}>
          {emptyCells().map((item, index) => (
            <Grid item xs={cellWidth} key={`empty-${item}-${index}`}>
              <AddNewFavoritePlace/>
            </Grid>
          ))}
          <Grid item xs={cellWidth} key={'add-place'}>
            <AddNewFavoritePlace/>
          </Grid>
        </If>
      </Grid>
    </>
  );
});
