import React, {useCallback, useState} from 'react';

import {DEFAULT_ZOOM} from 'shared/const/maps-settings';

import {doNothing} from '../../../shared/utils/functionsUtils';

export const minZoom = 4;
export const maxZoom = 22;

export interface ZoomProps {
  zoom: number;
  increase: () => void;
  decrease: () => void;
  setMapZoom: (zoom: number) => void;
}

export const initialDataZoom: ZoomProps = {
  zoom: DEFAULT_ZOOM,
  increase: doNothing,
  decrease: doNothing,
  setMapZoom: doNothing,
};

export const ZoomContext = React.createContext<ZoomProps>(initialDataZoom);

export const ZoomWrapper = ({children}) => {
  const [zoom, setZoom] = useState<number>(initialDataZoom.zoom);

  const increase = useCallback(() => {
    zoom > minZoom && setZoom(zoom - 1);
  }, [zoom]);

  const decrease = useCallback(() => {
    zoom < maxZoom && setZoom(zoom + 1);
  }, [zoom]);

  const setMapZoom = useCallback((zoom: number) => {
    setZoom(zoom);
  }, []);

  return (
    <ZoomContext.Provider value={{zoom, increase, decrease, setMapZoom}}>
      {children}
    </ZoomContext.Provider>);
};
