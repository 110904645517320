import {Feature, Layer} from 'react-mapbox-gl';
import React from 'react';
import {observer} from 'mobx-react-lite';

import {FuelStationsInterface} from 'interfaces/FuelStationsInterface';

import {useRootStore} from 'shared/stores/RootStore';

import {dispatchEnterFuelPoint, dispatchLeaveMapPoint} from '../MapEvents';

import {useMapBoxTheme} from './LayerPointStyle';
import {fuel} from './LayersIcons';


interface Props {
  id: string;
  before: string;
}

export const TankPointsLayer: React.FC<Props> = observer((props) => {
  const {fuelStationsStore} = useRootStore();
  const {paintTankPointsIcon} = useMapBoxTheme();

  const onMouseEnter = (point: FuelStationsInterface) => {
    dispatchEnterFuelPoint(point);
  };

  const onMouseLeave = () => {
    dispatchLeaveMapPoint();
  };

  const displayFuelPoint = (point: FuelStationsInterface) => {
    return (
      <Feature
        key={point.osm_id}
        onMouseEnter={() => onMouseEnter(point)}
        onMouseLeave={onMouseLeave}
        coordinates={[point.lon, point.lat]}
      />
    );
  };

  return (
    <Layer
      id={props.id}
      type="symbol"
      before={props.before}
      layout={{'icon-image': 'fuel-icon', 'icon-size': 1}}
      paint={paintTankPointsIcon}
      images={fuel}
    >
      {fuelStationsStore.fuelPoints.map(displayFuelPoint)}
    </Layer>
  );
});
