import {useCallback, useEffect, useState} from 'react';

import {AuthData} from '../../../providers/AuthWrapper';

import {validate} from './RegisterForm/registerValidators';
import {RegistrationDataType} from './RegisterForm/RegisterForm';

export type DataType = AuthData | RegistrationDataType;

export function useInputAuthChange(initialData: DataType) {
  const [data, setData] = useState<DataType>({...initialData});

  const setInputValue = useCallback(({target: {value, name}}) => {
    setData({...data, [name]: value});
  }, [data, setData]);

  const toggleValue = useCallback((name, value) => {
    setData({...data, [name]: !value});
  }, [data]);

  return {data, setInputValue, toggleValue};
}


export function useAuthFormValidation(validator: any, data: DataType, initialData: DataType) {
  const [dataValidationErrors, setDataErrors] = useState<DataType>({...initialData});
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    validate(validator, data)
      .then(() => {
        setDataErrors({...initialData});
        setIsValid(true);
      })
      .catch((e: RegistrationDataType) => {
        setDataErrors(e);
        setIsValid(false);
      });
  }, [data, initialData, validator]);

  return {dataValidationErrors, isValid};
}
