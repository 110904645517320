import moment from 'moment';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../interfaces/PointInterface';
import {getAddressByCoords} from '../../../shared/requests/geocoder.resource';
import Log from '../../../shared/utils/Log';
import {calculateForecastIndex} from '../../../shared/utils/forecastUtils';
import {DateFormat} from '../../../shared/components/DateDisplay/DateDisplay';
import {getForecast} from '../../../shared/requests/weather.request';

export const generatePoint = async (coordinates: number[], startDate: Date): Promise<Feature<PointInterface> | undefined> => {
  try {
    const point: Feature<PointInterface> = await getAddressByCoords(coordinates);
    if (!point.properties?.addressInfo.error) {
      try {
        const momentStartDate = moment(startDate);
        const momentStartDateFormatted = momentStartDate.format(DateFormat.LongDay);
        try {
          const forecast = await getForecast(coordinates);
          const forecastIndex = calculateForecastIndex(momentStartDate, forecast.list);
          if (forecastIndex) {
            Log.info(`Set forecast index: ${forecastIndex} for date ${momentStartDateFormatted}`);
          } else {
            Log.warn(`No forecast for date ${momentStartDateFormatted}`);
          }
          point.properties = {...point.properties, forecastIndex, forecast: forecast.list ?? []};
        } catch (e) {
          Log.error(e);
        }
      } catch (e) {
        Log.error('forecast request error');
      }
      try {
        const currentWeather = await getForecast(coordinates);
        point.properties = {...point.properties, weather: currentWeather};
      } catch (e) {
        Log.error('current weather-old request error');
      }
    }
    return point;
  } catch (e) {
    Log.error('getting point address error');
  }
};
