import {first} from 'lodash';
import {Coord, Feature, LineString, Polygon, Units} from '@turf/helpers';
import {NearestPointOnLine} from '@turf/nearest-point-on-line';
import {Position} from '@turf/helpers/lib/geojson';

import {LatLngInterface} from 'interfaces/LatLngInterface';
import {PointInterface, PointProperties} from 'interfaces/PointInterface';

import {
  bbox,
  circle,
  feature,
  Geometry,
  intersect,
  length,
  lineChunk,
  lineSlice,
  lineString,
  nearestPointOnLine,
  point,
  Point,
  polygon,
} from '@turf/turf';

import {PointType} from '../const/wayPointType';
import {randomString} from '../functions/randomString';
import {AddressInfoInterface} from '../../interfaces/address/AddressInfo';

import Log from './Log';


export const createPoint = (coordinates: Geometry): Feature<PointInterface> => {
  return feature(coordinates) as Feature<PointInterface>;
};

export const createWayPointProperties = (index = 0, properties?: PointProperties, pointType: PointType = PointType.newWayPoint): PointProperties => {
  return {
    forecastIndex: 0,
    arrivingTime: {},
    ...properties,
    index,
    id: randomString(),
    routePointIndex: {},
    distance: {},
    duration: {},
    pointType,
  };
};

export const createFuelPoint = (addressInfo: AddressInfoInterface): Feature<Point> => {
  const coordinates = [parseFloat(addressInfo.lon as string), parseFloat(addressInfo.lat as string)];
  const properties = {
    id: randomString(),
    addressInfo,
  };

  return point(coordinates, properties);
};

export const distanceFromStart = (point, route, units: Units = 'kilometers') => {
  try {
    const sliced = sliceRoute(route, point);
    return {
      routeLength: Math.round(length(sliced, {units})),
      index: sliced?.geometry?.coordinates.length,
    };
  } catch (error) {
    return error;
  }
};

export const sliceRoute = (route: any, point) =>
  lineSlice(
    first(route?.geometry?.coordinates) as Feature<Point> | Point | number[],
    point.geometry.coordinates,
    routeToLineString(route),
  );

export const routeToLineString = (routeCoords: Position[]): Feature<LineString> => createLineString(routeCoords);

export const createLineString = (coordinates: Position[]): Feature<LineString> => lineString(coordinates);

export const lngLatToCords = (latLng: LatLngInterface): Coord => [latLng.lng, latLng.lat];
export const coordToLatLng = (coords: number[]): LatLngInterface => ({lng: coords[0], lat: coords[1]});

export const nearestPointOnRoute = (directionLineString: LineString, coords: number[]): NearestPointOnLine => {
  return nearestPointOnLine(directionLineString, coords);
};

export const createCircle = (center, radius = 15, steps = 50, units: Units = 'kilometers'): Feature<Polygon> => {
  return circle(center, radius, {steps, units});
};

export const routeIntersect = (circle, lineString: Feature<LineString>) => {
  try {
    return intersect(circle, lineString);
  } catch (e) {
    Log.error(e);
    return null;
  }
};

export const routeToPolygon = (route: Position[][]) => polygon([...route]);

export const lineToChunk = (line) => lineChunk(line, 1, {units: 'kilometers'});

export const pointFromAddress = (addressInfo: AddressInfoInterface): Feature<PointInterface> | undefined => {
  const {lon, lat} = addressInfo;

  const geometry = {
    type: 'Geometry',
    coordinates: [lon as number, lat as number],
  };

  if (lon && lat) {
    const point = createPoint(geometry);
    const properties = createWayPointProperties(0, {addressInfo});
    point.properties = {...properties};
    return point;
  }
};

export const pointsToBBox = (coordinates: Position[]) => {
  const line = lineString([...coordinates]);
  return bbox(line);
};
