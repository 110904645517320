import React from 'react';
import {withRouter} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {isEmpty} from 'lodash';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {PATH_TO_USERS_DIRECTORY, USER_MAP_DIRECTORY} from '../../shared/const/settings';
import {loadUserMapRecord} from '../../shared/requests/map.resource';
import {UserMapsInterface} from '../../interfaces/UserMaps';

interface Props {
  match: {
    params: {
      slug: string;
    }
  }
}

interface State {
  mapData: UserMapsInterface | undefined;
  isError: boolean;
}

@inject(({store}) => ({
  userProfileStore: store.userProfileStore,
}))
@observer
class SharedMap extends React.Component<Props, State> {
  state = {
    mapData: undefined,
    isError: false,
  };

  componentDidMount() {
    const {slug} = this.props.match.params;

    const loadData = (mapData: UserMapsInterface) => {
      isEmpty(mapData)
        ? setErrorState()
        : this.setState({mapData});
    };

    const setErrorState = () => this.setState({isError: true});

    if (slug) {
      loadUserMapRecord(slug)
        .then(loadData)
        .catch(setErrorState);
    }
  }

  getPathToMapsImages = directory =>
    [
      process.env.REACT_APP_IMAGE_API,
      PATH_TO_USERS_DIRECTORY,
      directory,
      USER_MAP_DIRECTORY,
    ].join('/');

  render() {
    const {
      isError,
    } = this.state;

    return isError ? (
      <ErrorMessage/>
    ) : (
      <div/>
    );
  }
}


const ErrorMessage = () => (
  <Grid container alignItems="center" justify="center">
    <Grid item>
      <Typography align="center">
        Map doesn&apos;t exist, or was removed
      </Typography>
    </Grid>
  </Grid>
);


export default withRouter(SharedMap);
