import React from 'react';

import {AuthDisplay} from 'shared/components/AuthDisplay/AuthDisplay';

import {FavoritesPlacesList} from '../../shared/components/favorites/FavoritesPlacesList';
import WeatherMapPointModal from '../../shared/components/weather/WeatherMapPointModal/WeatherMapPointModal';

const MainPage = () => {
  return (
    <AuthDisplay>
      <>
        <FavoritesPlacesList/>
        <WeatherMapPointModal usePointContext={true}/>
      </>
    </AuthDisplay>
  );
};

export default MainPage;
