import React from 'react';
import {Skeleton} from '@material-ui/lab';

export const CardPlaceholder: React.FC = () => {
  return (
    <div style={{marginTop: '40px'}}>
      <Skeleton
        style={{height: '10px', width: '100%'}}
        variant="text"
        animation="wave"
      />
      <Skeleton
        style={{height: '10px', width: '100%'}}
        variant="text"
        animation="wave"
      />
      <Skeleton
        style={{height: '10px', width: '100%'}}
        variant="text"
        animation="wave"
      />
      <Skeleton
        style={{height: '10px', width: '100%'}}
        variant="text"
        animation="wave"
      />
      <Skeleton
        style={{height: '10px', width: '100%'}}
        variant="text"
        animation="wave"
      />
      <Skeleton
        style={{height: '200px', width: '100%', marginTop: '10px'}}
        variant="rect"
        animation="wave"
      />
      <Skeleton
        style={{height: '48px', width: '100%', marginTop: '10px'}}
        variant="rect"
        animation="wave"
      />
    </div>
  );
};
