import React, {useCallback, useContext, useMemo} from 'react';
import {MenuItem} from '@material-ui/core';
import {useObserver} from 'mobx-react';

import Select from '@material-ui/core/Select';

import {UserContext} from '../../../providers/UserWrapper';
import {useRootStore} from '../../stores/RootStore';
import Log from '../../utils/Log';
import Flag from '../CountryFlag/CountryFlag';

const LanguageSelector: React.FC = () => {
  const {profile: {language}, updateProfileProperty, touched} = useContext(UserContext);
  const {translations} = useRootStore();

  const onLanguageChange = useCallback((event: React.ChangeEvent<{value: unknown}>) => {
    const lang = event.target.value as string;
    updateProfileProperty('language', lang);
    translations.setCurrentLang(lang);
    touched();
  }, [touched, translations, updateProfileProperty]);

  const renderItems = useMemo(() => {
    Log.info('available app languages:' + translations.langs);
    return translations.langs.map((lang, index) => {
      return (
        <MenuItem selected={lang === translations.lang} key={`${lang}-${index}`} value={lang}><Flag countryCode={lang}/></MenuItem>);
    });
  }, [translations.lang, translations.langs]);

  return useObserver(() => (
    <Select
      value={language}
      onChange={onLanguageChange}
    >
      {renderItems}
    </Select>
  ));
};

export default React.memo(LanguageSelector);
