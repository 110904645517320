import React, {useCallback, useContext, useMemo, useState} from 'react';
import {Button, createStyles, DialogContent, makeStyles, Tab, Tabs, Theme} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';

import {AuthContext} from '../../../providers/AuthWrapper';
import {flexContainerCenter} from '../../const/stylesDef';
import If from '../If/If';
import {useToggle} from '../../../hooks/useToggle';

import LoginForm from './LoginLocalForm/LoginForm';
import RegisterForm from './RegisterForm/RegisterForm';
import {GoogleSignInButton} from './GoogleSignInButton';
import {LoginModalTitle} from './LoginModalTitle';

interface Props {
  children?: any;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      minWidth: '320px',
      borderBottomColor: theme.palette.grey.A200,
      borderBottom: '1px solid',
    },
    dialogActions: {
      ...flexContainerCenter,
      borderTopColor: theme.palette.grey.A100,
      borderTop: '1px solid',
    },
    dialogContent: {
      minHeight: '100px',
    },
    errorLabel: {
      maxWidth: '320px',
      textAlign: 'center',
    },
    root: {
      backgroundColor: theme.palette.grey.A100,
    },
    formContainer: {
      flex: 1,
    },
    dialogContainer: {
      width: '400px',
      height: '450px',
      display: 'flex',
      flexDirection: 'column',
    },
  }));

export const LoginSignUpModal: React.FC<Props> = () => {
  const {isAuth, checkPending} = useContext(AuthContext);
  const [usePasswordContainer, {toggle}] = useToggle(false);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const changeTab = useCallback((event, tabIndex: number) => {
    setSelectedTab(tabIndex);
  }, []);

  const buttonLabel = useMemo(() => {
    return usePasswordContainer
      ? 'Use social logins'
      : 'Use login & password';
  }, [usePasswordContainer]);

  return (
    <Dialog
      maxWidth={'md'}
      open={!isAuth && !checkPending}
      disableBackdropClick={true}
    >
      <LoginModalTitle usePasswordContainer={usePasswordContainer} selectedTab={selectedTab}/>
      <If condition={!usePasswordContainer}>
        <DialogContent>
          <GoogleSignInButton/>
        </DialogContent>
      </If>
      <DialogContent>
        <Button
          variant='outlined'
          fullWidth
          color='primary'
          size='large'
          onClick={toggle}
        >{buttonLabel}</Button>
      </DialogContent>
      <If condition={usePasswordContainer}>
        <div className={classes.dialogContainer}>
          <Tabs
            value={selectedTab}
            onChange={changeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="I have account"/>
            <Tab label="Create account"/>
          </Tabs>
          <If condition={selectedTab === 0}><LoginForm/></If>
          <If condition={selectedTab === 1}><RegisterForm/></If>
        </div>
      </If>
    </Dialog>
  );
};
