import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {orderBy} from 'lodash';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {DirectionsWayPointsStoreInterface} from 'interfaces/stores/DirectionsWayPointsStoreInterface';
import {PointInterface, PointProperties, WayPointInsertingType} from 'interfaces/PointInterface';

import {Feature, featureCollection, getCoord, Point} from '@turf/turf';

import {createWayPointProperties} from '../utils/turfUtils';
import Log from '../utils/Log';
import {RouteCalculationType, RouteInterface} from '../../interfaces/RoutesInterface';
import {arrivingDateTime, joinTimeArray} from '../utils/timeUtils';
import {getPointCoords, setPointArrivingTime, setPointDistance, setPointProperty} from '../utils/pointUtils';
import {gerRouteCoordinates, routeLegsClone} from '../utils/routeUtils';
import {calculateForecastIndex, selectForecast} from '../utils/forecastUtils';
import {ItemForecastInterface} from '../../interfaces/weather/ForecastWeatherInterface';
import {isUndefined} from '../utils/functionsUtils';

import {PointTimeAndDistanceGenerator} from './generators/WayPointProperties';

export type PointsList = Feature<PointInterface>[];

class DirectionWayPointStore<T, ParamsType> implements DirectionsWayPointsStoreInterface<T, ParamsType> {
  private readonly rootStore: RootStoreInterface<any>;
  _wayPoints: PointsList = [];
  _mergedPoints: PointsList = [];
  _selectedKey;
  routesCount = 0;
  distanceWorker: any;

  constructor(rootStore: RootStoreInterface<any>) {
    makeObservable(this, {
      _wayPoints: observable,
      _mergedPoints: observable,
      _selectedKey: observable,
      wayPointsSource: computed,
      setSelectedKey: action,
      selectedKey: computed,
      recalculateWayPointsDistances: action,
      currentRouteIndex: computed,
      wayPoints: computed,
      wayPointsCount: computed,
      mergePoints: action,
      updateWayPointsIndexProperty: action,
      cleanupWayPointsDistance: action,
      mergedPoints: computed,
      wayPointsCordsMap: computed,
      addWayPoint: action,
      addWayPointAtIndex: action,
      updateWayPointLocation: action,
      clearAll: action,
      deleteWayPoint: action,
    });
    this.rootStore = rootStore;
  }

  get wayPointsSource() {
    return {
      type: 'geojson',
      data: featureCollection(this.mergedPoints),
      generateId: true,
    };
  }

  setSelectedKey(key?: string): void {
    this._selectedKey = key;
  }

  get selectedKey() {
    return this._selectedKey;
  }

  recalculateWayPointsDistances() {
    this.forEachWayPoint((wayPoint) => {
      this.updatePointDistanceAndArrivingTime(wayPoint);
    });
    this.rootStore.wayPointsStore.mergePoints(true);
  }

  get currentRouteIndex(): number {
    return this.rootStore.directionsStore.selectedRouteIndex;
  }

  get wayPoints(): PointsList {
    return this._wayPoints;
  }

  get wayPointsCount(): number {
    return this._wayPoints.length;
  }

  orderWayPointsByDistance = (): string[] => [`properties.distance[${this.currentRouteIndex}]`];
  orderWayPointsByIndex = (): string[] => [`properties.index[${this.currentRouteIndex}]`];

  mergePoints(orderByIndex = false): void {
    let merged = [...this._wayPoints];

    merged = orderByIndex
      ? orderBy(merged, this.orderWayPointsByIndex(), ['asc'])
      : orderBy(merged, this.orderWayPointsByDistance(), ['asc']);

    this._mergedPoints = merged;
  }

  updateWayPointsIndexProperty(): void {
    const updatePointIndex = (point, index) => {
      setPointProperty(point, 'index', index);
    };
    this.forEachWayPoint(updatePointIndex);
  }

  cleanupWayPointsDistance(): void {
    const updatePointProperties = (point: PointInterface) => {
      if (point.properties) {
        point.properties.distance = {};
      }
    };
    this.forEachWayPoint(updatePointProperties);
  }

  get mergedPoints(): PointsList {
    return this._mergedPoints;
  }

  get wayPointsCordsMap(): number[][] {
    return toJS(this.mergedPoints.map(wayPoint => getCoord(wayPoint)));
  }

  addWayPoint = (wayPoint: Feature<PointInterface>, insertPointAt: WayPointInsertingType): void => {
    switch (insertPointAt) {
      case WayPointInsertingType.AddOnEnd:
        wayPoint.properties = createWayPointProperties(this._wayPoints.length, wayPoint?.properties as PointProperties);
        this._wayPoints.push(wayPoint);
        break;
      case WayPointInsertingType.AddOnBeginning:
        wayPoint.properties = createWayPointProperties(0, wayPoint?.properties as PointProperties);
        this.cleanupWayPointsDistance();
        this._wayPoints.unshift(wayPoint);
        this.updateWayPointsIndex();
        break;
    }
    this.mergePoints(true);
    this.calculateRoute(insertPointAt);
  };

  addWayPointAtIndex = (wayPoint: Feature<PointInterface>, wayPointIndex: number): void => {
    wayPoint.properties = createWayPointProperties(wayPointIndex, wayPoint.properties as PointProperties);
    this.cleanupWayPointsDistance();
    this._wayPoints.splice(wayPointIndex, 0, wayPoint);
    this.updateWayPointsIndex();
    this.mergePoints(true);
    this.calculateRoute(WayPointInsertingType.AddAtIndex);
  };

  private calculateRoute = (insertPointAt?: WayPointInsertingType) => {
    if (this.mergedPoints.length === 2 || insertPointAt === WayPointInsertingType.AddAtIndex) {
      this.rootStore.directionsStore.createRouteDirections(RouteCalculationType.Initial);
    }

    if (insertPointAt === WayPointInsertingType.AddOnEnd) {
      this.rootStore.directionsStore.createRouteDirections(RouteCalculationType.AddRouteAtTheEnd);
    }
  };

  updateWayPointLocation = (newPoint: Feature<PointInterface>): void => {
    this.cleanupWayPointsDistance();
    if (newPoint.properties) {
      newPoint.properties = createWayPointProperties(newPoint.properties.index, newPoint.properties);
    }
    this._wayPoints.splice(newPoint.properties?.index, 1, newPoint);
    this.mergePoints();
    this.calculateRoute();
  };

  clearAll() {
    this._mergedPoints = [];
    this._wayPoints = [];
    this.rootStore.directionsStore.clearRoutes();
    this.rootStore.fuelStationsStore.clearAll();
  }

  deleteWayPoint(wayPoint: Feature<PointInterface>): void {
    const index = this._wayPoints.findIndex(
      point => wayPoint?.properties?.id === point?.properties?.id,
    );

    if (index === this.wayPointsCount) {
      this._wayPoints.splice(index);
    } else {
      this._wayPoints.splice(index, 1);
      this.updateWayPointsIndexProperty();
    }

    this.rootStore.directionsStore.clearRoutes();
    this.rootStore.fuelStationsStore.clearAll();
    this.mergePoints();
    this.rootStore.directionsStore.createRouteDirections();
  }

  updateWayPointsIndex(): void {
    this.forEachWayPoint((point: Feature<PointInterface>, index) => {
      setPointProperty(point, 'index', index);
    });
  }

  updatePointDistanceAndArrivingTime(wayPoint: Feature<PointInterface>): void {
    const eachFunc = (route: RouteInterface, routeIndex) => {
      PointTimeAndDistanceGenerator(
        getPointCoords(wayPoint, true),
        gerRouteCoordinates(route),
        joinTimeArray(routeLegsClone(route)),
      )
        .then((data) => {
          const {routeLength, arrivingTime} = data;
          if (isUndefined(routeLength) || isUndefined(arrivingTime)) {
            Log.error('there is a problem with calculate point distance');
            return;
          }

          setPointDistance(wayPoint, routeIndex, routeLength);
          setPointArrivingTime(wayPoint, routeIndex, arrivingTime);
          //set forecast index
          const arriveAt = arrivingDateTime(this.rootStore.routeTimeStore.routeStartDate, arrivingTime);
          const forecastIndex = calculateForecastIndex(arriveAt, (wayPoint.properties as PointProperties).forecast as ItemForecastInterface[]);
          if (forecastIndex && (forecastIndex !== wayPoint?.properties?.forecastIndex)) {
            setPointProperty(wayPoint, 'forecastIndex', forecastIndex);
            selectForecast(forecastIndex, wayPoint?.properties?.forecast);
          }
        });
    };

    this.forEachRoute(eachFunc);
  }


  forEachRoute = (eachFunction: (param: any, index?: number) => void): void => {
    this.rootStore.directionsStore.routesCollection.forEach(eachFunction);
  };

  forEachWayPoint = (eachFunction: (param: any, index?: number) => void): void => {
    this._wayPoints.forEach(eachFunction);
  };

  findPointByKey(key: string): Feature<PointInterface> | undefined {
    return this.mergedPoints.find((point: Feature<Point>) => point?.properties?.id === key);
  }

  findWayPointByKey(key: string): Feature<PointInterface> | undefined {
    return this._wayPoints.find((point: Feature<Point>) => point?.properties?.id === key);
  }
}

export default DirectionWayPointStore;
