import React from 'react';
import {Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';

import {PointArrivingTimeInterface} from 'interfaces/PointInterface';

import {useDistances} from '../Distance/Distance';
import {useRootStore} from '../../stores/RootStore';
import DateDisplay, {DateFormat} from '../DateDisplay/DateDisplay';
import If from '../If/If';
import {arrivingDateTime, isSameDay} from '../../utils/timeUtils';

interface Props {
  arrivingTime?: PointArrivingTimeInterface<number>;
}

const ArrivingTime: React.FC<Props> = ({arrivingTime}) => {
  const {selectedRouteIndex} = useDistances();
  const {routeTimeStore} = useRootStore();

  const timeArrivingValue = () => {
    if (arrivingTime) {
      const arriveAt = arrivingDateTime(routeTimeStore.routeStartDate, arrivingTime[selectedRouteIndex]);
      const sameDay = isSameDay(arriveAt, routeTimeStore.routeStartDate);
      return {arrivingDateTime: arriveAt, isSameDay: sameDay};
    }
  };

  return (
    <If condition={!!arrivingTime}>
      <Typography color='secondary'>
        <DateDisplay
          format={timeArrivingValue()?.isSameDay ? DateFormat.Time : DateFormat.DayHour}
          date={timeArrivingValue()?.arrivingDateTime}
        />
      </Typography>
    </If>
  );
};

export default observer(ArrivingTime);
