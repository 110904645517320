import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {InjectedStylesInterface, StyleType} from '../../interfaces/Styles';

interface Props {
  title: string;
}

const UserPreferences: React.FC<Props & InjectedStylesInterface> = (props) => {
  const {classes, title, children} = props;
  return (
    <Accordion className={classes.preferences}>
      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const styles: StyleType = () => ({
  preferences: {
    marginTop: 8,
  },
  flag: {
    marginLeft: 8,
    padding: 0,
  },
});

export default withStyles(styles)(UserPreferences);
