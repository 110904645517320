import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {checkIsLoggedRequest, localLogin} from 'shared/requests/auth.resource';
import {authToken} from 'shared/functions/HttpAuth';

import {doNothing} from '../shared/utils/functionsUtils';
import Log from '../shared/utils/Log';
import {useLogoutListener} from '../hooks/useLogoutListener';

export type AuthData = {
  email: string;
  password: string;
}

export const initialDataAuth = {
  checkPending: true,
  isAuth: false,
  setAuthenticated: (): Promise<boolean> => Promise.resolve(false),
  authenticateFromGoogle: doNothing,
};

interface DataAuth {
  checkPending: boolean;
  isAuth: boolean;
  setAuthenticated: (data: AuthData) => Promise<boolean | string>;
  authenticateFromGoogle: (token: string) => void;
}

export enum authEvent {
  logout = 'logout',
  login = 'login'
}

export const AuthContext = React.createContext<DataAuth>(initialDataAuth);
export const logoutEvent = new Event(authEvent.logout);

export const AuthWrapper: React.FC = ({children}) => {
  const [authenticated, setAuth] = useState<boolean>(initialDataAuth.isAuth);
  const [checkPending, setCheckPending] = useState<boolean>(initialDataAuth.checkPending);

  const onLogout = useCallback(() => {
    authToken.clearToken();
    setAuth(false);
    Log.info('token cleared');
  }, []);

  useLogoutListener(onLogout);

  const isLogged = async () => {
    try {
      await checkIsLoggedRequest();
      setCheckPending(false);
      setAuth(true);
    } catch (e) {
      Log.warn('user not logged in');
      setAuth(false);
      setCheckPending(false);
    }
  };

  useEffect(() => {
    isLogged()
      .catch(e => {
        Log.error(e);
      });
  }, []);

  const setAuthenticated = useCallback(async (data: AuthData): Promise<boolean | string> => {
    try {
      const authData = await localLogin(data);
      authToken.token = authData.token;
      setAuth(true);
      return true;
    } catch (e) {
      setAuth(false);
      Log.error(e.response.data.message);
      return Promise.reject(e.response.data.message);
    }
  }, []);

  const authenticateFromGoogle = useCallback((token: string) => {
    authToken.token = token;
    setAuth(true);
  }, []);

  const isAuth = useMemo(() => authenticated, [authenticated]);

  return (
    <AuthContext.Provider
      value={{
        checkPending,
        isAuth,
        setAuthenticated,
        authenticateFromGoogle,
      }}
    >{children}</AuthContext.Provider>
  );
};
