import {computed} from 'mobx';
import MobxReactRouter, {RouterStore} from 'mobx-react-router';
import {createContext, useContext} from 'react';

import {METRIC_UNIT} from '../const/units';
import {RootStoreInterface} from '../../interfaces/stores/RootStoreInterface';
import Log from '../utils/Log';

import UnitStore from './UnitStore';
import UserMotorcycleStore from './UserMotorcycleStore';
import DirectionStore from './DirectionsStore';
import DirectionsStore from './DirectionsStore';
import RouteTimeStore from './RouteTimeStore';
import TranslationStore from './TranslationStore';
import DirectionWayPointStore from './DirectionWayPointStore';
import AddressStore from './AddressStore';
import UserMapStore from './UserMapStores';
import MotorcyclesCatalogStore from './MotorcyclesCatalogStore';
import NewMotorcycleStore from './NewMotorcycleStore';
import UploadImagesStore from './UploadImagesStore';
import UsersStore from './UsersStore';
import FuelStationsStore from './FuelStationStore';
import PoiMapStore from './PoiMapStore';
import FavoritePlacesStore from './FavoritesPlaces';


class RootStore<T, ParamsType> implements RootStoreInterface<T, ParamsType> {
  _storesInitialized = false;

  translations: TranslationStore;
  routing: MobxReactRouter.RouterStore;
  userMotorcycle: UserMotorcycleStore<T, ParamsType>;
  unitsStore: UnitStore<T, ParamsType>;
  wayPointsStore: DirectionWayPointStore<T, ParamsType>;
  directionsStore: DirectionsStore;
  routeTimeStore: RouteTimeStore;
  addressStore: AddressStore;
  userMapsStore: UserMapStore;
  motorcycleCatalogStore: MotorcyclesCatalogStore<T, ParamsType>;
  newMotorcycleStore: NewMotorcycleStore;
  uploadImagesStore: UploadImagesStore;
  usersStore: UsersStore;
  fuelStationsStore: FuelStationsStore;
  poiMapStore: PoiMapStore;
  favoritePlacesStore: FavoritePlacesStore;

  constructor() {
    this.translations = new TranslationStore(this);
    this.routing = new RouterStore();
    this.userMotorcycle = new UserMotorcycleStore(this);
    this.unitsStore = new UnitStore(this);
    this.wayPointsStore = new DirectionWayPointStore(this);
    this.directionsStore = new DirectionStore(this);
    this.routeTimeStore = new RouteTimeStore(this);
    this.addressStore = new AddressStore(this);
    this.userMapsStore = new UserMapStore(this);
    this.motorcycleCatalogStore = new MotorcyclesCatalogStore(this);
    this.newMotorcycleStore = new NewMotorcycleStore(this);
    this.uploadImagesStore = new UploadImagesStore(this);
    this.usersStore = new UsersStore(this);
    this.fuelStationsStore = new FuelStationsStore(this);
    this.poiMapStore = new PoiMapStore(this);
    this.favoritePlacesStore = new FavoritePlacesStore(this);

    this.afterCreatingStores();
    this._storesInitialized = true;
    Log.info('stores init');
  }

  @computed
  get storesInitialized() {
    return this._storesInitialized;
  }

  cleanUpStores(): void {
    const logToConsole = (message: string, color: string): void =>
      console.info(`%c ${message}`, `color: ${color};`);
    const setInitialData = (store) => {
      try {
        this[store].setInitialStoreData();
        logToConsole(`App: initialData set for store:  ${store}`, 'green');
      } catch (e) {
        logToConsole(`App: Store error ${store}`, 'red');
      }
    };
    Object.getOwnPropertyNames(this).forEach(setInitialData);
  }

  afterCreatingStores(): void {
    this.unitsStore.setUnit(METRIC_UNIT);
  }
}


export default new RootStore();

export const RootStoreContext = createContext<RootStore<any, any>>({} as RootStore<any, any>);
export const RootStoreProvider = RootStoreContext.Provider;
export const useRootStore = (): RootStore<any, any> => useContext(RootStoreContext);
