import React, {useContext} from 'react';

import {AuthContext} from '../../../providers/AuthWrapper';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

type Props = {
  children: React.ComponentElement<any, any>;
  skeleton?: React.ComponentElement<any, any>;
};

export const AuthDisplay: React.FC<Props> = ({children, skeleton}) => {
  const {isAuth} = useContext(AuthContext);

  return (
    <ConditionalWrapper
      condition={isAuth}
      skeleton={skeleton}
    >
      {children}
    </ConditionalWrapper>
  );
};
