import {action, computed, observable, runInAction} from 'mobx';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {MotorcycleInterface} from 'interfaces/stores/MotorcycleInterface';

import {createUserMotorcyclesResource} from '../requests/motorcycles.resource';
import {METRIC_UNIT} from '../const/units';

class NewMotorcycleStore {
  private readonly rootStore: RootStoreInterface<any>;
  @observable _motorcycle: MotorcycleInterface | undefined;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.setInitialStoreData();
  }

  @computed
  get motorcycle() {
    return this._motorcycle as MotorcycleInterface;
  }

  setInitialStoreData() {
    runInAction(() => {
      if (this._motorcycle) {
        this._motorcycle.unit = METRIC_UNIT;
      }
    });
  }

  @action
  setMotorcycleMainValues() {
    const {
      Make,
      Year,
      Model,
      modelId,
    } = this.rootStore.motorcycleCatalogStore.motorcycleParams;
    runInAction(() => {
      if (this._motorcycle) {
        this._motorcycle = {...this.motorcycle, Make, Model, Year, modelId};
      }
    });
  }

  @action
  setMotorcycleInfo = ({tankCapacity, fuelConsumption, mileage, unit}) => {
    runInAction(() => {
      this._motorcycle = {
        ...this.motorcycle,
        tankCapacity,
        fuelConsumption,
        mileage,
        unit,
      };
    });
  };

  @action
  setMotorcycleDescription = ({description, ownName}) => {
    runInAction(() => {
      if(this._motorcycle){
        this._motorcycle = {...this.motorcycle, description, ownName};
      }
    });
  };

  @action
  create() {

    if (this._motorcycle) {
      delete this._motorcycle.kind;
    }

    if (this._motorcycle && this.rootStore.userMotorcycle.userMotorcycles.length === 0) {
      this._motorcycle.isFavourite = true;
    }
    createUserMotorcyclesResource(this.motorcycle)
      .then(() => {
        this.rootStore.userMotorcycle.getUserMotorcycles();
      });
  }

  @action
  reset = () => {
    this.setInitialStoreData();
    this.rootStore.motorcycleCatalogStore.setInitialData();
  };
}


export default NewMotorcycleStore;
