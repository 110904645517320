import React from 'react';
import {withStyles} from '@material-ui/core';
import {withRouter} from 'react-router';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Theme} from '@material-ui/core/es';

import LibraryAdd from '@material-ui/icons/LibraryAdd';

import {MAP_LINK} from '../../shared/const/NavigationLinks';
import {InjectedStylesInterface, StyleType} from '../../interfaces/Styles';


interface InjectedProps extends InjectedStylesInterface {
  history: {
    push: (url: string) => void;
  }
}


export const UserMaps: React.FC = (props) => {
  const {history, classes: {gridWrapper, addCard, addIcon, card, addCardContent}} = props as InjectedProps;

  const onCreateNewRoute = () => {
    history.push(MAP_LINK);
  };

  return (
    <Grid item xs={12} md={4} className={gridWrapper}>
      <Card
        className={`${card} ${addCard}`}
        onClick={() => {
          onCreateNewRoute();
        }}
      >
        <CardContent className={addCardContent}>
          <LibraryAdd className={addIcon}/>
          <Typography>
            Add new route
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const styles: StyleType = (theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    margin: 8 * 2,
  },
  addCard: {
    backgroundColor: theme.palette.grey['100'],
    cursor: 'pointer',
  },
  addCardContent: {
    margin: 'auto',
    textAlign: 'center',
  },
  gridWrapper: {
    display: 'flex',
  },
});

export default withRouter(withStyles(styles)(UserMaps));
