import React from 'react';
import {IconButton} from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import {useRootStore} from '../../stores/RootStore';
import If from '../If/If';

import {FavoritePlaceInterface} from './FavoritePlaceCard';

export const CardMenu: React.FC<FavoritePlaceInterface> = ({_id, defaultStartingPoint}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {favoritePlacesStore} = useRootStore();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const removePlace = async () => {
    favoritePlacesStore.removePlace(_id);
  };

  const setAsHomePoint = () => {
    favoritePlacesStore.setAsHome(_id);
    handleClose();
  };

  return (
    <>
      <IconButton aria-label="settings" onClick={handleClick}>
        <MoreVertIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <If condition={!defaultStartingPoint}>
          <MenuItem
            onClick={setAsHomePoint}
          >
            Set as Home (default center map)
          </MenuItem>
        </If>
        <MenuItem
          onClick={removePlace}
        >
          Remove place
        </MenuItem>
      </Menu>
    </>
  );
};
