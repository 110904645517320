import React, {useCallback, useMemo} from 'react';

import {PointInterface} from 'interfaces/PointInterface';

import {Feature} from '@turf/turf';

import If from '../../../shared/components/If/If';
import {clearMapEvent, selectWeatherPoint} from '../MapEvents';
import WeatherIcon from '../../../shared/components/weather/WeatherIcon/WeatherIcon';
import WeatherTemp from '../../../shared/components/weather/WeatherTemp/WeatherTemp';
import {getForecastData} from '../../../shared/utils/forecastUtils';

import {ContextMenuButton} from './ContextMenuButton';

interface Props {
  point?: Feature<PointInterface>
}

export const CurrentWeatherIcon: React.FC<Props> = ({point}) => {
  const openWeatherModal = useCallback(() => {
    if (point) {
      clearMapEvent();
      selectWeatherPoint(point);
    }
  }, [point]);

  const pointForecastData = useMemo(() => {
    return point && getForecastData(point);
  }, [point]);

  const currentWeatherIcon = useMemo(() => {
    return pointForecastData?.icon
      ? <WeatherIcon icon={pointForecastData.icon}/>
      : null;
  }, [pointForecastData]);

  const currentTemp = useMemo(() => {
    return pointForecastData?.temp
      ? <WeatherTemp temp={pointForecastData.temp} showLabel={false} variant={'body1'}/>
      : null;
  }, [pointForecastData]);

  return (
    <If condition={(currentTemp !== null && currentWeatherIcon !== null)}>
      <ContextMenuButton
        click={openWeatherModal}
        deg={210}
        icon={currentWeatherIcon}
        component={currentTemp}
      />
    </If>
  );
};
