import {action, computed, makeObservable, observable, toJS} from 'mobx';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {PointInterface} from 'interfaces/PointInterface';
import {FuelStationsInterface} from 'interfaces/FuelStationsInterface';

import {Feature} from '@turf/turf';


import {findTankStations} from '../../App';
import Log from '../utils/Log';
import {dispatchEventMessages, NotificationType} from '../components/Notificator/Notifier';


class FuelStationsStore {
  private readonly rootStore: RootStoreInterface<any>;
  _fuelPoints: FuelStationsInterface[] = [];
  _temporaryRoutes = {};

  constructor(rootStore: RootStoreInterface<any>) {
    makeObservable(this, {
      _fuelPoints: observable,
      _temporaryRoutes: observable,
      fuelPoints: computed,
      findStations: action,
      clearAll: action,
      setWayPointFuelPoints: action,
      setFuelPoints: action,
    });
    this.rootStore = rootStore;
  }

  get fuelPoints(): FuelStationsInterface[] {
    return this._fuelPoints;
  }

  private pointExists(point: FuelStationsInterface): boolean {
    return this._fuelPoints.some(({osm_id}) => osm_id === point.osm_id);
  }

  setFuelPoints(fuelStationsPoints: FuelStationsInterface[]) {
    fuelStationsPoints.forEach(point => {
      if (!this.pointExists(point)) {
        this._fuelPoints.push(point);
      }
    });
  }

  private pushNotification(type: NotificationType, message: string): void {
    const notificationInfo = {
      type,
      name: 'Tank stations',
      message,
    };
    dispatchEventMessages(notificationInfo);
  }

  setWayPointFuelPoints(point: Feature<PointInterface>, fuelPoints: FuelStationsInterface[]) {
    if (point?.properties) {
      point.properties.fuelPoints = fuelPoints;
    }
  }

  findStations(point: Feature<PointInterface>): Promise<FuelStationsInterface[] | never> {
    if (!point?.geometry?.coordinates) {
      throw new Error('no coordinates given');
    }

    this.pushNotification(NotificationType.info, 'Fetching fuel stations');
    return findTankStations(toJS(point?.geometry?.coordinates as number[]))
      .then(fuelPoints => {
        this.setFuelPoints(fuelPoints);
        this.setWayPointFuelPoints(point, fuelPoints);
        if (fuelPoints.length > 0) {
          this.pushNotification(NotificationType.info, `Found ${fuelPoints.length} station(s)`);
        }
        return fuelPoints;
      })
      .catch(e => {
        Log.error(e);
        const message = 'There is a problem witch getting the fuel stations. please try again later';
        this.pushNotification(NotificationType.error, message);
      });
  }

  clearAll() {
    this._fuelPoints = [];
  }
}


export default FuelStationsStore;
