import {observer} from 'mobx-react';
import React from 'react';

import {useRootStore} from 'shared/stores/RootStore';


export const FuelCapacityUnit = (
  observer(({unitsStore}) => (
    <>{unitsStore.fuelConsumptionUnit}</>
  ))
);

export const CapacityUnit = (
  observer(({unitsStore}) => <>{unitsStore.capacityUnit}</>)
);

export const TempUnit: React.FC = observer(() => {
  const {unitsStore} = useRootStore();
  return (
    <span dangerouslySetInnerHTML={unitsStore.temperatureUnit.__html}/>
  );
});
