import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {flexContainerStartRow} from '../../../const/stylesDef';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  selected: {
    backgroundColor: theme.palette.action.selected,
    borderLeft: `1px solid ${theme.palette.grey.A100}`,
    borderRight: `1px solid ${theme.palette.grey.A100}`,
  },
  tempContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  description: {
    fontSize: '14px',
    width: '215px',
  },
  cloudContainer: {
    width: '80px',
  },
  rainContainer: {
    width: '80px',
  },
  temp: {
    minWidth: '50px',
  },
  date_day: {
    textAlign: 'center',
    position: 'absolute',
    padding: '0 2px 0 2px',
    minWidth: '60px',
    border: `1px solid ${theme.palette.grey.A100}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    top: '-14px',
  },
  date_night: {
    textAlign: 'center',
    position: 'absolute',
    padding: '0 2px 0 2px',
    minWidth: '60px',
    border: `1px solid ${theme.palette.info.dark}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    top: '-14px',
  },
  hours: {
    fontSize: '14px',
  },
  weatherIconContainer: {
    padding: '8px',
    minWidth: '60px',
  },
  row: {
    ...flexContainerStartRow,
    paddingRight: '5px',
  },
}));
