import {action, computed, makeObservable, observable, toJS} from 'mobx';
import moment from 'moment';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {UserMapData} from 'interfaces/UserMapData';
import {UserMapsInterface} from 'interfaces/UserMaps';

import {Feature} from '@turf/turf';

import {MAP_THUMBNAIL_HEIGHT, MAP_THUMBNAIL_WIDTH} from '../const/maps-settings';
import {createMap, createThumbnail, deleteUserMaps, loadUserMaps, updateMap} from '../requests/map.resource';
import {MapTitleType} from '../../pages/maps/MapTitleModal/MapTitleModal';
import {dispatchEventMessages, NotificationType} from '../components/Notificator/Notifier';
import {PointInterface} from '../../interfaces/PointInterface';


class UserMapStore {
  private readonly rootStore: RootStoreInterface<any>;

  userMaps: UserMapsInterface[] = [];
  mapId: string | undefined = undefined;
  mapDataInformation: MapTitleType = {
    mapName: '',
    description: '',
  };

  constructor(rootStore: RootStoreInterface<any>) {
    makeObservable(this, {
      userMaps: observable,
      mapId: observable,
      mapDataInformation: observable,
      usersMaps: computed,
      loadUserMaps: action,
      deleteUserMap: action,
      saveMap: action,
    });
    this.rootStore = rootStore;
  }

  get usersMaps(): UserMapsInterface[] {
    return this.userMaps;
  }


  createNewMap() {
    this.mapId = undefined;
  }

  loadUserMaps() {
    const decompressRoute = routes => {
      return routes.map(route => {
        if (typeof route.geometry.coordinates === 'string') {
          route.geometry.coordinates = JSON.parse(route.geometry.coordinates);
        }
        if (typeof route.properties.legs === 'string') {
          route.properties.legs = JSON.parse(route.properties.legs);
        }
        return route;
      });
    };

    return loadUserMaps().then(response => {
      this.userMaps = response
        .filter(item => this.isCorrectMapStructure(item))
        .map(item => {
          item.source.routes = decompressRoute(item.source.routes);
          return item;
        });
    });
  }

  private prepareMapData() {
    const mapData: UserMapData = {
      ...this.mapDataInformation,
      startDate: this.rootStore.routeTimeStore.routeStartDateAsString,
      uuid: this.rootStore.directionsStore.uuid,
      isFav: false,
      tankPoints: toJS(this.rootStore.fuelStationsStore.fuelPoints),
      wayPoints: toJS(this.rootStore.wayPointsStore.wayPoints),
      weatherPoints: [],
      routes: toJS(this.rootStore.directionsStore.routesCollection),
    };

    if (this.mapId) {
      mapData._id = this.mapId;
    }

    return mapData;
  }

  private notify(type: NotificationType, message: string) {
    const notification = {
      type,
      name: 'map saving',
      message,
    };
    dispatchEventMessages(notification);
  }

  async saveMap() {
    this.notify(NotificationType.info, 'Saving map');
    const mapData = this.prepareMapData();
    const {thumbFilename} = await this.createMapThumbnail(mapData);

    if (thumbFilename) {
      mapData.imageFilename = thumbFilename;
    }

    const create = async () => {
      const {_id} = await createMap(mapData);
      this.mapId = _id as string;
      this.notify(NotificationType.info, 'New map saved');
    };

    const update = async () => {
      await updateMap(mapData);
      this.notify(NotificationType.info, 'Map updated');
    };

    (this.mapId)
      ? await update()
      : await create();
  }

  private async createMapThumbnail(mapData) {
    const directory = '';
    return await createThumbnail({...mapData, directory},
      MAP_THUMBNAIL_WIDTH,
      MAP_THUMBNAIL_HEIGHT,
      true,
      true,
    );
  }

  loadMap(userMap: UserMapsInterface) {
    this.mapId = userMap._id;
    this.rootStore.routeTimeStore.setStartDate(userMap.startDate as string);
    this.rootStore.wayPointsStore._wayPoints = userMap.source.wayPoints;
    this.rootStore.fuelStationsStore.setFuelPoints(userMap.source.tankPoints);
    this.rootStore.directionsStore.loadRoutes({routes: userMap.source.routes, uuid: userMap.source.uuid});
    this.rootStore.wayPointsStore.mergePoints();

    this.mapDataInformation = {
      mapName: userMap.name as string,
      description: userMap.description as string,
    };

    userMap.source.wayPoints.forEach((point: Feature<PointInterface>) => {
      if (point?.properties?.fuelPoints) {
        this.rootStore.fuelStationsStore.setFuelPoints(point.properties.fuelPoints);
      }
    });
  }

  isForecastOutdated = mapRouteStartDate => {
    const duration = moment.duration(moment().diff(mapRouteStartDate));
    return duration.asHours() > 3;
  };

  isCorrectMapStructure = ({source: {routes = []}}) => {
    return routes.length > 0;
  };

  updateMapDescription(titleData: MapTitleType) {
    this.mapDataInformation = titleData;
  }

  deleteUserMap(_id: string) {
    deleteUserMaps(_id)
      .then(() => {
        this.userMaps = this.userMaps.filter(map => map._id != _id);
      });
  }
}

export default UserMapStore;
