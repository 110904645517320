import {Point} from '@turf/turf';

import {PointType} from '../shared/const/wayPointType';

import {AddressInfoInterface} from './address/AddressInfo';
import {ItemForecastInterface} from './weather/ForecastWeatherInterface';
import {CurrentWeatherInterface} from './weather/CurrentWeatherInterface';
import {FuelStationsInterface} from './FuelStationsInterface';

export interface PointInterface extends Point {
  properties: PointProperties | null;
}

export interface PointDistance<T> {
  [routeIndex: number]: T;
}

export interface PointArrivingTimeInterface<T> {
  [routeIndex: number]: T;
}

export interface PointProperties {
  addressInfo?: AddressInfoInterface;
  distance?: PointDistance<number>;
  pointType?: PointType;
  id?: string;
  routePointIndex?: number | unknown;
  forecast?: ItemForecastInterface[];
  forecastIndex?: number;
  forecastLastUpdateDate?: string;
  weather?: CurrentWeatherInterface;
  showInList?: boolean;
  fuelPoints?: FuelStationsInterface[];
  index?: number;
  duration?: any;
  arrivingTime?: PointArrivingTimeInterface<number>
}

export enum WayPointInsertingType {
  AddOnBeginning = 'addOnBeginning',
  AddOnEnd = 'addOnEnd',
  AddAtIndex = 'addAtIndex'
}
