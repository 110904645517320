import {Route} from 'react-router-dom';
import React from 'react';

import {LayoutInterface} from '../../interfaces/LayoutInterface';
import ContentWrapper from '../../shared/components/ContentWrapper/ContentWrapper';
import MainAppBar from '../../shared/components/MainAppBar/MainAppBar';
import MainDrawer from '../../shared/components/MainDrawer';
import {Notificator} from '../../shared/components/Notificator/Notificator';
import ModalAddressLookup from '../../shared/components/ModalAddressLookup/ModalAddressLookup';


const DefaultLayout: React.FC<LayoutInterface> = ({
                                                    component: Component,
                                                    ...rest
                                                  }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <ContentWrapper>
        <MainAppBar/>
        <Component {...matchProps} />
        <MainDrawer/>
        <Notificator/>
        <ModalAddressLookup/>
      </ContentWrapper>
    )}
  />
);

export default DefaultLayout;
