import {Grid, IconButton, ListItem, ListItemSecondaryAction, ListItemText, useTheme} from '@material-ui/core';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useToggle} from 'hooks/useToggle';
import {observer} from 'mobx-react-lite';

import {PointInterface} from 'interfaces/PointInterface';

import MoreVertical from '@material-ui/icons/MoreVert';

import {Feature} from '@turf/turf';

import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';
import AddressDisplay from 'shared/components/Address/AddressDisplay';
import {Distances} from 'shared/components/Distance/Distance';
import WeatherTemp from 'shared/components/weather/WeatherTemp/WeatherTemp';
import WeatherIcon from 'shared/components/weather/WeatherIcon/WeatherIcon';
import {flexContainer} from 'shared/const/stylesDef';

import {PointContext} from '../../../MapProviders/MapPointProvider';
import {useStyles} from '../WaypointList';
import AvatarWayPoint from '../AvatarWayPoint';
import MapBoxHandler from '../../../MapBoxHandler';
import PointActionsContainer from '../PointActions/PointActionsContainer';
import {dispatchEnterMapPoint, dispatchLeaveMapPoint} from '../../../MapEvents';
import If from '../../../../../shared/components/If/If';
import {useForecast} from '../../../mapHooks/useForecast';
import ArrivingTime from '../../../../../shared/components/ArrivingTime/ArrivingTime';

import {PointItemBottomAction} from './PoinItemBottomAction';


interface WayPointListItemProps {
  index: number;
  wayPoint: Feature<PointInterface>;
  isAnyActionSelected: boolean;
  isForecastAvailable: boolean;
  actionSelected: () => void;
  actionUnselected: () => void;
}

export const WayPointListItem: React.FC<WayPointListItemProps> = observer((
  {
    wayPoint,
    actionSelected,
    actionUnselected,
    isForecastAvailable,
  }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {selectedPointIndex, setSelectedPointIndex} = useContext(PointContext);
  const [itemState, {on: setItemStateInfo, off: setItemAdditionalActions}] = useToggle(true);

  const onClick = useCallback(() => {
    if (itemState) {
      MapBoxHandler.flyTo(wayPoint?.geometry?.coordinates, 18);
    }
  }, [itemState, wayPoint]);

  const isSelected = useMemo(() => {
    return selectedPointIndex === wayPoint?.properties?.index;
  }, [selectedPointIndex, wayPoint]);

  const onMenuClick = useCallback(() => {
    actionSelected();
    setItemAdditionalActions();
    setSelectedPointIndex(wayPoint?.properties?.index);
  }, [actionSelected, setItemAdditionalActions, setSelectedPointIndex, wayPoint]);

  const onBack = useCallback(() => {
    actionUnselected();
    setItemStateInfo();
    setSelectedPointIndex(undefined);
  }, [actionUnselected, setItemStateInfo, setSelectedPointIndex]);

  useEffect(() => {
    if (!isSelected && !itemState) {
      setItemStateInfo();
    }
  }, [isSelected, itemState, setItemStateInfo]);

  const {weatherIcon, temp} = useForecast(wayPoint);

  const onMouseEnter = useCallback(() => {
    dispatchEnterMapPoint(wayPoint);
  }, [wayPoint]);

  const onMouseLeave = useCallback(() => {
    dispatchLeaveMapPoint();
  }, []);

  return (
    <ConditionalWrapper
      condition={itemState}
      skeleton={
        <PointActionsContainer
          wayPoint={wayPoint}
          onCancel={onBack}
          slideIn={true}
        />
      }
    >
      <>
        <ListItem
          className={classes.listItem}
          style={isSelected ? {backgroundColor: theme.palette.grey['50']} : {}}
          button
          key={wayPoint?.properties?.key}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <AvatarWayPoint
            pointType={wayPoint?.properties?.pointType}
            index={wayPoint?.properties?.index}
            onClick={() => void (0)}
          />
          <ListItemText
            className={classes.text}
            primary={
              <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                <Grid item>
                  <Distances distances={wayPoint.properties?.distance}/>
                </Grid>
                <If condition={isForecastAvailable}>
                  <Grid style={{...flexContainer}} item>
                    <div style={flexContainer}>
                      <WeatherIcon
                        icon={weatherIcon}
                        height={24}
                        width={24}
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <WeatherTemp temp={temp} showLabel={false} variant={'body1'}/>
                  </Grid>
                </If>
                <Grid style={{marginRight: '8px'}} item>
                  <ArrivingTime arrivingTime={wayPoint?.properties?.arrivingTime}/>
                </Grid>
              </Grid>
            }
            secondary={<AddressDisplay
              variant='body2'
              count={3}
              addressInfo={wayPoint?.properties?.addressInfo}
            />}
            secondaryTypographyProps={{component: 'div'}}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={onMenuClick}>
              <MoreVertical/>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <PointItemBottomAction
          point={wayPoint}
        />
      </>
    </ConditionalWrapper>
  );
});
