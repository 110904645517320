import {RoutesInterface} from 'interfaces/RoutesInterface';

import {http} from '../functions/HttpAuth';
import {dispatchEventMessages, NotificationType} from '../components/Notificator/Notifier';

const notificationInfo = {
  type: NotificationType.info,
  name: 'request direction',
  message: 'call for directions',
};

const notificationSuccess = {
  type: NotificationType.success,
  name: 'direction success',
  message: 'got new directions',
};

const url = '/api/route';
export const postDirection = (wayPoints: number[][], alternatives = true): Promise<RoutesInterface> => {
  dispatchEventMessages(notificationInfo);
  return http
    .post(url, {wayPoints, alternatives})
    .then((data) => {
      dispatchEventMessages(notificationSuccess);
      return data;
    });
};

export const calculateTankPointsOnRoute = (uuid: string, range: number, routeIndex: number): Promise<any> =>
  http.post(`${url}/calculate-points/tank-points/`, {
    uuid,
    range,
    routeIndex,
  });

export const calculateWeatherPointsOnRoute = (uuid: string, range: number, routeIndex: number) =>
  http.post(
    '/api/route/nearestPointOnRouteWorker-points/weather-old-points',
    {
      uuid,
      range,
      routeIndex,
    },
  );

export const pointProperties = (uuid: string, coords: number[], index: number) =>
  http.post(`${url}/point-properties`, {uuid, coords, index});
