import {Color, PropTypes} from '@material-ui/core';
import {useObserver} from 'mobx-react';
import React from 'react';

import Typography from '@material-ui/core/Typography';

import {useRootStore} from 'shared/stores/RootStore';

import {PointDistance} from '../../../interfaces/PointInterface';

type PropsCommon = {
  color?: Color | string | undefined;
  typographyProps?: any;
  component?: any;
}

type DistanceProps = {
  distance?: number;
  align?: PropTypes.Alignment;
} & PropsCommon

type DistancesProps = {
  distances: PointDistance<number>;
} & PropsCommon

export const useDistances = () => {
  const {unitsStore: {distanceUnit}, directionsStore: {selectedRouteIndex}} = useRootStore();
  return {distanceUnit, selectedRouteIndex};
};


export const Distances: React.FC<DistancesProps> = ({distances}) => {
  const {selectedRouteIndex} = useDistances();

  return useObserver(() => (
    distances && distances[selectedRouteIndex]
      ? <Distance distance={distances[selectedRouteIndex]}/>
      : null
  ));
};

export const Distance: React.FC<DistanceProps> = ({distance, align}) => {
  const {distanceUnit} = useDistances();

  return (
    distance && distance > 0
      ? (
        <Typography noWrap align={align ?? 'inherit'}>
          {distance && Math.round(distance / 1000)} {distanceUnit}
        </Typography>
      )
      : null
  );
};

export default Distance;
