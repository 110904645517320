import React from 'react';
import {Link} from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './style.css';

const randImages = [
	'404-background-1.jpg',
	'404-background-2.jpg',
	'404-background-3.jpg',
];

const backgroundImage = () => randImages[Math.floor(Math.random() * randImages.length)];

const CenterBlock = ({title, linkTo}) => {
	const styles = {
		backgroundImage: 'url(/img/' + backgroundImage() + ')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	};

	return (
		<div className={'container-page'}>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				style={styles}
			>
				<Grid item xs={5} lg={6} style={{display: 'flex', alignItems: 'center'}}>
					<Paper className={'padding'}>
						<Typography >{title}</Typography>
						<Link to="/">
							<Typography >{linkTo}</Typography>
						</Link>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default CenterBlock;
