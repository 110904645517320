import React from 'react';
import {useObserver} from 'mobx-react';

import IconButton from '@material-ui/core/IconButton';

import SaveIcon from '@material-ui/icons/Save';

import {useRootStore} from 'shared/stores/RootStore';

import MapTitleModal from '../../MapTitleModal/MapTitleModal';
import {useToggle} from '../../../../hooks/useToggle';

const SaveMapButton: React.FC = () => {
  const [modalOpen, {on: open, off}] = useToggle(false);
  const {directionsStore} = useRootStore();
  
  return useObserver(() => (
    <React.Fragment>
      <IconButton
        color="inherit"
        onClick={open}
        disabled={directionsStore.routesCount < 1}
      >
        <SaveIcon/>
      </IconButton>
      <MapTitleModal open={modalOpen} onClose={off}/>
    </React.Fragment>
  ));
};

export default SaveMapButton;
