import React, {ReactElement} from 'react';

interface Props {
  condition: boolean;
  children: unknown;
}

const If: React.FC<Props> = ({condition, children}): ReactElement<any, any> | null => {
  return (<>{condition ? children : null}</>);
};

export default If;
