import React, {useCallback} from 'react';

import {doNothing} from '../../../shared/utils/functionsUtils';

export interface MapInstanceProps {
  mapBox?: any;
  setMap: (map: any) => void;
}

export const initialDataMap: MapInstanceProps = {
  mapBox: null,
  setMap: doNothing,
};

let mapBoxInstance: any = null;

export const MapInstanceContext = React.createContext<MapInstanceProps>(initialDataMap);

export const MapInstanceWrapper = ({children}) => {

  const setMap = useCallback((map) => {
    mapBoxInstance = map;
  }, []);

  return (
    <MapInstanceContext.Provider value={{mapBox: mapBoxInstance, setMap}}>
      {children}
    </MapInstanceContext.Provider>);
};
