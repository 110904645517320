import Axios, {AxiosRequestConfig} from 'axios';
import {toJS} from 'mobx';

import middleware from '../../mw/mw';
import Log from '../utils/Log';

import * as Storage from './Storage';
import {add, removeKey} from './Storage';
import mapResponse from './mapResponse';

export interface HeaderInterface {
  Authorization: string;
  Accept: string;
  'App-language': string;
}

export class AuthToken {
  constructor() {
    const storageToken = Storage.get('token');

    if (storageToken) {
      this._token = storageToken;
    } else {
      Log.warn('no auth authToken found');
    }
  }

  private _token: string | undefined = undefined;

  get token(): string {
    return this._token as string;
  }

  set token(token: string) {
    if (token) {
      add('token', token);
      this._token = token;
    }
  }

  clearToken(): void {
    removeKey('token');
    this._token = undefined;
  }
}

const apiHosts = {
  api: process.env.REACT_APP_API_URL,
  images: process.env.REACT_APP_IMAGE_API,
};

class Http {
  _config = {
    headers: {},
    baseUrl: '',
  };

  get(url: string): Promise<any> {
    return Axios
      .get(url, this.configHeaders())
      .then(this.mapResponse);
  }

  post(url: string, body: any, config?: any): Promise<any> {
    return Axios
      .post(url, body, config ?? this.configHeaders())
      .then(this.mapResponse);
  }

  put = (url: string, body, host = apiHost, config?: any): Promise<any> => {
    return Axios
      .put(host + url, body, config ?? this.configHeaders());
  };

  delete = (url, host = apiHost, config?: any): Promise<any> => {
    return Axios.delete(host + url, config ?? this.configHeaders());
  };

  setBaseUrl(baseUrl: string) {
    this._config.baseUrl = baseUrl;
  }

  headers(): HeaderInterface {
    return {
      'Authorization': `Bearer ${new AuthToken().token}`,
      'Accept': 'application/json',
      'App-language': this.getLanguage(),
    };
  }

  private getLanguage(): string {
    return Storage.get('lang') ?? navigator.language;
  }

  private configHeaders() {
    return {
      headers: this.headers(),
      baseURL: apiHosts.api,
    };
  }

  private mapResponse = ({data}) => (data.data ? data.data : data);
}

export const authToken = new AuthToken();
export const http = new Http();
export const apiHost = process.env.REACT_APP_API_URL;

export const configHeaders = () => {
  return toJS(http.headers());
};

export const requestConfig = (headers: HeaderInterface, baseURL?: string) => (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  config.headers = headers;
  config.baseURL = baseURL ?? process.env.REACT_APP_API_URL;
  return Promise.resolve(config);
};

export const axiosInstance = Axios.create();

export const remove = (url: string, host = apiHost, headers = middleware.authHeaders) =>
  Axios.delete(host + url, {headers}).then(mapResponse);

export const catchError = (err: unknown) => {
  console.log(err);
  Log.error('catchError', err);
  throw err;
};
