import {MapProviders} from 'pages/maps/MapProviders/MapProvider';
import {Route} from 'react-router-dom';
import MapDrawer from 'pages/maps/mapDrawer/MapDrawer';
import React from 'react';

import {LayoutInterface} from 'interfaces/LayoutInterface';

import ModalAddressLookup from 'shared/components/ModalAddressLookup/ModalAddressLookup';

import {Notificator} from '../../shared/components/Notificator/Notificator';
import CalendarDialog from '../../shared/components/CalendarDialog/CalendarDialog';

const MapsLayout: React.FC<LayoutInterface> = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <MapProviders>
          <Component {...props}/>
          <MapDrawer/>
          <ModalAddressLookup/>
          <Notificator/>
          <CalendarDialog/>
        </MapProviders>
      )}
    />
  );
};

export default MapsLayout;
