import {DialogContent, TextField} from '@material-ui/core';
import React from 'react';

type Props = {
  name: string;
  label: string;
  type?: string;
  value: string;
  error: string;
  onChange: (event: any) => void;
  [x:string]: any;
}

export const InputField: React.FC<Props> = ({name, label, type = 'text', value, error, onChange, ...rest}) => {
  return (
    <DialogContent>
      <TextField
        name={name}
        label={label}
        type={type}
        autoComplete="off"
        fullWidth
        value={value}
        error={error.length > 0}
        helperText={error}
        onChange={onChange}
        {...rest}
      />
    </DialogContent>
  );
};
