import React, {useCallback, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {makeStyles} from '@material-ui/core';
import {useToggle} from 'hooks/useToggle';

import {Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import {useRootStore} from 'shared/stores/RootStore';
import {flexContainerCenter, fullWidth} from 'shared/const/stylesDef';

import {PointContext} from '../../MapProviders/MapPointProvider';
import StartRouteBlock from '../StartRouteBlock/StartRouteBlock';

import {DeletePointConfirmDialog} from './PointActions/DeletePOintConfirmDialog';
import {WayPointListItem} from './PointItem/WayPoinItem';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '8px',
  },
  text: {
    marginLeft: '16px',
    marginRight: '32px',
  },
  listItem: {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 8 * 2,
      paddingBottom: 8 * 2,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  listItemBottomRow: {
    ...fullWidth,
    ...flexContainerCenter,
    position: 'relative',
    height: 0,
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '85px',
    alignItems: 'space-between',
    '&:nth-child(1)': {
      order: 0,
      flex: '1 1 auto',
      alignSelf: 'stretch',
    },
    '&:nth-child(2)': {
      order: 0,
      flex: '0 1 auto',
      alignSelf: 'stretch',
    },
  },
}));

const WayPointsList: React.FC = () => {
  const {wayPointsStore,routeTimeStore} = useRootStore();
  const {hideDeleteDialog, selectedPoint} = useContext(PointContext);
  const {setSelectedPointIndex} = useContext(PointContext);
  const [isAnyActionSelected, {on: select, off: unselect}] = useToggle(false);
  const classes = useStyles();

  const onDeletePopupConfirm = useCallback(() => {
    selectedPoint && wayPointsStore.deleteWayPoint(selectedPoint);
    hideDeleteDialog();
  }, [hideDeleteDialog, selectedPoint, wayPointsStore]);

  const onDeletePopupCancel = useCallback(() => {
    hideDeleteDialog();
  }, [hideDeleteDialog]);

  const onMouseLeave = useCallback(() => {
    setSelectedPointIndex(undefined);
    unselect();
  }, [setSelectedPointIndex, unselect]);

  return (
    <>
      <List className={classes.container} onMouseLeave={onMouseLeave}>
        {wayPointsStore.mergedPoints
          .map((wayPoint, index) => (
            <WayPointListItem
              isForecastAvailable={routeTimeStore.isForecastAvailable}
              isAnyActionSelected={isAnyActionSelected}
              key={index}
              wayPoint={wayPoint}
              index={index}
              actionSelected={select}
              actionUnselected={unselect}
            />
          ))}
        <StartRouteBlock/>
      </List>
      <DeletePointConfirmDialog
        wayPoint={selectedPoint}
        onClose={onDeletePopupCancel}
        onConfirm={onDeletePopupConfirm}/>
    </>
  );
};

export default observer(WayPointsList);
