import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import moment,{Moment} from 'moment';
import Calendar from 'react-calendar';
import './Calendar.css';
import {Alert} from '@material-ui/lab';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import {useRootStore} from '../../stores/RootStore';
import {useEventListener} from '../../../hooks/useEventListener';
import {MapRouteDateDialog} from '../../const/mapEvents';
import DateDisplay,{DateFormat} from '../DateDisplay/DateDisplay';
import {UserContext} from '../../../providers/UserWrapper';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import RouteTimeInput from '../../../pages/maps/directionsControl/RouteTimeInput';

const CalendarDialog: React.FC = () => {
  const [tempDate, setTempDate] = useState<Date>();
  const {routeTimeStore} = useRootStore();
  const [openDialog, setOpenDialogState] = useState<boolean>(false);
  const {profile: {language}} = useContext(UserContext);

  useEffect(() => {
    if (openDialog && !tempDate) {
      setTempDate(moment(routeTimeStore.routeStartDate).toDate());
    }
  }, [openDialog, routeTimeStore.routeStartDate, tempDate]);

  const onMapEvent = useCallback(() => {
    setOpenDialogState(true);
  }, []);

  useEventListener(MapRouteDateDialog.openMapDialog.toString(), onMapEvent);

  const selectDay = useCallback((dateSelected: Date | Moment) => {
    const time = moment(tempDate).format(DateFormat.Time);
    const date = moment(dateSelected).format(DateFormat.DefaultDate);
    setTempDate(moment(`${date} ${time}`).toDate());
  }, [tempDate]);

  const handleClose = useCallback(() => {
    setOpenDialogState(false);
  }, []);

  const handleConfirm = useCallback(() => {
    routeTimeStore.setStartDate(moment(tempDate));
    handleClose();
  }, [handleClose, routeTimeStore, tempDate]);

  const changeTime = useCallback((time: Date) => {
    const date = moment(tempDate).format(DateFormat.DefaultDate);
    const timeChanged = moment(time).format(DateFormat.Time);
    setTempDate(moment(`${date} ${timeChanged}`).toDate());
  }, [tempDate]);

  return (
    <Dialog maxWidth="xs" open={openDialog}>
      <DialogTitle disableTypography>
        <Typography>When <DateDisplay date={tempDate as Date}/></Typography>
        <RouteTimeInput onChange={changeTime} date={tempDate}/>
      </DialogTitle>
      <DialogContent>
        <Calendar
          locale={language}
          maxDate={routeTimeStore.calendarMaxDate}
          minDate={routeTimeStore.calendarMinDate}
          value={routeTimeStore.routeStartDate}
          onClickDay={selectDay}
        />
      </DialogContent>
      <ConditionalWrapper
        skeleton={<div style={{height: '48px', width: '100%'}}/>}
        condition={!!tempDate && !routeTimeStore.checkIsForecastIsAvailable(tempDate)}
      >
        <Alert severity="warning">The forecast is not available for this day</Alert>
      </ConditionalWrapper>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="outlined">
          select
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default withMobileDialog()(CalendarDialog);
