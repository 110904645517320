import React from 'react';
import {first, last} from 'lodash';
import {Theme} from '@material-ui/core';

import {withStyles} from '@material-ui/core/styles';

import {StyleType} from 'interfaces/Styles';

import PlaceIcon from '@material-ui/icons/Place';
import FlagIcon from '@material-ui/icons/Flag';

import {Feature, Point} from '@turf/turf';

import AddressDisplay from 'shared/components/Address/AddressDisplay';


interface Props {
  source: {
    wayPoints: Feature<Point>[];
  };
  count?:number;
  classes: any;
}

const MapTitle: React.FC<Props> = ({source, classes, count}) => {
  const firstPointAddressInfo = (first(source.wayPoints) as Feature<Point>)?.properties?.addressInfo;
  const lastPointAddressInfo = (last(source.wayPoints) as Feature<Point>)?.properties?.addressInfo;

  return (
    <div className={classes.mapTitle}>
      <div className={classes.mapTitleRow}>
        <PlaceIcon/>
        <AddressDisplay
          addressInfo={firstPointAddressInfo}
          count={count ?? 2}
          alignItems={'flex-start'}
        />
      </div>
      <div className={classes.mapTitleRow}>
        <FlagIcon/>
        <AddressDisplay
          addressInfo={lastPointAddressInfo}
          count={count ?? 2}
          alignItems={'flex-start'}
        />
      </div>
    </div>
  );
};

const styles: StyleType = (theme: Theme) => ({
  mapTitle: {
    color: theme.palette.info.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  mapTitleRow:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
});

export default withStyles(styles)(MapTitle);
