export const METRIC_UNIT = 'metric';
export const IMPERIAL_UNIT = 'imperial';

const fuelConsumption = obj => obj.capacity.short + '/100 ' + obj.distance.short;
const speed = obj => obj.distance.short + '/h';

export const imperialUnit = {
  capacity: {
    name: 'Gallon',
    short: 'gal',
  },
  distance: {
    name: 'Miles',
    short: 'mi',
  },
  temperature: {
    name: 'Celsius',
    short: {__html: '&#8457;'},
  },
  fuelConsumptionUnit: fuelConsumption.bind(this),
  speed: speed.bind(this),
};

export const metricUnit = {
  capacity: {
    name: 'Liters',
    short: 'L',
  },
  distance: {
    name: 'Kilometers',
    short: 'Km',
  },
  temperature: {
    name: 'Celsius',
    short: {__html: '&deg;'},
  },
  fuelConsumptionUnit: fuelConsumption.bind(this),
  speed: speed.bind(this),
};
