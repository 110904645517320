import * as Yup from 'yup';

export const validationOptions = {
  strict: false,
  abortEarly: false,
  stripUnknown: false,
};

const registerPasswordValidator = Yup.string()
  .required('password is required')
  .max(50)
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
    'Must contain at least 8 characters, one uppercase, one lowercase and one digit',
  );

const registerRepeatPasswordValidator = Yup.string()
  .required('Please repeat password')
  .oneOf([Yup.ref('password'), null], 'passwords must match');


const registerEmailValidator = Yup.string()
  .required()
  .email()
  .min(5)
  .max(50);

export const registerValidators = Yup.object().shape({
  email: registerEmailValidator,
  passwordRepeat: registerRepeatPasswordValidator,
  password: registerPasswordValidator,
});

export const validate = (validator: any, registrationData: Record<string, unknown>): Promise<boolean | Record<string, unknown>> =>
  validator.validate(registrationData, validationOptions)
    .catch(e => {
      const validationObject = {...registrationData};
      Object.keys(validationObject).forEach(key => {
        validationObject[key] = '';
      });
      e.inner.forEach(({path, message}) => {
        validationObject[path] = message;
      });
      return Promise.reject(validationObject);
    });
