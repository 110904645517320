import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import {Position} from '@turf/turf';

import MapDistanceWorker from './workers/distance';
import PoiWorker from './workers/poi';
import LayoutWrapper from './shared/components/LayoutWrapper/LayoutWrapper';
import RootStore, {RootStoreProvider} from './shared/stores/RootStore';
import Routes from './Routes';
import AppProvider from './providers/AppProvider';
import {configHeaders} from './shared/functions/HttpAuth';
import {LatLngInterface} from './interfaces/LatLngInterface';

export interface CalculateDistanceProps {
  index: number;
  routeLength: number;
}

const instanceDistanceWorker = new MapDistanceWorker();
const instancePoiWorker = new PoiWorker();

export const findTankStations = async (coordinates: number[]): Promise<any> => {
  return await instancePoiWorker.getPoiPointsForCoordinates(coordinates, configHeaders());
};

export const calculateNearestPointOnRoute = ({lngLat, geometry}: {lngLat: LatLngInterface, geometry: Position[]}) => {
  if (geometry && lngLat) {
    return Promise.resolve();
  }
};

export const calculateDistanceFromStart = async (coordinates: number[], routeCoords: number[][]): Promise<CalculateDistanceProps | never> => {
  return await instanceDistanceWorker.distanceFromStartWorker(coordinates, routeCoords);
};

const App = () => {
  return (
    <>
      <CssBaseline/>
      <AppProvider>
        <RootStoreProvider value={RootStore}>
          <LayoutWrapper>
            <Routes/>
          </LayoutWrapper>
        </RootStoreProvider>
      </AppProvider>
    </>
  );
};

export default App;
