import React from 'react';

import {ZoomWrapper} from './ZoomProvider';
import {MapInstanceWrapper} from './MapInstanceProvider';
import {MapPointProvider} from './MapPointProvider';

export const MapProviders: React.FC = ({children}) => {
  return (
    <MapInstanceWrapper>
      <MapPointProvider>
        <ZoomWrapper>{children}</ZoomWrapper>
      </MapPointProvider>
    </MapInstanceWrapper>
  );
};
