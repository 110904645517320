import {GridItemsAlignment} from '@material-ui/core/Grid/Grid';
import {PropTypes} from '@material-ui/core';
import {Variant as ThemeVariant} from '@material-ui/core/styles/createTypography';
import {TypographyProps} from '@material-ui/core/Typography/Typography';
import React from 'react';

import Grid, {GridDirection} from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {AnyInterface} from 'interfaces/AnyInterface';
import {AddressInfoInterface} from 'interfaces/address/AddressInfo';

import {cutAddress} from '../../functions/CutAddress';

interface Props extends AnyInterface {
  addressInfo?: AddressInfoInterface;
  align?: PropTypes.Alignment;
  count?: number;
  component?: React.ReactType<TypographyProps>;
  alignItems?: GridItemsAlignment;
  direction?: GridDirection;
  wrap?: boolean;
  variant?: ThemeVariant;
  disableGrid?: boolean;
}


const AddressDisplay: React.FC<Props> = (props) => {
  const {
    disableGrid = true,
    wrap = false,
    addressInfo,
    count = 3,
    align,
    direction,
    alignItems = 'center',
    variant,
  } = props;
  return (
    (disableGrid && addressInfo?.address) ? (
      <Typography paragraph={false} noWrap={wrap} variant={variant} align={align}>
        {cutAddress(addressInfo?.display_name, count)}
      </Typography>
    ) : (
      <Grid
        container
        direction={direction}
        justify={'center'}
        alignItems={alignItems}
      >
        <Typography paragraph={false} noWrap={wrap} variant={variant} align={align}>
          {cutAddress(addressInfo?.display_name, count)}
        </Typography>
      </Grid>
    )
  );
};

export default AddressDisplay;
