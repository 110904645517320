import React, {useCallback, useMemo} from 'react';
import {Badge} from '@material-ui/core';
import {observer} from 'mobx-react-lite';

import IconButton from '@material-ui/core/IconButton';

import FuelStationIcon from '@material-ui/icons/LocalGasStationTwoTone';

import {Feature, Position} from '@turf/turf';

import {PointType} from '../../../../../shared/const/wayPointType';
import {PointInterface} from '../../../../../interfaces/PointInterface';
import ConditionalWrapper from '../../../../../shared/components/ConditionalWrapper/ConditionalWrapper';
import {useRootStore} from '../../../../../shared/stores/RootStore';
import MapBoxHandler from '../../../MapBoxHandler';
import {FuelStationsInterface} from '../../../../../interfaces/FuelStationsInterface';

interface Props {
  wayPoint: Feature<PointInterface>
}

const FindGasStationsButton: React.FC<Props> = ({wayPoint}) => {
  const {fuelStationsStore} = useRootStore();
  const condition = wayPoint.properties?.pointType === PointType.newWayPoint;

  const fuelPointsLength = useMemo(() => {
    return wayPoint?.properties?.fuelPoints
      ? wayPoint.properties.fuelPoints.length
      : 0;
  }, [wayPoint?.properties?.fuelPoints]);

  const fitToCoords = (fuelPoints: FuelStationsInterface[]) => {
    const coordinates: Position[] = fuelPoints.map((point) => [point.lon, point.lat]);
    MapBoxHandler.fitToBoundingBox(coordinates);
  };

  const focusOnFuelStations = useCallback(() => {
    if (wayPoint?.properties?.fuelPoints) {
      fitToCoords(wayPoint.properties.fuelPoints);
    }
  }, [wayPoint?.properties?.fuelPoints]);

  const fetchFuelStations = useCallback(async () => {
    try {
      const stations = await fuelStationsStore.findStations(wayPoint);
      setTimeout(() => {
        fitToCoords(stations);
      }, 0);
    } catch (e) {
      console.error(e);
    }
  }, [fuelStationsStore, wayPoint]);

  return (
    <ConditionalWrapper
      condition={condition && fuelPointsLength > 0}
      skeleton={
        <IconButton color="default" onClick={fetchFuelStations}>
          <FuelStationIcon/>
        </IconButton>
      }
    >
      <IconButton color="primary" onClick={focusOnFuelStations}>
        <Badge badgeContent={fuelPointsLength} color="primary">
          <FuelStationIcon/>
        </Badge>
      </IconButton>
    </ConditionalWrapper>);
};

export default observer(FindGasStationsButton);
