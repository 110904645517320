import React, {useMemo} from 'react';
import {createStyles, makeStyles, Theme, Typography, useTheme} from '@material-ui/core';

import {CloudsInterface} from 'interfaces/weather/Clouds';

import CloudIcon from '@material-ui/icons/Cloud';

import {flexContainerCenter} from '../../../const/stylesDef';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      ...flexContainerCenter,
      ...{
        position: 'relative',
        minWidth: '80px',
        padding: '8px',
      },
    },
    value: {
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize:'12px',
      color:theme.palette.grey.A400
    },
  }));

interface Props {
  clouds: CloudsInterface
}

const Clouds: React.FC<Props> = ({clouds}) => {
  const cloudsData = useMemo(() => {
    return {val: clouds.all, opacity: clouds.all / 100};
  }, [clouds.all]);
  const theme = useTheme();

  const classes = useStyles();
  return (
    cloudsData.val > 10 ? (<div className={classes.container}>
        <Typography component={'p'} className={classes.value}>
          {cloudsData.val} %
        </Typography>
        <CloudIcon style={{opacity: cloudsData.opacity, color: theme.palette.info.main}}/>
      </div>)
      : null
  );
};

export default React.memo(Clouds);
