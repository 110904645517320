import React, {useState} from 'react';

import {doNothing} from 'shared/utils/functionsUtils';

interface WayPointsListContextInterface {
  wayPointsIdSelected?: string;
  setWayPointsIdSelected: (id?: string) => void;
}

const initialData: WayPointsListContextInterface = {
  wayPointsIdSelected: undefined,
  setWayPointsIdSelected: doNothing,
};

export const WayPointsListContext = React.createContext(initialData);

export const WayPointsListContextWrapper: React.FC = ({children}) => {
  const [wayPointsIdSelected, setWayPointsIdSelected] = useState<string>();
  return (
    <WayPointsListContext.Provider value={{
      wayPointsIdSelected,
      setWayPointsIdSelected,
    }}>{children}</WayPointsListContext.Provider>
  );
};
