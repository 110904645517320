import React from 'react';
import {useTheme} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import {RainInterface} from 'interfaces/weather/RainInterface';

interface Props {
  rain?: RainInterface;
  snow?: RainInterface;
}

const Precipitation: React.FC<Props> = ({rain, snow}) => {
  const rainAmount = rain?.['3h'];
  const snowAmount = snow?.['3h'];
  const theme = useTheme();

  return rainAmount || snowAmount ? (
    <Grid
      container
      direction="column"
      alignContent="center"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Typography component="span" style={{marginRight: '8px', fontSize: '14px', color: theme.palette.info.light}}>
          {rainAmount && 'Rain'}
          {snowAmount && 'Snow'}
        </Typography>
      </Grid>
      <Grid item>
        <Divider/>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignContent="center" alignItems="center">
          <Grid item>
            <Typography style={{fontSize: '16px', color: theme.palette.info.dark}}>
              {rainAmount && rainAmount.toFixed(1)}
              {snowAmount && snowAmount.toFixed(1)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="span" style={{marginLeft: '2px', fontSize: '16px', color: theme.palette.info.dark}}>
              mm
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default Precipitation;
