import {action, observable, toJS} from 'mobx';

// import PoiWorker from '../../workers/fuelStations.worker';

import {AuthToken as Headers} from '../functions/HttpAuth';
import {RootStoreInterface} from '../../interfaces/stores/RootStoreInterface';

class PoiMapStore {
  private readonly rootStore: RootStoreInterface<any>;
  private poiWorker;
  @observable _pointOfInterest:{[x:string]: any} = {};

  constructor(rootStore:RootStoreInterface<any>) {
    this.rootStore = rootStore;
    this._pointOfInterest = {};
  }

  @action.bound
  findPoiPoints(point) {
    this.poiWorker.postMessage({
      token: new Headers().token,
      point: toJS(point),
    });
  }

  @action
  setPoiKey(key: string) {
    if (this._pointOfInterest.includes(key)) {
      this._pointOfInterest = {...this._pointOfInterest, ...{key: []}};
    }
  }
}

export default PoiMapStore;
