import {http, remove} from '../functions/HttpAuth';

export const postMotorcyclesByMakeAndYearResource = ({Make, Year}) =>
  http.post('/api/motorcycles', {Make, Year});

export const motorcyclesProducerResource = ({query}) =>
  http.post('/api/motorcycles/producer', {query});

export const getProducersResource = () => http.get('/api/motorcycles/make');

export const getYearsResource = make => http.get(`/api/motorcycles/years?make=${make}`);

export const createMotorcyclesResource = params => http.post('/api/motorcycles/new', {params});

export const createUserMotorcyclesResource = params =>
  http.post('/api/motorcycles/addusermotorcycle', params);

export const getUserMotorcyclesResource = () => http.get('/api/motorcycles/usermotorcycles');

export const deletetUserMotorcyclesResource = id =>
  remove(`/api/motorcycles/usermotorcycles?id=${id}`);

export const addImageResource = (files, id: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i]);
  }
  formData.append('id', id);
  return http.post('/motorcycles', formData, process.env.REACT_APP_IMAGE_API);
};

export const updateMotorcycleDataRequest = (data, id: string) => {
  delete data.modelData;
  return http.put(`/api/motorcycles/usermotorcycles?id=${id}`, data);
};

export const deleteMotorcyclePhotoRequest = (filename: string) =>
  remove(`/motorcycles?filename=${filename}`, process.env.REACT_APP_IMAGE_API);
