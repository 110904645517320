import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import {flexContainerCenter} from '../../const/stylesDef';


interface Props {
  onClose: () => void;
  onConfirm: () => void;
  children?: any;
  open: boolean;
  cancelLabel?: string;
  okLabel?: string;
  title?: string;
  contentHTML?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      minWidth: '320px',
      borderBottomColor: theme.palette.grey.A200,
      borderBottom: '1px solid',
    },
    dialogActions: {
      ...flexContainerCenter,
      borderTopColor: theme.palette.grey.A100,
      borderTop: '1px solid',
    },
    dialogContent: {
      ...flexContainerCenter,
      minHeight: '200px',
    },
    root: {
      backgroundColor: theme.palette.grey.A100,
    },
  }));

const ConfirmDialog: React.FC<Props> = (props) => {
  const {
    title = 'Confirm',
    okLabel = 'OK',
    cancelLabel = 'Cancel',
    open = false,
    children,
    contentHTML,
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };

  const handleConfirm = () => {
    props.onConfirm();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ConditionalWrapper
          condition={!contentHTML}
          skeleton={children}
        >
          <DialogContentText component={'div'}>{children}</DialogContentText>
        </ConditionalWrapper>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleConfirm} color="primary">
          {okLabel}
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
