import React, {useContext} from 'react';
import {Button} from '@material-ui/core';
import {GoogleLogin} from 'react-google-login';

import {googleSignUpAction} from '../../requests/auth.resource';
import Log from '../../utils/Log';
import {CLIENT_ID} from '../../const/googleClientId';
import {AuthContext} from '../../../providers/AuthWrapper';

export const GoogleSignInButton: React.FC = () => {
  const {authenticateFromGoogle} = useContext(AuthContext);
  const onSuccess = (response) => {
    googleSignUpAction(response)
      .then(({token}) => {
        authenticateFromGoogle(token);
      })
      .catch(e => {
        Log.error(e);
      });
  };

  const onFailure = (response) => {
    Log.error(response);
  };

  return (
    <GoogleLogin
      clientId={CLIENT_ID}
      render={renderProps => (
        <Button
          variant='outlined'
          fullWidth
          color='primary'
          size='large'
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >Sign In with Google</Button>
      )}
      buttonText="Login"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    />
  );
};
