import React from 'react';

import {useToggle} from '../hooks/useToggle';
import {doNothing} from '../shared/utils/functionsUtils';

interface DrawersProps {
  mainDrawerState: boolean;
  mapDrawerState: boolean;
  toggleMainDrawer: () => void;
  toggleMapDrawer: () => void;
  hideMainDrawer: () => void;
}

const initialDrawersData = {
  mainDrawerState: false,
  mapDrawerState: false,
  toggleMainDrawer: doNothing,
  toggleMapDrawer: doNothing,
  hideMainDrawer: doNothing,
};

export const DrawersContext = React.createContext<DrawersProps>(initialDrawersData);

export const DrawersProvider = ({children}) => {
  const [mainDrawerState, {toggle: switchMainDrawer, off: hideMainDrawer}] = useToggle(false);
  const [mapDrawerState, {toggle: switchMapDrawerState}] = useToggle(true);

  return (
    <DrawersContext.Provider
      value={{
        mainDrawerState,
        mapDrawerState,
        toggleMainDrawer: switchMainDrawer,
        toggleMapDrawer: switchMapDrawerState,
        hideMainDrawer,
      }}
    >{children}
    </DrawersContext.Provider>
  );
};
