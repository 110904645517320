import React, {useCallback, useState} from 'react';
import {Alert} from '@material-ui/lab';

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {randomString} from '../../functions/randomString';
import {useEventListener} from '../../../hooks/useEventListener';
import Log from '../../utils/Log';

import {EVENT_NOTIFY, NotificationPayload} from './Notifier';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'fixed',
    right: '90px',
    bottom: '30px',
    zIndex: theme.zIndex.tooltip,
  },
  alert: {
    marginTop: '8px',
  },
}));

const defaultTimeout = 5000;

export const Notificator: React.FC = () => {
  const [messages, setMessages] = useState<NotificationPayload[]>([]);

  const onUnmount = useCallback(() => {
    setMessages([]);
  }, []);

  const filterMessages = useCallback((payload: NotificationPayload) => {
    const filterItems = (item) => item.id !== payload.id;
    setMessages(current => current.filter(filterItems));
  }, []);

  const addMessage = useCallback((evt) => {
    const payload = evt.detail.payload;
    setMessages(current => [...current, payload]);
    Log.info(`Set notify message: "${payload.message}"`);
    window.setTimeout(() => filterMessages(payload), payload.timeout ?? defaultTimeout);
  }, [filterMessages]);

  useEventListener(EVENT_NOTIFY, addMessage, onUnmount);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {messages.map(message => (
        <Alert severity={message.type} key={randomString()} className={classes.alert}>
          {message.message}
        </Alert>
      ))}
    </div>
  );
};
