import {useContext, useMemo} from 'react';

import {UserHomeLocationContext} from '../../../providers/UserLocationWrapper';

const defaultCenter = [-0.520458, 51.611782];

export const useUserLocationCoords = (): number[] => {
  const {userHomeLocationData} = useContext(UserHomeLocationContext);

  return useMemo((): number[] => {
    return userHomeLocationData?.homePoint?.geometry?.coordinates ? userHomeLocationData.homePoint.geometry.coordinates : defaultCenter;
  }, [userHomeLocationData]);
};
