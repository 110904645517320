import React from 'react';
import moment from 'moment';

export enum DateFormat {
  DayNameShort = 'dd',
  Hour = 'H',
  Time = 'HH:mm',
  DefaultDate = 'YYYY-MM-DD',
  DayHour = 'dd, HH:mm',
  FullDate = 'YYYY-MM-DD HH:mm',
  LongDay = 'dddd, Do MMMM',
  shortDate = 'ddd, DD MMM HH:mm',
}

type Props = {
  date?: string | Date | moment.Moment;
  format?: DateFormat;
  calendar?: boolean;
}

const DateDisplay: React.FC<Props> = React.memo(({date, format = DateFormat.LongDay, calendar}) => {
  const momentDate = moment(date ?? undefined);
  return (
    <>
      {calendar
        ? momentDate.calendar()
        : momentDate.format(format)
      }
    </>
  );
});

DateDisplay.displayName = 'DateDisplay';
export default DateDisplay;
