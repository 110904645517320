import React from 'react';

import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';

import AuthContainer from 'shared/components/AuthContainer/AuthContainer';

import MainMenuButtonBar from '../MainMenuButtonBar/MainMenuButtonBar';

interface Props {
  position?: string;
}

const MainAppBar: React.FC<Props> = () => {
  return (
    <AppBar>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={6} sm={4}>

          </Grid>
          <Grid item xs={6} sm={2}>
            <AuthContainer/>
            <MainMenuButtonBar/>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(MainAppBar);
