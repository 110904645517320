import React, {useCallback, useContext} from 'react';
import {Feature, Layer} from 'react-mapbox-gl';
import {observer} from 'mobx-react-lite';

import {LineString, lineString, MultiLineString} from '@turf/turf';

import {useRootStore} from 'shared/stores/RootStore';
import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';

import {lineLayout, routeSelectedHover, useMapBoxTheme} from '../LayerPointStyle';
import {ZoomContext} from '../../MapProviders/ZoomProvider';
import {PointContext} from '../../MapProviders/MapPointProvider';
import Log from '../../../../shared/utils/Log';
import {clearMapEvent, onRouteEnter, onRouteLeave} from '../../MapEvents';
import {useTimer} from '../../../../hooks/useTimer';

import {DirectionLayerPointContext} from './DirectionLayerProvider';


type Props = {
  before?: string;
}
/**
 * extract Linestring for displaying point on map
 * @param geometry LineString | MultiLineString
 */
const generateLineCoords = (geometry: LineString | MultiLineString) => {
  try {

    if (geometry.type === 'LineString') {
      return lineString(geometry.coordinates);
    }

    if (geometry.type === 'MultiLineString') {
      return lineString(geometry.coordinates[0]);
    }
  } catch (e) {
    Log.error(e);
  }
};

const DirectionLayer: React.FC<Props> = (props) => {
  const {directionsStore} = useRootStore();
  const {clearCoords, setLineString, findNearestPoint} = useContext(DirectionLayerPointContext);
  const {zoom} = useContext(ZoomContext);
  const {routePointDragging} = useContext(PointContext);
  const zoomShowPoint = 6;
  const mapBoxTheme = useMapBoxTheme();

  const {onStartTimer: startTimer, stopTimer} = useTimer(() => {
    clearCoords();
    clearMapEvent();
  }, 1500);

  const onMouseEnter = useCallback(async ({features}) => {
    stopTimer();
    onRouteEnter();
    if (features[0]?.geometry) {
      const coords = generateLineCoords(features[0].geometry);
      coords && setLineString(coords);
    }
  }, [setLineString, stopTimer]);

  const onMouseLeave = useCallback(() => {
    if (!routePointDragging) {
      startTimer();
    }
    onRouteLeave();
  }, [routePointDragging, startTimer]);

  const onMouseMove = useCallback(async (event) => {
    if (zoom > zoomShowPoint) {
      findNearestPoint(event.lngLat);
    }
  }, [findNearestPoint, zoom]);

  const isSelected = (index) => directionsStore.selectedRouteIndex === index;

  return (
    <ConditionalWrapper condition={directionsStore.routesCollection.length > 0}>
      <>
        {directionsStore.routesCollection.map((route, index) => (
            <Layer
              type='line'
              before={props.before}
              id={`directions-hover--${index}`}
              key={`directions-hover--${index}`}
              properties={{layerId: index}}
              layout={lineLayout}
              paint={routeSelectedHover}
              onMouseEnter={onMouseEnter}
              onMouseMove={onMouseMove}
            >
              <Feature
                properties={{layerId: index}}
                onMouseLeave={onMouseLeave}
                coordinates={route.geometry.coordinates}
              />
            </Layer>
          ),
        )}
        {directionsStore.routesCollection.map((route, index) => (
            <Layer
              type='line'
              before={isSelected(index) ? props.before : 'base'}
              id={`directions-${index}`}
              key={`directions-${index}`}
              layout={lineLayout}
              paint={isSelected(index) ? mapBoxTheme.routeSelected : mapBoxTheme.alternativeRoute}
            >
              <Feature coordinates={route.geometry.coordinates}/>
            </Layer>
          ),
        )}
      </>
    </ConditionalWrapper>
  );
};

export default observer(DirectionLayer);
