import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/styles';

import Person from '@material-ui/icons/Person';

import {CircleButton} from '../../shared/components/buttons/circle/CircleButton';
import AvatarProfile from '../../shared/components/avatar/Avatar';
import {doNothing} from '../../shared/utils/functionsUtils';

type Props = {
  avatar?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8,
  },
  inputFile: {
    width: 0,
    height: 0,
    visibility: 'hidden',
  },
  avatar: {
    position: 'relative',
  },
}));

const UserAvatarForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const avatarProfileClick = useCallback(doNothing, []);

  const loadNewAvatar = useCallback(doNothing, []);

  return (
    <div className={classes.root}>
      <input
        type="file"
        className={classes.inputFile}
        accept="image/*"
      />
      <div className={classes.avatar}>
        {props.avatar ? (
          <AvatarProfile avatarUrl={props.avatar} click={avatarProfileClick}/>
        ) : (
          <CircleButton onClick={loadNewAvatar}>
            <Person/>
          </CircleButton>
        )}
      </div>
    </div>
  );
};


export default UserAvatarForm;
