import React from 'react';
import {Typography} from '@material-ui/core';

type Props = {
  description?: string;
  rest?: any;
}

export const WeatherDescription:React.FC<Props> = ({description, ...rest}) => {
  return <Typography {...rest}>{description}</Typography>;
};

export default React.memo(WeatherDescription);
