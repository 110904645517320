import React from 'react';
import {observer} from 'mobx-react-lite';

export const weatherIconPath = (icon: string): string =>
  `${process.env.REACT_APP_STATIC_HOST_URL}/icons/v1/weather/${icon}.png`;

export const poiIconPath = (icon: string): string =>
  `${process.env.REACT_APP_STATIC_HOST_URL}/icons/v1/svg/POI/${icon}.png`;

interface Props {
  icon?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
}

const WeatherIcon: React.FC<Props> = (
  {
    icon,
    onClick,
    width,
    height,
  }) => {
  return icon
    ? <img
      src={weatherIconPath(icon)}
      alt=""
      onClick={onClick ? onClick : void (0)}
      width={width}
      height={height}
    />
    : null;
};

export default observer(WeatherIcon);
