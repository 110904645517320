import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import classnames from 'classnames';

import Zoom from '@material-ui/core/Zoom';

import {PointInterface} from 'interfaces/PointInterface';

import {Feature} from '@turf/turf';

import {PointType} from 'shared/const/wayPointType';
import {useRootStore} from 'shared/stores/RootStore';
import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';

import {PointContext} from '../MapProviders/MapPointProvider';
import {useAddPoint} from '../mapHooks/addPointHookt';
import {useToggle} from '../../../hooks/useToggle';
import {leaveContextMenu} from '../MapEvents';
import {mapEventType} from '../../../shared/const/mapEvents';
import Log from '../../../shared/utils/Log';
import {coordToLatLng, lngLatToCords} from '../../../shared/utils/turfUtils';
import {MapPointCoords} from '../index';
import {useEventListener} from '../../../hooks/useEventListener';

import {useStyles} from './styles';
import {AddressWrapped} from './ContextMenuPointAddress';
import ZoomButtons from './ZoomButtons';
import {ContextMenuContainer} from './ContextMenuContainer';
import ContextMenuActionsButtons from './ContextMenuActionsButtons';
import {CurrentWeatherIcon} from './CurrentWeatherIcon';
import {generatePoint} from './generatePoint';

export const radius = 65;
export const buttonWidth = 40;

const MapContextMenu: React.FC = () => {
    const classes = useStyles();
    const [point, setPoint] = useState<Feature<PointInterface> | undefined>(
      undefined,
    );
    const [buttonsDisabled, {off: enableButtons, on: disableButtons}] = useToggle(true);
    const {
      contextMenuCoords,
      pointType,
      clearPointType,
      setPointCoords,
      contextMenuOpen,
      openContextMenu,
      closeContextMenu,
    } = useContext(PointContext);

    const {addPointWithModal} = useAddPoint();
    const {wayPointsStore, routeTimeStore} = useRootStore();

    const onContextMenuShow = useCallback((e) => {
      setPointCoords(e.detail.point as MapPointCoords);
      openContextMenu(e.detail.pointType);
    }, [openContextMenu, setPointCoords]);

    const onWayPointClick = useCallback((e) => {
      const point = wayPointsStore.findPointByKey(e.detail.point);
      if (point?.geometry?.coordinates) {
        const lngLat = coordToLatLng(point.geometry.coordinates);
        setPointCoords({lngLat});
        setPoint(point);
        openContextMenu(e.detail.pointType);
      } else {
        Log.error(`Point with index ${e.detail.point} not found`);
      }
    }, [openContextMenu, setPointCoords, wayPointsStore]);

    const onRouteClick = useCallback(
      (e) => {
        const coords = {lngLat: coordToLatLng(e.detail.point)};
        setPointCoords(coords as MapPointCoords);
        openContextMenu(e.detail.pointType);
      },
      [openContextMenu, setPointCoords],
    );

    useEventListener(mapEventType.RouteClick, onRouteClick);
    useEventListener(mapEventType.ShowContextMenu, onContextMenuShow);
    useEventListener(mapEventType.WayPointClick, onWayPointClick);

    const coordinates = useMemo(() => {
      return (
        contextMenuCoords && (lngLatToCords(contextMenuCoords.lngLat) as number[])
      );
    }, [contextMenuCoords]);

    const shouldSearchForAddress = useMemo(() => {
      return pointType === PointType.newWayPoint
        || pointType === PointType.newWayPointOnRoute;
    }, [pointType]);

    useEffect(() => {
      if (!point && coordinates && contextMenuOpen && shouldSearchForAddress) {
        generatePoint(coordinates, routeTimeStore.routeStartDate)
          .then(newPoint => {
            setPoint(newPoint);
            enableButtons();
          });
      }
    }, [contextMenuOpen, coordinates, enableButtons, point, pointType, routeTimeStore.routeStartDate, shouldSearchForAddress]);

    const handleClose = useCallback(() => {
      disableButtons();
      setPoint(undefined);
      clearPointType();
      closeContextMenu();
      leaveContextMenu();
    }, [clearPointType, closeContextMenu, disableButtons]);

    const setNewPoint = useCallback(() => {
      addPointWithModal(point as Feature<PointInterface>);
      handleClose();
    }, [addPointWithModal, point, handleClose]);
    return (
      <ConditionalWrapper condition={contextMenuOpen}>
        <ContextMenuContainer coordinates={coordinates}>
          <>
            <Zoom in={contextMenuOpen} timeout={300}>
              <div
                className={classnames({
                  [classes.centerDot]: true,
                  [classes.existingPoint]: pointType === PointType.existingWayPoint,
                  [classes.pointOnRoute]: pointType === PointType.newWayPointOnRoute,
                })}
              >
                <AddressWrapped addressInfo={point?.properties?.addressInfo}/>
                <CurrentWeatherIcon point={point}/>
                <ContextMenuActionsButtons
                  pointType={pointType}
                  setNewPoint={setNewPoint}
                  buttonsDisabled={buttonsDisabled}
                  handleClose={handleClose}
                />
                <ZoomButtons coordinates={coordinates}/>
                {/*<PointTimeArrivingLabel*/}
                {/*  pointType={pointType as PointType}*/}
                {/*  coordinates={coordinates as number[]}*/}
                {/*/>*/}
              </div>
            </Zoom>
          </>
        </ContextMenuContainer>
      </ConditionalWrapper>
    );
  }
;


export default MapContextMenu;
