import React, {ReactElement, useCallback} from 'react';
import {Marker} from 'react-mapbox-gl';

import {useTimer} from '../../../hooks/useTimer';
import {leaveContextMenu} from '../MapEvents';

export const ContextMenuContainer: React.FC<{coordinates?: number[], children: ReactElement<any>}> = ({
                                                                                                        coordinates,
                                                                                                        children,
                                                                                                      }) => {
  const {onStartTimer: startTimer, stopTimer} = useTimer(leaveContextMenu, 800);
  const onMouseLeave = useCallback(() => {
    startTimer();
  }, [startTimer]);

  const onMouseEnter = useCallback(() => {
    stopTimer();
  }, [stopTimer]);

  return (
    <Marker
      coordinates={coordinates as number[]}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >{children}</Marker>
  );
};
