import React, {useContext, useMemo} from 'react';
import {withRouter} from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';

import AvatarProfile from '../avatar/Avatar';
import {PROFILE_LINK} from '../../const/NavigationLinks';
import {UserContext} from '../../../providers/UserWrapper';

type Props = {
  history: {
    push: (string) => void;
  }
}

const ProfileButton: React.FC<Props> = (props) => {
  const {profile: {directory, avatar}} = useContext(UserContext);

  return useMemo(() => {
    const avatarUrl = (directory && avatar)
      ? `${process.env.REACT_APP_IMAGE_API}/users/${directory}/avatar/${avatar}`
      : undefined;

    return (
      <IconButton color="primary" onClick={() => props.history.push(PROFILE_LINK)}>
        <AvatarProfile size={'small'} avatarUrl={avatarUrl}/>
      </IconButton>
    );
  }, [avatar, directory, props.history]);
};

export default withRouter(ProfileButton);
