
import React from 'react';

import Typography from '@material-ui/core/Typography';

const UserNick: React.FC = () => {
	return (
		<Typography noWrap>

		</Typography>
	);
};

export default UserNick;
