import React from 'react';
import classnames from 'classnames';

import Divider from '@material-ui/core/Divider';

import {ItemForecastInterface} from 'interfaces/weather/ForecastWeatherInterface';

import WeatherIcon from '../WeatherIcon/WeatherIcon';
import Temp from '../WeatherTemp/WeatherTemp';
import WeatherDescription from '../WeatherDescription/WeatherDescription';
import Precipitation from '../Precipitation/Precipitation';
import Clouds from '../Clouds/Clouds';

import TimeRangeLabel from './TimeRangeLabel';
import {useStyles} from './styles';

const ForecastRow: React.FC<ItemForecastInterface> = (props) => {
  const {dt_txt, main, weather: [{icon, description}], rain, snow, clouds, sys: {pod}, selected} = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Divider/>
      <div className={classnames({
        [classes.row]: true,
        [classes.selected]: selected,
      })}>
        <div style={{position: 'relative'}}>
          <TimeRangeLabel dt_txt={dt_txt} pod={pod}/>
          <div className={classes.weatherIconContainer}>
            <WeatherIcon icon={icon}/>
          </div>
        </div>
        <div className={classes.description}>
          <WeatherDescription description={description}/>
        </div>
        <div className={classes.cloudContainer}>
          {clouds && <Clouds clouds={clouds}/>}
        </div>
        <div className={classes.rainContainer}>
          {(rain || snow) && <Precipitation rain={rain} snow={snow}/>}
        </div>
        <div className={classes.tempContainer}>
          <div className={classes.temp}>
            <Temp temp={main.temp}/>
            <Divider/>
            <Temp feels_like={main.feels_like}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ForecastRow);
