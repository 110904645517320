import React from 'react';

import If from '../If/If';

type Props = {
  countryCode?: string;
  style?: string;
  size?: number;
}

const Flag: React.FC<Props> = ({countryCode, style = 'flat', size = 24}) => {
  const code = countryCode === 'en' ? 'gb' : countryCode;
  const src = `https://www.countryflags.io/${code}/${style}/${size}.png`;
  return (
    <If condition={!!countryCode}>
      <img
        src={src}
        alt={countryCode}
      />
    </If>
  );
};


export default React.memo(Flag);
