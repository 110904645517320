import {action, computed, observable, reaction} from 'mobx';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {
  MotorcycleModels,
  MotorcycleParams,
  MotorcyclesCatalogStoreInterface,
  MotorcycleYears,
  Producers,
} from 'interfaces/stores/MotorcyclesCatalogStoreInterface';

import {
  getProducersResource,
  getYearsResource,
  postMotorcyclesByMakeAndYearResource,
} from '../requests/motorcycles.resource';

import {motorcycleParamsInitialData} from './StoreInitialData/motorcycleParamInitialData';

class MotorcyclesCatalogStore<T, ParamType> implements MotorcyclesCatalogStoreInterface<T, ParamType> {
  private readonly rootStore: RootStoreInterface<any>;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.setInitialData();

    reaction(
      () => this.motorcycleParams.Make,
      make => {
        this.fetchYears(make);
        this.cleanupModelAndYear();
      },
    );

    reaction(
      () => this.motorcycleParams.Year,
      year => {
        this.cleanupModelAndYear();
        this.fetchModels(year);
      },
    );
  }

  @observable private _motorcycleParams: MotorcycleParams = motorcycleParamsInitialData;

  @computed
  get motorcycleParams() {
    return this._motorcycleParams;
  }

  @observable private _producers: Producers[] = [];

  @computed
  get producers() {
    return this._producers;
  }

  @observable private _years: MotorcycleYears[] = [];

  @computed
  get years() {
    return this._years;
  }

  @observable private _models: MotorcycleModels[] = [];

  @computed
  get models() {
    return this._models;
  }

  @action
  setInitialData() {
    this._motorcycleParams = motorcycleParamsInitialData;
  }

  cleanupModelAndYear() {
    this._motorcycleParams.Model = '';
    this._motorcycleParams.modelId = '';
    //this.rootStore.newMotorcycleStore._motorcycle?.Model = '';
    //this.rootStore.newMotorcycleStore._motorcycle?.modelId = '';
  }

  @action
  fetchProducers() {
    getProducersResource().then(producers => {
      this._producers = producers;
    });
  }

  @action
  fetchModels(Year) {
    postMotorcyclesByMakeAndYearResource({
      Make: this.motorcycleParams.Make,
      Year,
    }).then(models => {
      this._models = models;
    });
  }

  @action
  fetchYears(make) {
    getYearsResource(make)
      .then(years => {
        this._years = years;
      });
  }
}

export default MotorcyclesCatalogStore;
