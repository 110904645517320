import React, {useCallback} from 'react';
import {DialogContent} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';

import {InputField} from '../InputField';
import {useAuthFormValidation, useInputAuthChange} from '../loginHooks';
import {AuthData} from '../../../../providers/AuthWrapper';

import {registerValidators} from './registerValidators';

export type RegistrationDataType = AuthData & {
  passwordRepeat: string;
  terms?: boolean;
}

const initialRegistrationData = {
  email: '',
  password: '',
  passwordRepeat: '',
  terms: false,
};

const RegisterForm: React.FC = () => {
  const {data: registrationData, setInputValue, toggleValue} = useInputAuthChange(initialRegistrationData);
  const {email, password, passwordRepeat, terms} = registrationData as RegistrationDataType;

  const toggleTerms = useCallback(() => {
    toggleValue('terms', terms);
  }, [terms, toggleValue]);

  const {
    dataValidationErrors
  } = (useAuthFormValidation(registerValidators, registrationData, initialRegistrationData)) as {dataValidationErrors: RegistrationDataType, isValid: boolean};

  return (
    <>
      <InputField
        name="email"
        label="email"
        value={email}
        onChange={setInputValue}
        error={dataValidationErrors.email}
      />
      <InputField
        name="password"
        label="password"
        type="password"
        error={dataValidationErrors.password}
        value={password}
        onChange={setInputValue}
      />
      <InputField
        type='password'
        name='passwordRepeat'
        label='Repeat password'
        value={passwordRepeat}
        error={dataValidationErrors.passwordRepeat}
        onChange={setInputValue}
      />
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox name="termsAndConditions" value={terms} onChange={toggleTerms}/>
          }
          label="Accept terms & conditions"
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          disabled={!terms}
          fullWidth
          variant='contained'
          color='primary'
        >
          Sign up
        </Button>
      </DialogActions>
    </>
  );
};

export default RegisterForm;
