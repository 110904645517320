import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import Clear from '@material-ui/icons/Clear';

export const CloseDrawer = ({click, ...rest}) => (
	<IconButton onClick={click} {...rest}>
		<Clear />
	</IconButton>
);
