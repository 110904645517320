import React, {useCallback, useContext, useState} from 'react';

import Overlay from '../shared/components/overlay/Overlay';
import {doNothing} from '../shared/utils/functionsUtils';

import {AuthContext} from './AuthWrapper';

export interface OverlayProps {
  display: boolean;
  title?: string;
  showProgress?: false;
  show: () => void;
  hide: () => void;
}

export const initialDataOverlay: OverlayProps = {
  display: false,
  show: doNothing,
  hide: doNothing,
};

export const OverlayContext = React.createContext<OverlayProps>(initialDataOverlay);

export const OverlayWrapper = ({children}) => {
  const [display, setDisplay] = useState(initialDataOverlay.display);
  const {checkPending} = useContext(AuthContext);

  const show = useCallback(() => {
    setDisplay(!display);
  }, [display]);

  const hide = useCallback(() => {
    setDisplay(!display);
  }, [display]);


  return (
    <OverlayContext.Provider value={{display, show, hide}}>
      <>
        <Overlay display={display || checkPending}/>
        {children}
      </>
    </OverlayContext.Provider>);
};
