import {useCallback, useContext} from 'react';

import {PointInterface, WayPointInsertingType} from 'interfaces/PointInterface';

import {Feature} from '@turf/turf';

import {useRootStore} from 'shared/stores/RootStore';

import {PointContext} from '../MapProviders/MapPointProvider';

export const useAddPoint = () => {
  const {wayPointsStore} = useRootStore();
  const {setTempPoint, showPointModalChooser} = useContext(PointContext);

  const addPointOnBeginning = useCallback((point: Feature<PointInterface>) => {
    wayPointsStore.addWayPoint(point, WayPointInsertingType.AddOnBeginning);
  }, [wayPointsStore]);

  const addPointOnEnd = useCallback((point: Feature<PointInterface>) => {
    wayPointsStore.addWayPoint(point, WayPointInsertingType.AddOnEnd);
  }, [wayPointsStore]);

  const addPointByIndex = useCallback((point: Feature<PointInterface>, index) => {
    wayPointsStore.addWayPointAtIndex(point, index);
  }, [wayPointsStore]);

  const addPointWithModal = useCallback((point: Feature<PointInterface>) => {
    if (wayPointsStore.wayPointsCount < 2) {
      addPointOnEnd(point);
    } else {
      setTempPoint(point as Feature<PointInterface>);
      showPointModalChooser();
    }
  }, [addPointOnEnd, setTempPoint, showPointModalChooser, wayPointsStore.wayPointsCount]);

  return {addPointOnBeginning, addPointByIndex, addPointOnEnd, addPointWithModal};
};
