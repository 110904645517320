import {toJS} from 'mobx';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../interfaces/PointInterface';

export function getPointCoords(point: Feature<PointInterface>, clone = false): number[] {
  const {geometry} = point;
  return clone ? toJS(geometry?.coordinates as number[]) : geometry?.coordinates as number[];
}

export function setPointProperty(point: Feature<PointInterface>, property: string, value: unknown): void {
  if (!point?.properties) {
    point.properties = {};
  }

  point.properties[property] = value;
}

export function setPointDistance(point: Feature<PointInterface>, routeIndex: number, distance: number): void {
  if (point.properties) {
    point.properties.distance[routeIndex] = distance;
  }
}

export function setPointArrivingTime(point: Feature<PointInterface>, routeIndex: number, arrivingTime: number): void {
  if (point.properties) {
    point.properties.arrivingTime[routeIndex] = arrivingTime;
  }
}
