import { makeStyles } from '@material-ui/core';
import React from 'react';

import { Theme } from '@material-ui/core/styles';

import { useRootStore } from 'shared/stores/RootStore';


import SearchAddressInput from './SearchAddressInput';
import SearchResultList from './SearchResultList';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		position: 'relative',
	},
	resultListContainer: {
		position: 'absolute',
		maxHeight: '300px',
		overflowY: 'auto',
		zIndex: theme.zIndex.modal,
		background: theme.palette.background.paper,
		borderColor: theme.palette.grey.A200,
		borderStyle: 'solid',
		borderWidth: '1px ',
	},
}));

export const AddressLookup: React.FC = () => {
	const classes = useStyles();
	const { addressStore } = useRootStore();

	return (
		<div className={classes.container}>
			<div>
				<SearchAddressInput />
			</div>
			<div>{addressStore.addresses.length > 0 && <SearchResultList />}</div>
		</div>
	);
};

export default AddressLookup;
