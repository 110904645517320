import React, {useEffect, useMemo} from 'react';
import {useObserver} from 'mobx-react';
import {useToggle} from 'hooks/useToggle';

import Grid from '@material-ui/core/Grid';

import {useRootStore} from 'shared/stores/RootStore';
import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';

import {usePathToImagesMaps} from '../maps/mapHooks/MapHooks';

import CreateRouteCard from './CreateRouteCard';
import MapCard from './MapCard/MapCard';
import {CardPlaceholder} from './MapCard/CardPlaceholder';

const Container: React.FC = ({children}) => {
  return (
    <Grid
      container
      spacing={8}
      direction="row"
    >{children}</Grid>
  );
};

const Cell: React.FC = ({children}) => {
  return (
    <Grid
      style={{'display': 'flex'}}
      item
      xs={12}
      md={4}
    >{children}</Grid>
  );
};

const Skeleton: React.FC = () => {
  const emptyCells = useMemo(() => {
    return new Array(3).fill(<CardPlaceholder/>);
  }, []);

  return (
    <Container>
      {emptyCells.map((cell, index) => <Cell key={'cell' + index}><CardPlaceholder/></Cell>)}
    </Container>
  );
};

const UserMaps: React.FC = () => {
  const [isLoadingData, {on: isLoading, off: loaded}] = useToggle(false);
  const {userMapsStore} = useRootStore();
  const pathToImages = usePathToImagesMaps();

  useEffect(() => {
    isLoading();
    userMapsStore.loadUserMaps().then(() => {
      loaded();
    });
  }, [isLoading, loaded, userMapsStore]);

  return useObserver(() => (
    <ConditionalWrapper
      condition={!isLoadingData}
      skeleton={<Skeleton/>}
    >
      <Container>
        {userMapsStore.usersMaps.map((item, index) => (
          <Cell key={`cell--${index}`}>
            <MapCard
              item={item}
              path={pathToImages}
            />
          </Cell>
        ))}
        <CreateRouteCard/>
      </Container>
    </ConditionalWrapper>
  ));
};

export default UserMaps;
