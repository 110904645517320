import React, {useCallback} from 'react';

import PlusIcon from '@material-ui/icons/ZoomIn';
import MinusIcon from '@material-ui/icons/ZoomOut';

import MapBoxHandler from '../MapBoxHandler';

import {ContextMenuButton} from './ContextMenuButton';

const ZoomButtons: React.FC<{coordinates?: number[]}> = ({coordinates}) => {
  const zoomIn = useCallback(() => {
    MapBoxHandler.flyTo(coordinates, MapBoxHandler.getZoom() + 1);
  }, [coordinates]);

  const zoomOut = useCallback(() => {
    MapBoxHandler.flyTo(coordinates, MapBoxHandler.getZoom() - 1);
  }, [coordinates]);

  return (
    <>
      <ContextMenuButton
        click={zoomIn}
        deg={250}
        icon={<PlusIcon/>}
      />
      <ContextMenuButton
        click={zoomOut}
        deg={290}
        icon={<MinusIcon/>}
      />
    </>
  );
};

ZoomButtons.displayName ='ZoomButtons';
export default ZoomButtons;
