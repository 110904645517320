import React, {useContext} from 'react';
import {Card, CardContent, Typography} from '@material-ui/core';

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';

import {AddingPointType, SearchAddressContext} from '../Address/SearchAddressProvider';
import {flexContainerCenter, flexContainerCenterRow} from '../../const/stylesDef';


export const AddNewFavoritePlace: React.FC = () => {
  const classes = useStyles();
  const {show, setOptions} = useContext(SearchAddressContext);

  const showAddressModal = () => {
    setOptions({
      label: 'Add to favorites',
      addingType: AddingPointType.AddToFavorites,
    });
    show();
  };

  return (
    <Card className={classes.root} onClick={showAddressModal}>
      <CardContent className={classes.container}>
        <Typography variant='caption'>Add point</Typography>
        <AddIcon color='primary'/>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height:'100%',
    backgroundColor: theme.palette.grey.A100,
    ...flexContainerCenter,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey.A200,
    },
  },
  container: {
    ...flexContainerCenterRow,
    'flex-direction': 'column',
  },
}));
