import React, {useCallback, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core';
import {withRouter} from 'react-router';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Theme} from '@material-ui/core/es';

import ConfirmDialog from 'shared/components/ConfirmDialog/ConfirmDialog';

import {DASHBOARD} from '../../const/NavigationLinks';
import {dispatchLogoutEvent} from '../../../hooks/useLogoutListener';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutLink: {
      '& > *': {
        color: theme.palette.secondary.main,
      },
      '& > * > span': {
        color: theme.palette.secondary.dark,
      },
    },
  }));

type Props = {
  history: {
    push: (x: string) => void;
  };
  label: string;
  icon: React.ComponentElement<any, any>;
}

const LogoutItem: React.FC<Props> = ({history, label, icon}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleLogout = useCallback(() => {
    dispatchLogoutEvent();
    hide();
    history.push(DASHBOARD);
  }, [history]);

  const handleConfirm = () => {
    setOpen(true);
  };

  const hide = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleConfirm} className={classes.logoutLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText inset primary={label}/>
      </ListItem>
      <ConfirmDialog
        open={open}
        onConfirm={handleLogout}
        onClose={hide}
      >
        <span>Logout from app ?</span>
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default withRouter(LogoutItem);
