import {useCallback, useMemo} from 'react';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../interfaces/PointInterface';
import {ItemForecastInterface} from '../../../interfaces/weather/ForecastWeatherInterface';

export const useForecastTempPoint = (forecast?: ItemForecastInterface) => {
  const temp = useMemo(() => {
    if (forecast) {
      return forecast.main.temp;
    }
  }, [forecast]);

  const getTemp = useCallback((point: Feature<PointInterface>) => `${Math.round(point?.properties?.forecast[0].main.temp)}`, []);

  return {temp, getTemp};
};
