import React, {useMemo} from 'react';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import {observer} from 'mobx-react-lite';

import Typography from '@material-ui/core/Typography';

import {TempUnit} from '../../unitLabels/unitLabels';

type Props = {
  temp?: number;
  feels_like?: number;
  onClick?: () => void;
  variant?: any;
  component?: any;
  feels?: boolean;
  showLabel?: boolean;
  ignoreColors?: boolean;
};

const colorRanges = {
  '-10': blue.A100,
  '0': blue.A200,
  '5': blue.A700,
  '10': blue.A400,
  '15': green.A400,
  '20': green.A700,
  '25': orange.A700,
  '30': red.A400,
  '35': red.A700,
};


export const useTempColors = (temp: number): string => {
  return useMemo(() => {
    const keys = Object.keys(colorRanges)
      .map(key => parseInt(key, 0))
      .sort((a: number, b: number) => a - b);
    const getKeyValue = (keyIndex: number) => keys[keyIndex];
    let color = colorRanges['0'];
    for (let i = 1; i < keys.length; i++) {
      if (temp >= getKeyValue(i - 1) && temp < getKeyValue(i)) {
        color = colorRanges[getKeyValue(i).toString()];
      }
    }
    if (temp >= keys[keys.length - 1]) {
      color = colorRanges[getKeyValue(keys.length - 1)];
    }
    return color;
  }, [temp]);
};


const Temp: React.FC<Props> = ({
                                 temp,
                                 feels_like,
                                 onClick,
                                 showLabel = true,
                                 variant,
                                 ignoreColors
                               }) => {
  const temperature = useMemo(() => {
    if (feels_like) {
      return Math.round(feels_like);
    }
    return Math.round(temp || 0);
  }, [temp, feels_like]);

  const color = useTempColors(temperature);
  const componentData = useMemo(
    () =>
      feels_like
        ? {label: 'feels', variant: 'subtitle1'}
        : {
          label: 'temp',
          variant: 'subtitle1',
        },
    [feels_like],
  );

  return (
    <Typography
      variant={variant ? variant : componentData.variant}
      onClick={onClick}
    >
      {showLabel && `${componentData.label} : `}
      <span style={{'color': ignoreColors ? 'inherit' : color}}>{temperature}</span>
      <TempUnit/>
    </Typography>
  );
};

export default observer(Temp);
