import React, {useCallback, useState} from 'react';
import {Feature, Layer} from 'react-mapbox-gl';

import {MapEventObject, mapEventType} from 'shared/const/mapEvents';

import {useEventListener} from '../../../hooks/useEventListener';

import {useMapBoxTheme} from './LayerPointStyle';

interface Props {
  id: string;
  before: string;
}

export const SelectionLayer: React.FC<Props> = ({id, before}) => {
  const [coordinates, setPointCoords] = useState<number[]>();
  const mapBoxTheme = useMapBoxTheme();

  const setSelectedPoint = useCallback(({detail}) => {
    if (detail.mapObject === MapEventObject.FuelPoint) {
      setPointCoords([detail.point.lon, detail.point.lat]);
    }
    if (detail.mapObject === MapEventObject.WayPoint) {
      setPointCoords(detail.point.geometry.coordinates);
    }
  }, []);

  const unsetPoint = useCallback(() => {
    setPointCoords(undefined);
  }, []);

  useEventListener(mapEventType.EnterMapPoint, setSelectedPoint);
  useEventListener(mapEventType.EnterFuelPoint, setSelectedPoint);
  useEventListener(mapEventType.LeaveMapPoint, unsetPoint);

  return (
    <Layer
      id={id}
      key={id}
      before={before}
      type="circle"
      paint={mapBoxTheme.selectionPoint}
    >{(coordinates) && <Feature coordinates={coordinates}/>}
    </Layer>
  );
};
