import moment from 'moment';
import React from 'react';

import Typography from '@material-ui/core/Typography';

import DateDisplay,{DateFormat} from '../../DateDisplay/DateDisplay';

import {useStyles} from './styles';

interface Props {
  dt_txt: string;
  pod: string;
}

const TimeRangeLabel: React.FC<Props> = React.memo(({dt_txt, pod}) => {
  const classes = useStyles();
  return (
    <div className={pod === 'n' ? classes.date_night : classes.date_day}>
      <Typography className={classes.hours}>
        <DateDisplay date={moment(dt_txt)} format={DateFormat.Hour}/> - <DateDisplay
        date={moment(dt_txt).add(3, 'hours')} format={DateFormat.Hour}/>
      </Typography>
    </div>
  );
});

TimeRangeLabel.displayName= 'TimeRangeLabel';
export default TimeRangeLabel;
