import {action, makeObservable, observable} from 'mobx';
import {from, Subscription} from 'rxjs';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {AddressInfoInterface} from 'interfaces/address/AddressInfo';

import {lookupAddress} from '../requests/geocoder.resource';

class AddressStore {
  private rootStore: RootStoreInterface<any>;
  addresses: AddressInfoInterface[] = [];
  loadingAddresses = false;

  lookupAddress$: Subscription | undefined;

  constructor(rootStore: RootStoreInterface<any>) {
    makeObservable(this, {
      addresses: observable,
      loadingAddresses: observable,
      clearAddresses: action,
      setAddressList: action,
      setLoading: action,
    });
    this.rootStore = rootStore;
  }

  setLoading(loadingState: boolean): void {
    this.loadingAddresses = loadingState;
  }

  setAddressList(list: AddressInfoInterface[]): void {
    this.addresses = list;
  }

  lookup$ = addressQuery => {
    const promiseSource = from(lookupAddress(addressQuery));
    this.setLoading(true);
    this.lookupAddress$ = promiseSource.subscribe({
      next: response => this.setAddressList(response),
      complete: () => {
        this.setLoading(false);
        this.lookupAddress$?.unsubscribe();
      },
      error: () => {
        this.setLoading(false);
      },
    });
  };

  clearAddresses(): void {
    this.setAddressList([]);
  }
}

export default AddressStore;
