import {action, computed, observable, runInAction} from 'mobx';
import {has} from 'lodash';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';

import {geUsersClusterData} from '../requests/users.request';

class UsersStore {
  private readonly rootStore: RootStoreInterface<any>;

  @observable _usersClusterData;
  @observable _usersClusterLayerVisible;

  constructor(rootStore: RootStoreInterface<any>) {
    this.rootStore = rootStore;
    this._usersClusterLayerVisible = false;
    this._usersClusterData = [];
  }

  @action
  toggleUsersClusterLayerVisible() {
    this._usersClusterLayerVisible = !this._usersClusterLayerVisible;
  }

  @computed
  get usersClusterLayerVisible() {
    return this._usersClusterLayerVisible;
  }

  @action
  loadUsersLocations() {
    geUsersClusterData()
      .then(data =>
        data
          .filter(point => has(point, '_id.countyPoint[0].geometry.coordinates'))
          .map(item => {
            const temp = {...item};
            return {
              count: temp.count,
              county: temp._id.county,
              coords: temp._id.countyPoint[0].geometry.coordinates.reverse(),
            };
          }),
      )
      .then(cleanData => {
        runInAction(() => {
          this._usersClusterData = cleanData;
        });
      });
  }

  @computed
  get usersClusterData() {
    return this._usersClusterData;
  }
}

export default UsersStore;
