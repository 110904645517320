import {useCallback, useEffect} from 'react';

import Log from '../shared/utils/Log';

export const useEventListener = (eventName: string, onMountCallback: (evt: any) => void, onExit?: () => void): void => {

  const addListener = useCallback((event) => {
    Log.info(`listen on event: ${event}`);
    document.addEventListener(event, onMountCallback);
  }, [onMountCallback]);

  const removeListener = useCallback((event) => {
    Log.info(`stop listening event: ${event}`);
    document.removeEventListener(event, onMountCallback);
    onExit && onExit();
  }, [onExit, onMountCallback]);

  useEffect(() => {
    addListener(eventName);
    return () => {
      removeListener(eventName);
    };
  }, [addListener, eventName, removeListener]);
};
