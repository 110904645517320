import React, {useCallback, useContext, useState} from 'react';
import {Button, DialogContent, Typography} from '@material-ui/core';

import DialogActions from '@material-ui/core/DialogActions';

import {useStyles} from '../LoginSignupModal';
import {InputField} from '../InputField';
import {useAuthFormValidation, useInputAuthChange} from '../loginHooks';
import {AuthContext, AuthData} from '../../../../providers/AuthWrapper';
import If from '../../If/If';

import {loginFormValidator} from './loginValidators';

const initialLoginData: AuthData = {
  email: '',
  password: '',
};


export const LoginForm: React.FC = () => {
  const classes = useStyles();
  const {data: loginData, setInputValue} = useInputAuthChange(initialLoginData);
  const {
    dataValidationErrors,
    isValid,
  } = useAuthFormValidation(loginFormValidator, loginData, initialLoginData) as {dataValidationErrors: AuthData, isValid: boolean};
  const [errorLogin, setErrorLogin] = useState<string>('');
  const {setAuthenticated, checkPending} = useContext(AuthContext);

  const onClick = useCallback(async () => {
    try {
      await setAuthenticated(loginData);
    } catch (e) {
      setErrorLogin(e);
    }
  }, [loginData, setAuthenticated]);

  const onFocus = useCallback(() => {
    setErrorLogin('');
  }, []);

  return (
    <>
      <div className={classes.formContainer}>
        <InputField
          name="email"
          label="email"
          value={loginData.email}
          onChange={setInputValue}
          error={dataValidationErrors.email}
          onFocus={onFocus}
        />
        <InputField
          name="password"
          label="password"
          type="password"
          value={loginData.password}
          onChange={setInputValue}
          error={dataValidationErrors.password}
          onFocus={onFocus}
        />
      </div>
      <DialogContent>
        <If condition={errorLogin.length > 0}>
          <Typography
            variant='body2'
            color='error'
            className={classes.errorLabel}
          >{errorLogin}</Typography>
        </If>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={!isValid || checkPending || errorLogin.length > 0}
          fullWidth
          size='large'
        >Login</Button>
      </DialogActions>
    </>
  );
};

export default LoginForm;
