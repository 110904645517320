import React, {useContext} from 'react';

import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../../../interfaces/PointInterface';
import {PointContext} from '../../../MapProviders/MapPointProvider';

interface Props {
  wayPoint: Feature<PointInterface>;
}

const DeleteWayPointButton: React.FC<Props> = ({wayPoint}) => {
  const {showConfirmDialog} = useContext(PointContext);

  const deletePointQuery = e => {
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    showConfirmDialog(wayPoint);
  };

  return (
    <IconButton onClick={deletePointQuery}>
      <DeleteIcon/>
    </IconButton>
  );
};

export default DeleteWayPointButton;
