import React, {useContext, useEffect, useState} from 'react';

import {Position} from '@turf/turf';

import {CurrentWeatherInterface} from '../interfaces/weather/CurrentWeatherInterface';
import Log from '../shared/utils/Log';
import {getCurrentWeather} from '../shared/requests/weather.request';

import {UserHomeLocationContext} from './UserLocationWrapper';

export interface CurrentWeatherDataProps {
  currentWeather?: CurrentWeatherInterface
}

export const initialCurrentWeatherData: CurrentWeatherDataProps = {currentWeather: undefined};

export const CurrentWeatherContext = React.createContext<CurrentWeatherDataProps>(initialCurrentWeatherData);

export const CurrentWeatherWrapper = ({children}) => {
  const [currentWeather, setCurrentWeather] = useState<CurrentWeatherInterface>(initialCurrentWeatherData.currentWeather as CurrentWeatherInterface);
  const {userHomeLocationData} = useContext(UserHomeLocationContext);

  const getHomePointCurrentWeather = async (coordinates: Position) => {
    try {
      const weather = await getCurrentWeather(coordinates as number[]);
      setCurrentWeather(weather);
    } catch (e) {
      Log.error(e);
    }
  };

  useEffect(() => {
    if (userHomeLocationData) {
      if (userHomeLocationData?.homePoint?.geometry?.coordinates) {
        getHomePointCurrentWeather(userHomeLocationData.homePoint.geometry.coordinates);
      }
    }
  }, [userHomeLocationData]);

  return (
    <CurrentWeatherContext.Provider value={{currentWeather}}>{children}</CurrentWeatherContext.Provider>
  );
};
