import {Grid} from '@material-ui/core';
import React, {useContext} from 'react';

import {Feature} from '@turf/turf';

import AvatarWayPoint from '../AvatarWayPoint';
import AddressDisplay from '../../../../../shared/components/Address/AddressDisplay';
import ConfirmDialog from '../../../../../shared/components/ConfirmDialog/ConfirmDialog';
import {PointInterface} from '../../../../../interfaces/PointInterface';
import {PointContext} from '../../../MapProviders/MapPointProvider';

type Props = {
  wayPoint?: Feature<PointInterface>;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeletePointConfirmDialog: React.FC<Props> = ({wayPoint, onClose, onConfirm}) => {
  const {openConfirmDeleteDialog} = useContext(PointContext);
  return (
    <ConfirmDialog
      title="Delete point ?"
      open={openConfirmDeleteDialog}
      okLabel="Delete"
      cancelLabel="cancel"
      onConfirm={onConfirm}
      onClose={onClose}
      contentHTML={true}
    >
      <Grid container
            direction="row"
            justify="center"
            alignItems="center">
        <Grid item xs={4}>
          <AvatarWayPoint
            pointType={wayPoint?.properties?.pointType}
            index={wayPoint?.properties?.index}
          />
        </Grid>
        <Grid item xs={8}>
          <AddressDisplay
            addressInfo={wayPoint?.properties?.addressInfo}
            count={5}
            variant={'h6'}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};
