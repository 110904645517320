import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Theme, withStyles} from '@material-ui/core/styles';

import {StyleType} from 'interfaces/Styles';
import {RouteInterface} from 'interfaces/RoutesInterface';

import Distance from 'shared/components/Distance/Distance';
import Duration from 'shared/components/Duration/Duration';
import If from 'shared/components/If/If';

interface Props {
  params: RouteInterface[];
  classes?: any;
}

const RouteParams: React.FC<Props> = ({params, classes}) => {
  if (!params) {
    return null;
  }

  const {distance, duration} = params[0]?.properties;
  return (
    <If condition={!!distance && !!duration}>
      <Grid
        container
        direction="row"
        alignItems={'center'}
        justify={'center'}
        alignContent={'center'}
        className={classes.container}
      >
        <Grid item xs={6} className={classes.firstBlock}>
          <Typography variant='body2'>Distance</Typography>
          <Distance distance={distance}/>
        </Grid>
        <Grid item xs={6} className={classes.lastBlock}>
          <Typography variant='body2'>Duration</Typography>
          <Duration
            duration={duration}
            roundTime={true}
          />
        </Grid>
      </Grid>
    </If>
  );
};

const styles: StyleType = (theme: Theme) => ({
  container: {
    marginBottom: '8px',
    marginTop: '8px',
    flex:1
  },
  firstBlock: {
    '&>span': {
      textAlign: 'center',
    },
    '&>p': {
      textAlign: 'center',
    },
    '&>p:last-child': {
      textAlign: 'center',
      color: theme.palette.info.main,
    },
  },
  lastBlock: {
    borderLeft: `1px solid ${theme.palette.grey.A200}`,
    '&>span': {
      textAlign: 'center',
    },
    '&>p': {
      textAlign: 'center',
      color: theme.palette.info.light,
    },
  },
});

export default withStyles(styles)(RouteParams);
