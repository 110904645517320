class buttonCoords {
  private readonly buttonWidth: number = 0;
  private readonly radius: number = 0;

  constructor(props: {radius: number; buttonWidth: number}) {
    this.radius = props.radius;
    this.buttonWidth = props.buttonWidth;
  }

  private getButtonRadius = () => {
    return Math.round(this.buttonWidth / 2);
  }

  degToRad = (deg: number): number => deg * (Math.PI / 180);

  getPointXCords = (deg: number): number =>
    this.radius + this.radius * Math.cos(this.degToRad(deg)) - this.getButtonRadius();

  getPointYCords = (deg: number): number =>
    this.radius + this.radius * Math.sin(this.degToRad(deg)) - this.getButtonRadius();

  pointCords = (deg: number): {top: number, left: number} => ({
    left: this.getPointXCords(deg),
    top: this.getPointYCords(deg),
  });
}

export default buttonCoords;
