import React from 'react';

import ProfileButton from '../ProfileButton/ProfileButton';
import {AuthDisplay} from '../AuthDisplay/AuthDisplay';

import ButtonLogin from './ButtonLogin';

const AuthContainer: React.FC = () => {
  return (
    <AuthDisplay
      skeleton={<ButtonLogin click={() => void(0)}/>}>
      <ProfileButton/>
    </AuthDisplay>
  );
};

export default AuthContainer;
