import {useContext, useMemo} from 'react';
import {UserContext} from 'providers/UserWrapper';

import {PATH_TO_USERS_DIRECTORY, USER_MAP_DIRECTORY} from 'shared/const/settings';


export const usePathToImagesMaps = (): string => {
  const {profile} = useContext(UserContext);
  return useMemo(() => [
    process.env.REACT_APP_IMAGE_API,
    PATH_TO_USERS_DIRECTORY,
    profile.directory,
    USER_MAP_DIRECTORY,
  ].join('/'), [profile.directory]);
};
