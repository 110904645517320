import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';

import IconButton from '@material-ui/core/IconButton';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import CalendarIcon from '@material-ui/icons/CalendarToday';

import {useRootStore} from 'shared/stores/RootStore';
import DateDisplay, {DateFormat} from 'shared/components/DateDisplay/DateDisplay';

import {dateModalEvent} from '../../MapEvents';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateLabel: {
    color: theme.palette.common.white,
  },
}));

const SelectDateButton: React.FC = () => {
  const classes = useStyles();
  const {routeTimeStore} = useRootStore();

  const onClick = useCallback(() => {
    dateModalEvent(true);
  }, []);

  return (
    <div
      className={classes.root}
      onClick={onClick}
    >
      <IconButton color="inherit">
        <CalendarIcon/>
      </IconButton>
      <DateDisplay date={routeTimeStore._routeStartDate} format={DateFormat.shortDate}/>
    </div>
  );
};

export default observer(SelectDateButton);
