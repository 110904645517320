import Axios from 'axios';

import mapResponse from '../functions/mapResponse';
import {apiHost, http} from '../functions/HttpAuth';
import {AuthData} from '../../providers/AuthWrapper';

export const checkIsLoggedRequest = (): Promise<string | never> => http.get('/api/logged');

export interface AuthResponse {
  token: string;
}

export const localLogin = (authData: AuthData): Promise<AuthResponse | never> => http.post('/api/login', authData);

export const facebookLoginActionResource = authData => {
  const url = apiHost + '/api/login/facebook';
  return Axios.post(url, authData).then(mapResponse);
};

export const googleSignUpAction = (authData: any) => {
  const url = apiHost + '/api/signup/google';
  return Axios.post(url, authData).then(mapResponse);
}

export const logoutActionResource = () => {
  const url = apiHost + '/api/LogoutButton';
  return Axios.get(url).then(mapResponse);
};

export const signup = data => {
  const url = apiHost + '/api/signup';
  return Axios.post(url, data).then(mapResponse);
};
