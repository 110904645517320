import {action, computed, makeObservable, observable} from 'mobx';
import moment, {Moment} from 'moment';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../interfaces/PointInterface';
import Log from '../utils/Log';
import {calculateForecastIndex, selectForecast} from '../utils/forecastUtils';
import {DateFormat} from '../components/DateDisplay/DateDisplay';
import {arrivingDateTime} from '../utils/timeUtils';

class RouteTimeStore {
  private readonly rootStore: RootStoreInterface<any>;
  _routeStartDate: Date | undefined;
  _isForecastAvailable = false;

  constructor(rootStore: RootStoreInterface<any>) {
    this.rootStore = rootStore;
    makeObservable(this, {
      _routeStartDate: observable,
      _isForecastAvailable: observable,
      setCurrentDateTime: action,
      checkIsForecastIsAvailable: action,
      setStartDate: action,
      isForecastAvailable: computed,
      calendarMaxDate: computed,
      calendarMinDate: computed,
      routeStartDateAsString: computed,
    });
    this.setCurrentDateTime();
  }

  setCurrentDateTime(): void {
    const defaultDateTime = moment()
      .endOf('hour')
      .add(1, 'm');
    this.setStartDate(defaultDateTime);
    this._isForecastAvailable = this.checkIsForecastIsAvailable(defaultDateTime);
  }

  private selectForecastIndex(wayPoint: Feature<PointInterface>) {
    if (!wayPoint?.properties?.forecast) {
      Log.warn('no forecast for point');
      return;
    }

    if (wayPoint?.properties) {
      const arriveAt = arrivingDateTime(this._routeStartDate as Date, wayPoint.properties.arrivingTime[0]);
      const forecastIndex = calculateForecastIndex(arriveAt, wayPoint.properties.forecast);
      wayPoint.properties.forecastIndex = forecastIndex;
      selectForecast(forecastIndex as number, wayPoint?.properties?.forecast);
    }
  }

  private setWayPointForecastIndex() {
    if (this.rootStore.wayPointsStore.wayPoints.length === 0) {
      Log.info('no wayPoints');
      return;
    }

    const forEachPoint = (wayPoint: Feature<PointInterface>) => {
      this.selectForecastIndex(wayPoint);
    };

    this.rootStore.wayPointsStore._wayPoints.forEach(forEachPoint);
  }

  checkIsForecastIsAvailable(startDate: Date | Moment): boolean {
    return !moment(startDate).isAfter(
      moment()
        .add(6, 'd')
        .endOf('day'),
    );
  }

  setStartDate(date: Date | Moment | string): void {
    this._routeStartDate = moment(date).toDate();
    this._isForecastAvailable = this.checkIsForecastIsAvailable(this._routeStartDate);
    this.setWayPointForecastIndex();
    Log.info(`Route date start changed to ${moment(this._routeStartDate).format(DateFormat.FullDate)}`);
  }

  get startDateDayMonthYear(): string {
    return moment(this._routeStartDate).format(DateFormat.DefaultDate);
  }

  get routeStartDate(): Date {
    return this._routeStartDate as Date;
  }

  get routeStartDateAsString(): string {
    return moment(this.routeStartDate).format(DateFormat.FullDate);
  }

  get isForecastAvailable(): boolean {
    return this._isForecastAvailable;
  }

  get calendarMaxDate(): Date {
    return moment()
      .add(1, 'y')
      .toDate();
  }

  get calendarMinDate(): Date {
    return moment()
      .endOf('day')
      .toDate();
  }
}

export default RouteTimeStore;
