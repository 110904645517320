import {ItemForecastInterface} from 'interfaces/weather/ForecastWeatherInterface';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../interfaces/PointInterface';

import {useForecastIcon} from './useForecastIcon';
import {useForecastTempPoint} from './useForecastTempPoint';

type UseForecast = {
  selectedForecast?: ItemForecastInterface;
  weatherIcon?: string;
  temp?: number;
}

export function useForecast(point: Feature<PointInterface>): UseForecast {
  const selectedForecast = () => {
    const index = point?.properties?.forecastIndex ?? 0;
    return point?.properties?.forecast ? point?.properties?.forecast[index] : undefined;
  };


  const icon = useForecastIcon(selectedForecast());
  const {temp} = useForecastTempPoint(selectedForecast());
  return {weatherIcon: icon, temp};
}
