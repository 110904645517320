import React from 'react';

import {useRootStore} from 'shared/stores/RootStore';
import If from 'shared/components/If/If';

import StartFromHomeItem from './StartFromHomeItem';
import SearchAddressItem from './SearchAddressItem';

export const StartRouteBlock = () => {
  const {wayPointsStore} = useRootStore();
  const isNoWayPoints = (() => wayPointsStore.wayPointsCount === 0)();
  return (
    <>
      <If condition={wayPointsStore.wayPointsCount === 0}>
        <StartFromHomeItem/>
      </If>
      <If condition={wayPointsStore.wayPointsCount < 2}>
        <SearchAddressItem
          labelPrimary={isNoWayPoints ? 'Search address' : 'Add next point'}
          labelSecondary={isNoWayPoints ? 'Or choose from the history' : 'Or click on the map'}
        />
      </If>
    </>
  );
};

export default StartRouteBlock;
