import React, {useCallback, useContext} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SearchIcon from '@material-ui/icons/Search';

import {Spacing} from 'shared/const/spacing';

import {AddingPointType, SearchAddressContext} from '../../../../shared/components/Address/SearchAddressProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.info.light,
      marginRight: Spacing + 'px',
    },
  }));

interface Props {
  labelPrimary?: string;
  labelSecondary?: string;
  onClick?: () => void;
}

export const SearchAddressItem: React.FC<Props> = ({onClick, labelPrimary, labelSecondary}) => {
  const {show, setOptions} = useContext(SearchAddressContext);
  const classes = useStyles();

  const onClickPress = useCallback(
    () => {
      setOptions({
        addingType: AddingPointType.AddPointToMap,
      });
      show();
      onClick && onClick();
    },
    [onClick, setOptions, show],
  );

  return (
    <ListItem
      button
      onClick={onClickPress}
    >
      <Avatar className={classes.avatar}>
        <SearchIcon/>
      </Avatar>
      <ListItemText
        primary={labelPrimary ?? 'Search address'}
        secondary={labelSecondary ?? 'for next point'}
      />
    </ListItem>
  );
};

export default SearchAddressItem;
