import React from 'react';
import ReactDOM from 'react-dom';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/pl';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
