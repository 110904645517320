import React from 'react';
import {observer} from 'mobx-react-lite';

import {useRootStore} from 'shared/stores/RootStore';

import {WayPointLayer} from './WayPointLayer';
import {WayPointNumberLayer} from './WayPointNumberLayer';

interface Props {
  id: string;
  before: string;
}

export const DirectionWayPointsLayer: React.FC<Props> = observer(({id, before}) => {
  const {wayPointsStore} = useRootStore();
  return (
    <React.Fragment>
      <WayPointLayer
        wayPoints={wayPointsStore.mergedPoints}
        id={id}
        before={before}
      />
      <WayPointNumberLayer
        wayPoints={wayPointsStore.mergedPoints}
        id={'waypoint-number'}
        before={before}
      />
    </React.Fragment>
  );
});
