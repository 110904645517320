import React, {useCallback} from 'react';
import {AppBar, createStyles, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import {QueryBuilder} from '@material-ui/icons';
import {observer} from 'mobx-react-lite';

import Grid from '@material-ui/core/Grid';

import {useRootStore} from 'shared/stores/RootStore';
import Distance from 'shared/components/Distance/Distance';
import Duration from 'shared/components/Duration/Duration';
import If from 'shared/components/If/If';
import {randomString} from 'shared/functions/randomString';
import {flexContainerCenterRow} from 'shared/const/stylesDef';

const useStyles = makeStyles(() =>
  createStyles({
    routeInfoContainer: {
      marginTop: '10px',
    },
    routeInfoCell: {
      ...flexContainerCenterRow,
    },
  }));

export const RoutesInformation: React.FC = observer(() => {
  const classes = useStyles();
  const {directionsStore} = useRootStore();
  const tabbClick = useCallback((routeIndex) => {
    directionsStore.selectRoute(routeIndex);
  }, [directionsStore]);

  return (
    <>
      <If condition={directionsStore.routesCount > 1}>
        <AppBar position="static" color={'primary'}>
          <Tabs value={directionsStore.selectedRouteIndex}>
            {directionsStore.routesCollection.map((route, index) => {
              const selected = directionsStore.selectedRouteIndex === index;
              return (
                <Tab
                  //className={selected ? classes.selectedTab : classes.selectedTab}
                  key={`tab-${index}`}
                  onClick={() => tabbClick(index)}
                  selected={selected}
                  label={
                    <>
                      <Distance distance={route.properties.distance}/>
                      <Duration duration={route.properties.duration}/>
                    </>
                  }
                />
              );
            })}
          </Tabs>
        </AppBar>
      </If>
      <If condition={directionsStore.routesCount === 1}>
        {directionsStore.routesCollection.map((route) => (
          <Grid
            container
            key={randomString()}
            alignContent={'center'}
            justify={'space-between'}
            alignItems={'center'}
            className={classes.routeInfoContainer}
          >
            <Grid item xs={3} className={classes.routeInfoCell}>
              <Typography color='primary'>Route info</Typography>
            </Grid>
            <Grid item xs={3} className={classes.routeInfoCell}>
              <Distance distance={route.properties.distance}/>
            </Grid>
            <Grid item xs={3} className={classes.routeInfoCell}>
              <QueryBuilder color='primary' fontSize='small'/>
              <Duration duration={route.properties.duration}/>
            </Grid>
          </Grid>
        ))}
      </If>
    </>
  );
});
