import React, {useEffect, useMemo, useState} from 'react';
import {Skeleton} from '@material-ui/lab';

import ConditionalWrapper from 'shared/components/ConditionalWrapper/ConditionalWrapper';

import {fullWidth} from '../../../shared/const/stylesDef';

interface Props {
  path: string;
  imageFileName?: string;
}

export const MapImage: React.FC<Props> = ({path, imageFileName}) => {
  const [src, setMapImageSrc] = useState<string>();

  const imagePath = useMemo(() => {
    return path && imageFileName ? `${path}/${imageFileName}` : undefined;
  }, [path, imageFileName]);

  useEffect(() => {
    if (imagePath) {
      const newImage = new Image();
      newImage.src = imagePath;
      newImage.onload = () => setMapImageSrc(imagePath);
    }
  }, [imagePath]);

  return (
    <ConditionalWrapper
      condition={!!src}
      skeleton={
        <Skeleton
          animation="wave"
          style={{height: '300px'}}
        />
      }
    >
      <div style={{
        ...fullWidth,
        height: '300px',
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}/>
    </ConditionalWrapper>
  );
};
