import React, {useCallback} from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Clear';
import HomeIcon from '@material-ui/icons/Home';
import PlusIcon from '@material-ui/icons/Add';

import {PointType} from '../../../shared/const/wayPointType';
import If from '../../../shared/components/If/If';
import MapBoxHandler from '../MapBoxHandler';
import {useUserLocationCoords} from '../mapHooks/useUserLocationCoords';

import {ContextMenuButton} from './ContextMenuButton';

interface Props {
  pointType?: PointType;
  setNewPoint: () => void;
  buttonsDisabled: boolean;
  handleClose: () => void;
}

const ContextMenuActionsButtons: React.FC<Props> = (
  {
    pointType,
    setNewPoint,
    buttonsDisabled,
    handleClose,
  }) => {
  const homeLocation = useUserLocationCoords();

  const centerOnHomePoint = useCallback(() => {
    MapBoxHandler.flyTo(homeLocation, 17);
    handleClose();
  }, [handleClose, homeLocation]);

  return (
    <>
      <If condition={pointType === PointType.newWayPoint}>
        <ContextMenuButton
          click={setNewPoint}
          deg={0}
          icon={<CheckIcon/>}
          disabled={buttonsDisabled}
        />
      </If>
      <If condition={pointType === PointType.newWayPointOnRoute}>
        <ContextMenuButton
          click={setNewPoint}
          deg={0}
          icon={<PlusIcon/>}
          disabled={buttonsDisabled}
        />
      </If>
      <If condition={pointType === PointType.newWayPoint}>
        <ContextMenuButton
          click={centerOnHomePoint}
          deg={135}
          icon={<HomeIcon/>}
        />
      </If>
      {/*<ContextMenuButton*/}
      {/*  click={moreIconClick}*/}
      {/*  deg={45}*/}
      {/*  icon={<MoreIcon/>}*/}
      {/*/>*/}
      <ContextMenuButton
        click={handleClose}
        deg={90}
        icon={<CancelIcon/>}
      />
    </>
  );
};
ContextMenuActionsButtons.displayName = 'ContextMenuActionsButtons';
export default ContextMenuActionsButtons;
