import {bbox, Feature, lineString, Position} from '@turf/turf';

import {poiIconPath, weatherIconPath} from '../../shared/components/weather/WeatherIcon/WeatherIcon';
import {poiIcons, weatherIcons} from '../../shared/const/weatherIcons';
import Log from '../../shared/utils/Log';
import {PointInterface} from '../../interfaces/PointInterface';

class MapBoxHandler {
  _instance: any = undefined;

  get instance() {
    return this._instance;
  }

  set instance(map) {
    this._instance = map;

    const setIcon = (icon) => (path) => {
      this._instance.loadImage(path(icon), (error, image) => {
        if (error) {
          Log.warn(error);
          return;
        }
        this._instance.addImage(icon, image);
      });
    };

    weatherIcons.forEach(icon => {
      setIcon(icon)(weatherIconPath);
    });

    poiIcons.forEach(icon => {
      setIcon(icon)(poiIconPath);
    });

    this._instance.on('flystart', () => {
      //console.log('fly start');
    });

    this._instance.on('flyend', () => {
      //console.log('fly end');
    });

    this._instance.on('moveend', () => {
      //console.log('moveend');
    });
  }

  resizeMap = () => {
    if (this.instance) {
      setTimeout(() => {
        this.instance.resize();
      });
    }
  };

  getZoom = () => {
    return this.instance ? this.instance.getZoom() : 15;
  };

  flyTo = (center, zoom = 9) => {
    try {
      this.instance.flyTo({
        center,
        zoom,
      });
    } catch (e) {
      Log.warn(e);
    }
  };

  fromLngLat = (lng, lat) => {
    if (this.instance) {
      const p = this.instance.project([lng, lat]);
      Log.info(p);
    }
  };

  getCenter = () => {
    return this.instance.getCenter();
  };

  setCenter = (coordinates) => {
    this.instance.setCenter(coordinates);
  };

  fitToRouteBounds(wayPoints) {
    const line: Position[] = [];
    const options = {padding: 90, maxZoom: 15};

    wayPoints.map((point: Feature<PointInterface>) => {
      return point?.geometry?.coordinates && line.push(point.geometry.coordinates);
    });

    const lineS = lineString(line);
    this.instance.fitBounds(bbox(lineS), options);
  }

  fitToBoundingBox(boundingBox: number[][]) {
    const options = {padding: 90, maxZoom: 15};
    const lineS = lineString(boundingBox);
    this.instance.fitBounds(bbox(lineS), options);
  }

  setFeatureState(source, state) {
    this.instance.setFeatureState(source, state);
  }
}

export default new MapBoxHandler();
