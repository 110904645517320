import React, {useContext, useEffect} from 'react';
import {Route} from 'react-router-dom';

import NotFound from '../../../pages/notFound';
import ErrorLayout from '../../../layout/error/errorLayout';
import {AuthContext} from '../../../providers/AuthWrapper';

interface Props {
  component: any;
  layout: React.FC<any>;
  title: string;

  [x: string]: any;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const {component: Component, layout: Layout, title, ...rest} = props;

  const {isAuth} = useContext(AuthContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Route {...rest} render={() => (
    isAuth
      ? <Layout component={Component}/>
      : <ErrorLayout component={NotFound}/>)
  }/>;
};

export default PrivateRoute;
