import {toJS} from 'mobx';

import {PointInterface} from 'interfaces/PointInterface';
import {FuelStationsInterface} from 'interfaces/FuelStationsInterface';

import {Feature} from '@turf/turf';

import {MapEventObject, mapEventType, MapRouteDateDialog} from '../../shared/const/mapEvents';
import Log from '../../shared/utils/Log';
import {PointType} from '../../shared/const/wayPointType';

import {MapPointCoords} from './index';

class MapEvents {
  private currentObjects: mapEventType[] = [];

  get event(): mapEventType | null {
    return this.currentObjects[0] ?? null;
  }

  set event(event: mapEventType | null) {
    if (event !== null) {
      this.currentObjects.push(event);
    }
  }

  listen(event: mapEventType, callback) {
    document.addEventListener(event.toString(), callback);
  }

  stopListening(event: mapEventType, callback) {
    document.removeEventListener(event.toString(), callback);
  }

  clearEvents() {
    this.currentObjects = [];
  }
}

const mapEvents = new MapEvents();

export const clearMapEvent = (): void => {
  mapEvents.clearEvents();
};

export const showContextMenu = (point: MapPointCoords): void => {
  const detail = {
    point: toJS(point),
    pointType: PointType.newWayPoint,
    mapObject: MapEventObject.Map,
  };
  dispatchMapEvent(mapEventType.ShowContextMenu, detail);
};

export const leaveContextMenu = (): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.LeaveContextMenu);
  clearMapEvent();
};

export const onWayPointClick = (point: Feature<PointInterface>): void => {
  clearMapEvent();
  const detail = {
    point: toJS(point?.properties?.id),
    pointType: PointType.existingWayPoint,
    mapObject: MapEventObject.WayPoint,
  };
  dispatchMapEvent(mapEventType.WayPointClick, detail);
};

export const onRouteClick = (coordinates: number[]): void => {
  const detail = {
    point: coordinates,
    pointType: PointType.newWayPointOnRoute,
  };
  clearMapEvent();
  dispatchMapEvent(mapEventType.RouteClick, detail);
};

export const selectWeatherPoint = (point: Feature<PointInterface>): void => {
  dispatchMapEvent(mapEventType.SelectWeatherPoint, {point: toJS(point), mapObject: MapEventObject.WeatherModal});
};

export const onRouteEnter = (): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.OnRouteEnter);
};

export const onRouteLeave = (): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.OnRouteLeave);
  clearMapEvent();
};

export const dispatchEnterMapPoint = (point: Feature<PointInterface>): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.EnterMapPoint, {point: toJS(point), mapObject: MapEventObject.WayPoint});
};

export const dispatchEnterFuelPoint = (point: FuelStationsInterface): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.EnterFuelPoint, {point: toJS(point), mapObject: MapEventObject.FuelPoint});
};

export const dispatchLeaveMapPoint = (): void => {
  clearMapEvent();
  dispatchMapEvent(mapEventType.LeaveMapPoint);
  clearMapEvent();
};

export const dateModalEvent = (openDateModal: boolean): void => {
  document.dispatchEvent(new CustomEvent(MapRouteDateDialog.openMapDialog.toString(), {
    detail: {
      openDateModal,
    },
  }));
};


const dispatchMapEvent = (eventName: mapEventType, detail: {point?: Feature<PointInterface> | MapPointCoords | FuelStationsInterface | number[], mapObject?: MapEventObject} = {}) => {
  if (mapEvents.event) {
    Log.warn(`${eventName.toString()} blocked: first, clean up map events from ${mapEvents.event}`);
    return;
  }
  Log.info(`Fire up event ${eventName}`);
  document.dispatchEvent(new CustomEvent(eventName.toString(), {
    bubbles: false,
    detail,
  }));

  mapEvents.event = eventName;
};

export default mapEvents;
