import React from 'react';
import {Box, IconButton, ListItem, ListItemSecondaryAction, Slide} from '@material-ui/core';

import BackIcon from '@material-ui/icons/Undo';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../../../interfaces/PointInterface';

import DeleteWayPointButton from './DeleteWayPointButton';
import FindGasStationsButton from './FindGasStationsButton';

interface Props {
  slideIn?: boolean;
  onCancel?: () => void;
  wayPoint: Feature<PointInterface>;
}

const PointActionsContainer: React.FC<Props> = (props) => {
  const {slideIn, onCancel, wayPoint} = props;

  return (
    <ListItem>
      <Box style={{width: '100%', 'overflow': 'hidden'}}>
        <Slide in={slideIn} direction={'left'} timeout={300}>
          <div>
            <DeleteWayPointButton wayPoint={wayPoint}/>
            <FindGasStationsButton wayPoint={wayPoint}/>
          </div>
        </Slide>
      </Box>
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={onCancel}>
          <BackIcon color={'secondary'}/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PointActionsContainer;
