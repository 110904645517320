import {useCallback, useContext} from 'react';

import {ZoomContext} from '../MapProviders/ZoomProvider';

export function useZoomIn() {
  const {decrease} = useContext(ZoomContext);

  return useCallback(() => {
    decrease();
  }, [decrease]);
}

export function useZoomOut() {
  const {increase} = useContext(ZoomContext);

  return useCallback(() => {
    increase();
  }, [increase]);
}
