import * as Storage from '../shared/functions/Storage';
import {add, removeKey} from '../shared/functions/Storage';
import Log from '../shared/utils/Log';

export class RequestHeaders {
  constructor() {
    const storageToken = Storage.get('token');


    if (storageToken) {
      this.token = storageToken;
    } else {
      Log.warn('no auth authToken');
    }
  }

  private _token: string | undefined = undefined;


  get token(): string {
    return `Bearer ${this._token}`;
  }


  set token(token: string) {
    if (token) {
      this._token = token;
      add('token', token);
    }
  }

  get auth() {
    return {
      headers: {Authorization: this.token},
    };
  }

  clearToken() {
    removeKey('token');
    this.token = '';
  }
}
