import {useEffect, useState} from 'react';

export function useWindowDimensions() {
	const [size, setSize] = useState({height: 0, width: 0});

	useEffect(() => {
		setSize({height: window.innerHeight, width: window.innerWidth, });
	}, []);

	const setViewportSize = () => {
		setSize({height: document.body.clientHeight, width: document.body.clientWidth});
	};

	useEffect(() => {
		window.addEventListener('resize', setViewportSize);
		return () => {
			window.removeEventListener('resize', setViewportSize);
		};
	}, []);

	return [size];
}
