import React from 'react';

type Props = {
  condition: boolean;
  children: React.ComponentElement<any, any>;
  skeleton?: React.ComponentElement<any, any>;
}

const ConditionalWrapper: React.FC<Props> = React.memo(({condition, children, skeleton}) => {
  return condition ? (<>{children}</>) : (skeleton ?? null);
});
ConditionalWrapper.displayName='ConditionalWrapper';
export default ConditionalWrapper;
