import {Subject, timer} from 'rxjs';
import {debounce} from 'rxjs/operators';
import React, {useCallback, useEffect, useState} from 'react';
import {createStyles, makeStyles} from '@material-ui/core';
import {useToggle} from 'hooks/useToggle';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import {useRootStore} from 'shared/stores/RootStore';


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '8px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    input: {
      flex: 11,
      marginLeft: '8px',
    },
    buttonContainer: {
      flex: 1,
      justifyContent: 'flex-end',
    },
  }));

const subject: Subject<any> = new Subject();

const SearchAddressInput: React.FC = () => {
  const [value, setValue] = useState<string>('');
  const {addressStore} = useRootStore();
  const [disabled, {on, off}] = useToggle(true);
  const classes = useStyles();

  const lookupAddress = useCallback(
    (val: string) => {
      addressStore.lookup$(val);
    },
    [addressStore],
  );

  const clearText = useCallback(() => {
    on();
    setValue('');
  }, [on]);

  useEffect(() => {
    subject.pipe(debounce(() => timer(900))).subscribe(lookupAddress);
  }, [lookupAddress]);

  const onChange = (e) => {
    const {
      target: {value},
    } = e;
    off();
    setValue(value);
    addressStore.setLoading(true);
    subject.next(value);
  };

  const onKeyPress = e => {
    e.key === 'Enter' && e.preventDefault();
  };

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        placeholder="Start typing to search"
        onChange={onChange}
        value={value}
        autoComplete="off"
        className={classes.input}
        onKeyPress={onKeyPress}
      />
      <div className={classes.buttonContainer}>
        <IconButton color="secondary" onClick={clearText} disabled={disabled}>
          <CloseIcon/>
        </IconButton>
      </div>
    </Paper>
  );
};

export default SearchAddressInput;
