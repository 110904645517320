import React from 'react';

import {LoginSignUpModal} from '../modal-login/LoginSignupModal';

const LayoutWrapper = ({children}) => (
  <React.Fragment>
    {children}
    <LoginSignUpModal/>
  </React.Fragment>
);

export default LayoutWrapper;
