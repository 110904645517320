import React, {useCallback, useState} from 'react';

import {Feature, LineString, nearestPointOnLine, point} from '@turf/turf';

import {doNothing} from 'shared/utils/functionsUtils';
import {lngLatToCords} from 'shared/utils/turfUtils';

interface DirectionLayerPointContextProps {
  coordinates?: number[];
  setCoordinates: (coordinates: number[]) => void;
  clearCoords: () => void;
  findNearestPoint: (lngLat: {lat: number, lng: number}) => void;
  setLineString: (line: Feature<LineString, any>) => void;
}

const initialData = {
  coordinates: undefined,
  setCoordinates: doNothing,
  clearCoords: doNothing,
  findNearestPoint: doNothing,
  setLineString: doNothing,
};

export const DirectionLayerPointContext = React.createContext<DirectionLayerPointContextProps>(initialData);

export const DirectionLayerWrapper = ({children}) => {
  const [coordinates, setCoordinates] = useState<number[] | undefined>(initialData.coordinates);
  const [line, setLineString] = useState<any>();

  const findNearestPoint = useCallback((lngLat) => {
    if (line) {
      const nearest = nearestPointOnLine(line, point(lngLatToCords(lngLat) as number[]), {units: 'meters'});
      nearest.geometry?.coordinates && setCoordinates(nearest.geometry.coordinates as number[]);
    }
  }, [line, setCoordinates]);

  const clearCoords = () => {
    setCoordinates(undefined);
  };

  return (
    <DirectionLayerPointContext.Provider
      value={{
        coordinates,
        setCoordinates,
        clearCoords,
        findNearestPoint,
        setLineString,
      }}
    >{children}
    </DirectionLayerPointContext.Provider>
  );
};
