import React from 'react';
import {Route} from 'react-router-dom';

import MainAppBar from '../../shared/components/MainAppBar/MainAppBar';
import MainDrawer from '../../shared/components/MainDrawer/index';
import {LayoutInterface} from '../../interfaces/LayoutInterface';

const ErrorLayout: React.FC<LayoutInterface> = ({component: Component, ...rest}) => (
	<Route
		{...rest}
		render={matchProps => (
			<React.Fragment>
				<MainDrawer />
				<MainAppBar />
				<Component {...matchProps} />
			</React.Fragment>
		)}
	/>
);

export default ErrorLayout;
