export const WAY_POINT = 'wayPoint';
export const TANK_POINT = 'tankPoint';
export const HOME_POINT = '_homePoint';
export const WEATHER_POINT = 'weatherPoint';
export const FUEL_POINT = 'fuelPoint';

export enum PointType {
  newWayPoint = 'newWayPoint',
  existingWayPoint = 'existingWayPoint',
  wayPointOnRoute = 'wayPointOnRoute',
  newWayPointOnRoute = 'newWayPointOnRoute',
  tankPoint = 'tankPoint',
  homePoint = '_homePoint',
  weatherPoint = 'weatherPoint',
  fuelPoint = 'fuelPoint',
}
