import moment, {DurationInputArg2, Moment} from 'moment/moment';

import {LegsInterface} from '../../interfaces/RoutesInterface';
import {DateFormat} from '../components/DateDisplay/DateDisplay';

export const joinTimeArray = (legs: LegsInterface[]): number[] => {
  return legs.reduce((durations: number[], current) => durations.concat(current.annotation.duration), []);
};

export const arrivingDateTime = (startDate: Moment | Date, addTime: number, units: DurationInputArg2 = 's'): Moment => moment(startDate)
  .add(addTime, units);

export const isSameDay = (date: Moment | Date, dateToCompare: Moment | Date): boolean => moment(date)
  .isSame(dateToCompare, 'day');

export const dateToString = () => moment().format(DateFormat.FullDate);

export const isForecastIsOutdated = (checkDate: string): boolean => {
  const duration = moment.duration(moment().diff(moment(checkDate)));
  return duration.asHours() > 2;
};
