import React, {useCallback, useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import ReactMapboxGl, {Layer} from 'react-mapbox-gl';
import {Backdrop, CircularProgress, Typography, useTheme} from '@material-ui/core';

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import 'mapbox-gl/dist/mapbox-gl.css';

import {mapboxToken} from '../../shared/const/mapbox';
import {DEFAULT_MAPS_LAYOUT} from '../../shared/const/maps-settings';
import {useWindowDimensions} from '../../hooks/useWindowDimensions';
import {DrawersContext} from '../../providers/DrawersProvider';
import {useToggle} from '../../hooks/useToggle';
import WeatherMapPointModal from '../../shared/components/weather/WeatherMapPointModal/WeatherMapPointModal';
import {LatLngInterface} from '../../interfaces/LatLngInterface';
import {useRootStore} from '../../shared/stores/RootStore';

import {drawerWidth} from './mapDrawer/MapDrawer';
import MapZoomControl from './ZoomControl/ZoomControl';
import {maxZoom, minZoom, ZoomContext} from './MapProviders/ZoomProvider';
import MapBoxHandler from './MapBoxHandler';
import {HomeLayer} from './layers/HomeLayer';
import {showContextMenu} from './MapEvents';
import MapContextMenu from './contextmenu/mapContextMenu';
import {DirectionWayPointsLayer} from './layers/WayPointsLayer/DirectionWaypointsLayer';
import DirectionLayer from './layers/DirectionLayers/DirectionLayer';
import {DirectionLayerWrapper} from './layers/DirectionLayers/DirectionLayerProvider';
import {DirectionLayerWayRouteWayPoint} from './layers/DirectionLayers/DirectionLayerWayRouteWayPoint';
import {PointOrderModalChooser} from './pointOrderModalChooser/pointOrderModalChooser';
import WeatherLayer from './layers/WeatherLayer';
import {SelectionLayer} from './layers/SelectionLayer';
import {TankPointsLayer} from './layers/TankPointsLayer';

const Map: any = ReactMapboxGl({
  minZoom: minZoom,
  maxZoom: maxZoom,
  accessToken: mapboxToken,
});

interface Props {
  height: number;
  width: number;
  classes: any;
}

export type MapPointCoords = {
  point?: {x: number, y: number};
  lngLat: LatLngInterface;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.common.white,
      opacity: 0.7,
    },
    backdropContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    caption: {
      paddingTop: '16px',
    },
  }),
);

const MapContainer: React.FC<Props> = () => {
  const [mapStyle, setMapStyle] = useState({});
  const [size] = useWindowDimensions();
  const {mapDrawerState} = useContext(DrawersContext);
  const {zoom, setMapZoom} = useContext(ZoomContext);
  const {favoritePlacesStore} = useRootStore();
  const [backdrop, {on: showBackdrop, off: hideBackdrop}] = useToggle(false);

  const classes = useStyles();
  const center = favoritePlacesStore.homePoint?.point.geometry?.coordinates ?? [];


  useEffect(() => {
    showBackdrop();
    return () => {
      hideBackdrop();
    };
  }, [hideBackdrop, showBackdrop]);

  const theme = useTheme();

  useEffect(() => {
    const width = mapDrawerState ? size.width - drawerWidth : size.width;
    setMapStyle({
      left: mapDrawerState ? drawerWidth : 0,
      height: size.height,
      width,
      flex: 1,
      backgroundColor: theme.palette.grey.A200,
    });
    !mapDrawerState && MapBoxHandler.resizeMap();
  }, [size, mapDrawerState, theme.palette.grey.A200]);

  const onMapClick = useCallback((map, point: MapPointCoords) => {
    showContextMenu(point);
  }, []);

  const onZoomEnd = useCallback(() => {
    setMapZoom(MapBoxHandler.getZoom());
  }, [setMapZoom]);

  const onDataLoaded = useCallback((map) => {
    hideBackdrop();
    MapBoxHandler.instance = map;
    MapBoxHandler.resizeMap();
  }, [hideBackdrop]);

  return (
    <>
      <Backdrop open={backdrop} className={classes.root}>
        <div className={classes.backdropContent}>
          <CircularProgress color="primary"/>
          <Typography variant='caption' className={classes.caption}>Loading map</Typography>
        </div>
      </Backdrop>
      <Map
        style={DEFAULT_MAPS_LAYOUT}
        center={center}
        containerStyle={mapStyle}
        onClick={onMapClick}
        onStyleLoad={onDataLoaded}
        onZoomEnd={onZoomEnd}
        zoom={[zoom]}
      >
        <MapContextMenu/>
        <PointOrderModalChooser/>
        <HomeLayer id='home'/>
        <Layer id='base'/>
        <DirectionLayerWrapper>
          <DirectionLayerWayRouteWayPoint/>
          <DirectionLayer before='home'/>
        </DirectionLayerWrapper>
        <DirectionWayPointsLayer id='wayPoints' before='base'/>
        <SelectionLayer id='selectionLayer' before='base'/>
        <WeatherLayer id='weatherLayer' before='base'/>
        <MapZoomControl zoom={zoom}/>
        <TankPointsLayer id='tankPoints' before='base'/>
      </Map>
      <WeatherMapPointModal usePointContext={true}/>
    </>
  );
};

export default withRouter(MapContainer);
