class Log {
  private appName = 'Want2Ride app: ';


  info(message: unknown): void {
    let infoMessage = '';

    if (typeof message === 'object') {
      try {
        infoMessage = JSON.stringify(message);
      } catch (e) {
        console.error(e);
        infoMessage = 'parsing JSON error';
      }
    } else {
      infoMessage = message as string;
    }

    console.log(`%c ${this.appName} ${infoMessage}`, 'color:blue');
  }

  warn(message): void {
    console.warn(`%c ${this.appName} ${message} `, 'color:orange');
  }

  error(message: unknown, ...args): void {
    console.error(`%c ${this.appName} ${message}`, ...args, 'color:red');
  }
}

const logger = new Log();

export default logger;
