import React from 'react';

interface Props {
  t: string;
}

export const Translate: React.FC<Props> = ({t}) => {

  return (
    <>{t}</>
  );
};
