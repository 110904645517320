import moment from 'moment';
import {useCallback} from 'react';

import {ItemForecastInterface} from '../interfaces/weather/ForecastWeatherInterface';
import Log from '../shared/utils/Log';
import {DateFormat} from '../shared/components/DateDisplay/DateDisplay';

export type ForecastByDayType = Map<string, ItemForecastInterface[] | unknown>;


export const useForecastByDay = () => useCallback((data: ItemForecastInterface[]) => {
  const forecastByDay: ForecastByDayType = new Map();

  if (data) {
    data.forEach((item) => {
      const day = moment(item.dt_txt).format(DateFormat.DefaultDate);
      forecastByDay.has(day)
        ? forecastByDay.set(day, [...forecastByDay.get(day) as ItemForecastInterface[], item])
        : forecastByDay.set(day, [item]);
    });
  } else {
    Log.warn('no forecast data given');
  }

  return {forecastByDay, days: Array.from(forecastByDay.keys())} as const;
}, []);
