import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';

import {ForecastWeatherInterface, ItemForecastInterface} from 'interfaces/weather/ForecastWeatherInterface';

import {getForecast} from '../shared/requests/weather.request';

import {UserHomeLocationContext} from './UserLocationWrapper';
import {UserContext} from './UserWrapper';

export interface currentHomeForecastProps {
  forecast?: ForecastWeatherInterface;
  forecastGroupedByDays?: Map<string, ItemForecastInterface[]>
}

export const initialForecastData: currentHomeForecastProps = {forecast: undefined, forecastGroupedByDays: undefined};

export const CurrentHomeForecastContext = React.createContext(initialForecastData);

export const CurrentHomeForecastProvider = ({children}) => {
  const [currentHomeForecast, setCurrentHomeForecast] = useState<ForecastWeatherInterface>(initialForecastData.forecast as ForecastWeatherInterface);
  const [forecastGroupedByDays, setForecastGroupedByDays] = useState<Map<string, ItemForecastInterface[]>>(new Map());
  const {userHomeLocationData} = useContext(UserHomeLocationContext);
  const {profile} = useContext(UserContext);

  const groupForecast = (data: ForecastWeatherInterface): void => {
    const forecastByDay = new Map<string, ItemForecastInterface[] | any>();
    data.list.forEach(item => {
      const day = moment(item.dt_txt).format('ddd');
      forecastByDay.has(day)
        ? forecastByDay.set(day, [...forecastByDay.get(day), item])
        : forecastByDay.set(day, [item]);
    });
    setForecastGroupedByDays(forecastByDay);
  };

  useEffect(() => {
    if (userHomeLocationData && userHomeLocationData?.homePoint?.geometry?.coordinates && profile.language && profile._id) {
      getForecast(userHomeLocationData.homePoint.geometry.coordinates)
        .then((data) => {
          setCurrentHomeForecast(data as ForecastWeatherInterface);
          groupForecast(data as ForecastWeatherInterface);
        });
    }
  }, [profile._id, profile.language, userHomeLocationData]);

  return (
    <CurrentHomeForecastContext.Provider
      value={{
        forecast: currentHomeForecast,
        forecastGroupedByDays,
      }}>{children}</CurrentHomeForecastContext.Provider>
  );
};
