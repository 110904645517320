import React from 'react';

import Button from '@material-ui/core/Button';

import {AnyInterface} from 'interfaces/AnyInterface';

export const CircleButton: React.FC<AnyInterface> = (props) => {
  return (
    <Button  {...props}>
      {props.children}
    </Button>
  );
};
