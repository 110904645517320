import React from 'react';
import {LinearProgress, Typography} from '@material-ui/core';

import {APP_NAME} from 'shared/const/settings';

import {ContainerFullSize} from '../Container/ContainerFullScreen';

interface Props {
  display: boolean
}

const Overlay: React.FC<Props> = (props) => {
  return (
    <ContainerFullSize styles={{display: props.display ? 'flex' : 'none'}}>
      <Typography variant='h3'>{APP_NAME}</Typography>
      <div>
        <LinearProgress/>
      </div>
    </ContainerFullSize>
  );
};

export default Overlay;
