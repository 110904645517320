import {PointInterface} from 'interfaces/PointInterface';

import {Feature} from '@turf/turf';

import {catchError, http} from '../functions/HttpAuth';
import {dispatchEventMessages, NotificationType} from '../components/Notificator/Notifier';

export interface UserHomeLocationProps {
  _id: string;
  homePoint: Feature<PointInterface>
}

const url = '/api/location/home';

const notificationError = {
  type: NotificationType.error,
  name: 'start location',
  message: 'No given user starting location',
};

export const getUserHomeLocationRequest = (): Promise<UserHomeLocationProps | never> => http.get(url)
  .catch((err) => {
    dispatchEventMessages(notificationError);
    catchError(err);
  });

export const setHomeLocationRequest = data =>
  http
    .post(url, data).catch(catchError);
