import {AxiosRequestConfig} from 'axios';

import {RequestHeaders} from './headers';

export interface MiddlewareInterface {
  authHeaders: Pick<AxiosRequestConfig, 'headers'>
}

export class mw {
  private _requestHeaders;

  constructor() {
    this._requestHeaders = new RequestHeaders();
  }

  set authToken(token: string) {
    this._requestHeaders.token = token;
  }

  get authHeaders(): {Authorization: string} {
    return this._requestHeaders.auth;
  }
}

const middleware = new mw();

export default middleware;
