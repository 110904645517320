import {action, computed, observable} from 'mobx';

import {UnitStoreInterface} from 'interfaces/stores/UnitStoreInterface';
import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';

import {IMPERIAL_UNIT, imperialUnit, metricUnit,UnitType} from 'shared/const/units';

export enum UnitTypeNames {
  Imperial = 'imperial',
  Metric = 'metric'
}

class UnitStore<T, ParamsType> implements UnitStoreInterface<T, ParamsType> {
  private rootStore: RootStoreInterface<T, ParamsType>;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable
  unit: UnitType = metricUnit;

  @observable
  unitType = '';

  @action
  setUnit(unitType) {
    this.unitType = unitType;
    switch (unitType) {
      case IMPERIAL_UNIT:
        this.unit = imperialUnit;
        break;
      default:
        this.unit = metricUnit;
        break;
    }
  }

  @computed
  get fuelConsumptionUnit() {
    return this.unit.fuelConsumptionUnit(this.unit);
  }

  @computed
  get distanceUnit(): string {
    return this.unit.distance.short;
  }

  @computed
  get capacityUnit() {
    return this.unit.capacity.short;
  }

  @computed
  get temperatureUnit() {
    return {'__html': this.unit.temperature.short};
  }

  @computed
  get unitObject() {
    return this.unit;
  }

  @computed
  get type() {
    return this.unitType;
  }

  @computed
  get options() {
    return this.unit.distance.name.toLowerCase();
  }

  @action
  getUnitsByType(unitType): UnitType {
    switch (unitType) {
      case IMPERIAL_UNIT:
        return imperialUnit;
      default:
        return metricUnit;
    }
  }

  @action
  getDistanceUnit(unitType): string {
    return this.getUnitsByType(unitType).distance.short;
  }

  @action
  getCapacityUnit(unitType) {
    return this.getUnitsByType(unitType).capacity.short;
  }

  @action
  getFuelConsumptionUnit(unitType) {
    const unit = this.getUnitsByType(unitType);
    return unit.fuelConsumptionUnit(unit);
  }
}

export default UnitStore;
