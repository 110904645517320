import React, {useCallback, useContext} from 'react';
import {Feature, Layer} from 'react-mapbox-gl';

import {PointInterface} from 'interfaces/PointInterface';

import {Feature as PointFeature} from '@turf/turf';

import {useRootStore} from 'shared/stores/RootStore';
import Log from 'shared/utils/Log';
import {lngLatToCords} from 'shared/utils/turfUtils';

import {PointContext} from '../../MapProviders/MapPointProvider';
import {useMapBoxTheme} from '../LayerPointStyle';
import {dispatchEnterMapPoint, dispatchLeaveMapPoint, onWayPointClick} from '../../MapEvents';
import {generatePoint} from '../../contextmenu/generatePoint';


type Props = {
  wayPoints: PointFeature<PointInterface>[];
  id: string;
  before: string;
}

export const WayPointLayer: React.FC<Props> = ({wayPoints, id, before}) => {
  const {wayPointsStore, routeTimeStore} = useRootStore();
  const {setSelectedPointIndex, setPointCoords} = useContext(PointContext);

  const mapBoxTheme = useMapBoxTheme();

  const onDragEnd = async (event, wayPoint: PointFeature<PointInterface>) => {
    const coordinates = lngLatToCords(event.lngLat) as number[];
    const newPoint = await generatePoint(coordinates, routeTimeStore.routeStartDate);
    if (newPoint?.properties) {
      const index = wayPoint?.properties?.index;
      newPoint.properties.index = index;
      wayPointsStore._wayPoints.splice(index, 1);
      wayPointsStore.addWayPointAtIndex(newPoint, index);
    }
  };

  const onMouseEnter = useCallback((event, wayPoint) => {
    dispatchEnterMapPoint(wayPoint);
    setSelectedPointIndex(event.feature.properties.id);
  }, [setSelectedPointIndex]);

  const onMouseLeave = useCallback(() => {
    dispatchLeaveMapPoint();
    setSelectedPointIndex(undefined);
  }, [setSelectedPointIndex]);

  const onLayerClick = useCallback((event) => {
    setPointCoords(event);
  }, [setPointCoords]);

  const onDragStart = useCallback(() => {
    dispatchLeaveMapPoint();
  }, []);

  const onClick = useCallback((point: PointFeature<PointInterface>) => {
    Log.info(`existing point click ${point.geometry?.coordinates}, index: ${point?.properties?.id}`);
    onWayPointClick(point);
  }, []);

  return (
    <Layer
      type="circle"
      id={id}
      before={before}
      paint={mapBoxTheme.circlePaint}
      onClick={onLayerClick}
    >
      {wayPoints.map((wayPoint, index) =>
        (wayPoint?.geometry?.coordinates && <Feature
					properties={{id: index}}
					coordinates={wayPoint.geometry.coordinates}
					key={`feature${index}`}
					draggable={true}
					onClick={() => onClick(wayPoint)}
					onDragStart={onDragStart}
					onDragEnd={(event) => onDragEnd(event, wayPoint)}
					onMouseEnter={(event) => onMouseEnter(event, wayPoint)}
					onMouseLeave={onMouseLeave}
				/>))
      }
    </Layer>
  );
};
