import {authEvent} from '../providers/AuthWrapper';
import Log from '../shared/utils/Log';

import {useEventListener} from './useEventListener';

export const dispatchLogoutEvent = (): void => {
  Log.info(`Fire up event ${authEvent.logout}`);
  document.dispatchEvent(new CustomEvent(authEvent.logout));
};

export function useLogoutListener(onAction: () => void): void {
  useEventListener(authEvent.logout, onAction);
}
