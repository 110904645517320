import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import keys from 'lodash/keys';
import values from 'lodash/values';

import {http} from '../functions/HttpAuth';

export const fetchTranslations = lang => {
  const url = `/api/translate/lang/${lang}`;
  return http.get(url)
    .then(response => langResponse(response, lang));
};

export const fetchLangList = () => {
  const url = '/api/translate/lang/';
  const lang = 'en';
  return http.get(url)
    .then(response => langResponse(response, lang));
};

const langResponse = (response, lang) => {
  const currentLang = pickBy(response[lang], val => val !== '');
  const en = pickBy(response[lang], val => val !== '');
  return {
    translations: merge(en, currentLang),
    langList: values(keys(response)),
  };
};
