import React, {ChangeEvent, useCallback, useContext, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Paper} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {UserContext} from '../../providers/UserWrapper';
import {Translate} from '../../shared/components/translate/Translate';
import If from '../../shared/components/If/If';
import LanguageSelector from '../../shared/components/Language/LanguageSelector';

import {nickValidator} from './validators';


const useStyles = makeStyles(() => ({
  cell: {
    padding: 8,
  },
  buttonContainer: {
    marginTop: '16px',
    display: 'flex',
    flexAlign: 'center',
    justifyContent: 'center',
    '& :last-child': {
      marginLeft: '20px',
    },
  },
  button: {
    minWidth: '120px',
  },
  fieldsContainer: {
    marginBottom: '8px',
  },
}));

//const profileRequiredLabel = 'Nick will be display on your publish routes, or user list';
const labelAdditionalInformation = 'Personal information will be display only for users in your friends list';
const enableAdditionalProfileField = false;

const ProfileForm: React.FC = (() => {
  const classes = useStyles();
  const {
    profile,
    updateProfileProperty,
    saveProfile,
    loadDefaultProfileData,
    formTouched,
    touched,
    untouched,
  } = useContext(UserContext);
  const [nickValidationError, setNickValidationError] = useState<string>('');

  const onCancel = useCallback(() => {
    loadDefaultProfileData();
    untouched();
  }, [loadDefaultProfileData, untouched]);

  const onSaveProfile = useCallback(
    () => {
      saveProfile();
      untouched();
    },
    [saveProfile, untouched],
  );

  const onFieldChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    updateProfileProperty(name, value);
    touched();
  }, [touched, updateProfileProperty]);

  const onNickFieldBlur = useCallback((event) => {
    nickValidator.validate(event.target.value)
      .then(setNickValidationError(''))
      .catch(e => {
        setNickValidationError(e.message);
      });
  }, []);

  return (
    <>
      <Grid container style={{width: '100%'}} alignContent='center' justify='center'>
        <Cell>
          <TextField
            label="Nick"
            name="nick"
            fullWidth
            error={nickValidationError.length > 0}
            value={profile.nick}
            onChange={onFieldChange}
            onBlur={onNickFieldBlur}
            helperText={nickValidationError}
          />
        </Cell>
        <Cell>
          <LanguageSelector/>
        </Cell>
      </Grid>
      <If condition={enableAdditionalProfileField}>
        <FieldsContainer label={labelAdditionalInformation}>
          <Grid container style={{width: '100%'}}>
            <Cell>
              <TextField
                label="First name"
                name="firstName"
                fullWidth
                value={profile.firstName}
                onChange={onFieldChange}
              />
            </Cell>
            <Cell>
              <TextField
                label="Last name"
                name="lastName"
                fullWidth
                value={profile.lastName}
                onChange={onFieldChange}
              />
            </Cell>
            <Cell>
              <TextField
                label="Phone"
                name="phone"
                fullWidth
                value={profile.phone}
                onChange={onFieldChange}
              />
            </Cell>
          </Grid>
        </FieldsContainer>
      </If>
      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          disabled={!formTouched}
          onClick={onSaveProfile}
          variant='outlined'
          className={classes.button}
        >
          <Translate t={'Save'}/>
        </Button>
        <Button
          color='secondary'
          disabled={!formTouched}
          onClick={onCancel}
          variant='outlined'
          className={classes.button}
        >
          <Translate t={'Cancel'}/>
        </Button>
      </div>
    </>
  );
});

const Cell = (({children}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} className={classes.cell}>
      {children}
    </Grid>
  );
});

const FieldsContainer: React.FC<{label?: string; children: any}> = ({label, children}) => {
  const classes = useStyles();
  return (
    <Paper variant='outlined' className={classes.fieldsContainer}>
      <If condition={!!label}>
        <Paper elevation={0} className={classes.cell}>{label}</Paper>
      </If>
      {children}
    </Paper>
  );
};

export default ProfileForm;
