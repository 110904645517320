import React, {useCallback, useContext, useMemo} from 'react';
import {createStyles, IconButton, makeStyles, Theme, useTheme} from '@material-ui/core';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {DrawersContext} from '../../../../providers/DrawersProvider';
import {drawerWidth} from '../MapDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      left: drawerWidth,
      top: 0,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    containerClosed: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    buttonContainer: {
      backgroundColor: theme.palette.common.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[300],
      borderLeftColor: theme.palette.common.white,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
      position: 'relative',
    },
  }),
);

export const MapDrawerButtonSwitch: React.FC = () => {
  const {mapDrawerState, toggleMapDrawer} = useContext(DrawersContext);
  const classes = useStyles();
  const theme = useTheme();

  const toggle = useCallback(() => {
    toggleMapDrawer();
  }, [toggleMapDrawer]);

  const containerClass = useMemo(() => {
    return mapDrawerState ? classes.container : classes.containerClosed;
  }, [mapDrawerState, classes]);

  return (
    <div className={containerClass}>
      <div className={classes.buttonContainer} style={mapDrawerState ? {} : {backgroundColor: theme.palette.info.main}}>
        <div>
          <IconButton onClick={toggle}>
            {mapDrawerState ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
          </IconButton>
        </div>
      </div>
    </div>
  );
};
