import {catchError, http, remove} from '../functions/HttpAuth';
import {ProfileInterface} from '../../providers/UserWrapper';
import {dispatchEventMessages, NotificationType} from '../components/Notificator/Notifier';

const notificationError = {
  type: NotificationType.error,
  name: 'Profile',
  message: 'Error on saving profile',
};

const notificationSuccess = {
  type: NotificationType.success,
  name: 'Profile',
  message: 'Your profile has been updated',
};

export const profileResource = (): Promise<ProfileInterface | never> =>
  http
    .get('/api/profile')
    .catch(catchError);

export const saveProfileRequest = (data: ProfileInterface): Promise<unknown | never> =>
  http
    .post('/api/profile', data)
    .then(response => {
      dispatchEventMessages(notificationSuccess);
      return response;
    })
    .catch(err => {
      dispatchEventMessages(notificationError);
      catchError(err);
    });

export const saveProfileAvatarResource = file => {
  const formData = new FormData();
  formData.append('file', file);
  return http.post('/avatar', formData, process.env.REACT_APP_IMAGE_API);
};

export const deleteAvatarResource = () => {
  const url = '/api/profile/avatar';
  return remove(url);
};
