import React from 'react';

import Button from '@material-ui/core/Button';

const ButtonLogin: React.FC<{click: () => void;}> = ({click}) => {
  return (
    <Button onClick={click}>
      Login | Join
    </Button>
  );
};

export default ButtonLogin;
