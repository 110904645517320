import React from 'react';
import {Router, Switch} from 'react-router-dom';

import MainPage from './pages/main';
import NotFound from './pages/notFound';
import Profile from './pages/profile/Profile';
import DefaultLayout from './layout/default';
import PrivateRoute from './shared/components/PrivateRoute/PrivateRoute';
import history from './history';
import './routeStyles.css';
import MapContainer from './pages/maps/index';
import {
  DASHBOARD,
  LOGOUT_PAGE,
  MAP_LINK,
  PROFILE_LINK,
  USER_MAPS_LIST,
  USER_SHARED_MAPS,
  USERS_LIST,
} from './shared/const/NavigationLinks';
import ErrorLayout from './layout/error/errorLayout';
import MapsLayout from './layout/maps/MapsLayout';
import UserMaps from './pages/userMaps/UserMaps';
import Users from './pages/users/users';
import SharedMap from './pages/sharedMap/sharedMap';
import Settings from './pages/settings/settings';

const Routes: React.FC = () => (
  <Router history={history}>
    <Switch>
      <DefaultLayout
        exact
        path={DASHBOARD}
        component={MainPage}
        title={'Dashboard'}
      />
      <PrivateRoute
        path={PROFILE_LINK}
        title={'My profile'}
        layout={DefaultLayout}
        component={Profile}
      />
      <PrivateRoute
        path={MAP_LINK}
        title={'Map'}
        layout={MapsLayout}
        component={MapContainer}
      />
      <PrivateRoute
        path={USER_MAPS_LIST}
        title={'My maps'}
        layout={DefaultLayout}
        component={UserMaps}
      />
      <DefaultLayout path={USER_SHARED_MAPS + '/:slug'} component={SharedMap}/>
      <PrivateRoute
        path={USERS_LIST}
        title={'Users'}
        layout={DefaultLayout}
        component={Users}
      />
      <DefaultLayout
        exact
        path={LOGOUT_PAGE}
        title={'Logout'}
        layout={DefaultLayout}
        component={Settings}
      />
      <ErrorLayout component={NotFound} title={'Lost direction'}/>
    </Switch>
  </Router>
);

export default Routes;
