import {DialogTitle} from '@material-ui/core';
import React, {useMemo} from 'react';

import {useStyles} from './LoginSignupModal';

type Props = {
  usePasswordContainer: boolean;
  selectedTab: number;
}

export const LoginModalTitle: React.FC<Props> = ({usePasswordContainer, selectedTab}) => {
  const classes = useStyles();

  const modalTitle = useMemo(() => {
    return usePasswordContainer
      ? selectedTab === 0
        ? 'Login to app'
        : 'Register new account'
      : 'Select login type';
  }, [selectedTab, usePasswordContainer]);


  return (
    <DialogTitle className={classes.title}>
      {modalTitle}
    </DialogTitle>
  );
};
