import moment, {Moment} from 'moment';
import {toJS} from 'mobx';

import {Feature} from '@turf/turf';

import {ItemForecastInterface} from '../../interfaces/weather/ForecastWeatherInterface';
import {ForecastByDay} from '../../interfaces/weather/ForecastByDay';
import {DateFormat} from '../components/DateDisplay/DateDisplay';
import {PointInterface} from '../../interfaces/PointInterface';

import Log from './Log';

const isForecastBetween = ({dt_txt}) => arrivingTime => {
  const rangeEnd = moment(dt_txt)
    .add(2, 'h')
    .add(59, 'm');
  return moment(arrivingTime).isBetween(dt_txt, rangeEnd);
};

export const groupForecastByDay = (forecast: ItemForecastInterface[]): ForecastByDay => {
  if (!forecast || forecast.length === 0) {
    return {};
  }
  const forecastList = {};
  const today = moment().startOf('day');

  const formatDate = date => date.format(DateFormat.DefaultDate);

  for (let i = 0; i < forecast.length; i++) {
    const dateKey = moment(forecast[i].dt_txt);

    if (dateKey.isAfter(today)) {
      if (!forecastList[formatDate(dateKey)]) {
        forecastList[formatDate(dateKey)] = [];
      }

      forecastList[formatDate(dateKey)].push(forecast[i]);
    }
  }

  return forecastList;
};

export const getWeatherIconFromForecast = (forecast: ItemForecastInterface): string | undefined =>
  forecast && forecast.weather ? forecast.weather[0].icon : undefined;

export const getWeatherTempFromForecast = (forecast: ItemForecastInterface): number | undefined =>
  forecast && forecast.main ? Math.round(forecast.main.temp) : undefined;

export const calculateForecastIndex = (startDate: Moment, forecast: ItemForecastInterface[]): number | undefined => {
  const forecastIndex = toJS(forecast)
    .findIndex((forecast) => isForecastBetween(forecast)(startDate));

  if (!forecastIndex) {
    Log.warn(`Forecast for selected date ${startDate.format(DateFormat.shortDate)} not found`);
  }

  return forecastIndex;
};

export const getForecastData = (point: Feature<PointInterface>): {temp: number | undefined, icon: string | undefined} | undefined => {
  if (point?.properties?.forecast) {
    const selectedForecast = point.properties.forecast[point.properties.forecastIndex];
    return {
      temp: getWeatherTempFromForecast(selectedForecast),
      icon: getWeatherIconFromForecast(selectedForecast),
    };
  }
};

export function selectForecast(selectedIndex: number, forecast: ItemForecastInterface[]): void {
  if (!forecast) {
    return;
  }

  const index = forecast.findIndex(forecast => forecast?.selected);

  if (index >= 0 && forecast[selectedIndex]) {
    forecast[selectedIndex].selected = false;
  }

  if (forecast[selectedIndex]) {
    forecast[selectedIndex].selected = true;
  }
}
