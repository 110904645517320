import React, {useContext} from 'react';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import UserNick from '../UserNick/UserNick';
import {DrawersContext} from '../../../providers/DrawersProvider';
import {useLogoutListener} from '../../../hooks/useLogoutListener';

import ListDrawer from './listDrawer';
import {CloseDrawer} from './CloseDrawer';

const drawerWidth = '240px';

const MainDrawer: React.FC = () => {
  const {mainDrawerState, toggleMainDrawer, hideMainDrawer} = useContext(DrawersContext);
  useLogoutListener(hideMainDrawer);

  return (
    <Drawer
      anchor="right"
      open={mainDrawerState}
      onClose={toggleMainDrawer}
    >
      <div style={{width: drawerWidth}}>
        <AppBar position="static">
          <Toolbar>
            <CloseDrawer
              click={toggleMainDrawer}
            />
            <UserNick/>
          </Toolbar>
        </AppBar>
        <ListDrawer/>
      </div>
    </Drawer>
  );
};

export default MainDrawer;
