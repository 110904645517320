import {CurrentWeatherInterface} from 'interfaces/weather/CurrentWeatherInterface';

import {http} from '../functions/HttpAuth';
import {ForecastWeatherInterface} from '../../interfaces/weather/ForecastWeatherInterface';

export const forecastRequests = {
  currentWeather: (lat: number, lng: number): string => `/api/weather/${lat}/${lng}`,
  forecast: (lat: number, lng: number): string => `/api/weather/forecast/${lat}/${lng}`,
  postWeatherPoints: (): string => '/api/route/weather-old-points',
};

export const getForecast = (coordinates: number[]): Promise<ForecastWeatherInterface> =>
  http.get(forecastRequests.forecast(coordinates[1],coordinates[0]));

export const getCurrentWeather = (coordinates: number[]): Promise<CurrentWeatherInterface> => http.get(forecastRequests.currentWeather(coordinates[1], coordinates[0]));

export const postWeatherPoints = (weatherPoints): Promise<any> => http.post(forecastRequests.postWeatherPoints(), weatherPoints);
