import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {IconButton} from '@material-ui/core';
import {useToggle} from 'hooks/useToggle';

import Slide from '@material-ui/core/Slide';

import {PointInterface, PointProperties} from 'interfaces/PointInterface';

import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import {Feature} from '@turf/turf';

import {useRootStore} from 'shared/stores/RootStore';

import SearchAddressItem from '../../StartRouteBlock/SearchAddressItem';
import {useStyles} from '../WaypointList';
import {WayPointsListContext} from '../WayPointsListContextWrapper';

type Props = {
  point: Feature<PointInterface>
}

export const PointItemBottomAction: React.FC<Props> = ({point}) => {
  const classes = useStyles();
  const {wayPointsStore} = useRootStore();
  const [itemSecondaryActionState, {off: hide, toggle: toggleSecondaryItemState}] = useToggle(false);
  const {setWayPointsIdSelected, wayPointsIdSelected} = useContext(WayPointsListContext);
  const {id} = point?.properties as PointProperties;

  const isOnlyOnePoint = useMemo(() => {
    return wayPointsStore.wayPointsCount === 1;
  }, [wayPointsStore.wayPointsCount]);

  useEffect(() => {
    if (wayPointsIdSelected && (wayPointsIdSelected !== id) && itemSecondaryActionState) {
      hide();
    }
  }, [hide, id, itemSecondaryActionState, wayPointsIdSelected]);

  const onSecondaryItemClick = useCallback(() => {
    setWayPointsIdSelected(id as string);
    toggleSecondaryItemState();
    if (itemSecondaryActionState) {
      setWayPointsIdSelected(undefined);
    }
  }, [id, itemSecondaryActionState, setWayPointsIdSelected, toggleSecondaryItemState]);

  return (
    <>
      {!isOnlyOnePoint &&
			<div className={classes.listItemBottomRow}>
				<IconButton
					size='small'
					color='secondary'
					onClick={onSecondaryItemClick}
				>
          {
            itemSecondaryActionState
              ? <KeyboardArrowUp/>
              : <KeyboardArrowDown/>
          }
				</IconButton>
			</div>
      }
      {itemSecondaryActionState &&
			<div style={{overflow: 'hidden'}}>
				<Slide
					direction='down'
					timeout={200}
					in={itemSecondaryActionState}
				>
					<div>
						<SearchAddressItem
							onClick={onSecondaryItemClick}
						/>
					</div>
				</Slide>
			</div>
      }
    </>
  );
};
