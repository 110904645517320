import React from 'react';
import {observer} from 'mobx-react';
import classnames from 'classnames';
import grey from '@material-ui/core/es/colors/grey';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import {Theme, withStyles} from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';


import {InjectedStylesInterface, StyleType} from 'interfaces/Styles';

import HomeIcon from '@material-ui/icons/Home';
import TankPoint from '@material-ui/icons/Opacity';
import FlagIcon from '@material-ui/icons/Flag';

import {PointType} from 'shared/const/wayPointType';
import {Spacing} from 'shared/const/spacing';

const styles: StyleType = (theme: Theme) => ({
  fabProgress: {
    position: 'absolute',
    zIndex: 1,
  },
  wrapper: {
    height: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
      width: '100%',
      position: 'relative',
    },
    marginRight: Spacing + 'px'
  },
  homePoint: {
    color: theme.palette.primary.dark,
  },
  wayPoint: {
    backgroundColor: blue.A700,
    color: grey.A100,
  },
  tankPoint: {
    backgroundColor: orange.A400,
    color: theme.palette.common.white,
  },
  avatar: {
    [theme.breakpoints.down('xs')]: {
      width: '28px',
      height: '28px',
    },
  },
  badge: {
    '&>span': {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  },
});
export const selectAvatarIcon = (pointType: PointType) => {
  switch (pointType) {
    case PointType.homePoint:
      return <HomeIcon/>;
    case PointType.fuelPoint:
      return <TankPoint/>;
    case PointType.newWayPoint:
    default:
      return <FlagIcon/>;
  }
};

interface AvatarIconProps {
  loading?: boolean;
  pointType?: PointType;
  onClick: () => void;
}

interface InjectedProps extends AvatarIconProps, InjectedStylesInterface {

}

const AvatarIcon: React.FC<AvatarIconProps> = (props) => {
  const {pointType, loading} = props;
  const {classes} = props as InjectedProps;
  return (
    <div className={classes.wrapper}>
      {loading && <CircularProgress size={48} className={classes.fabProgress}/>}
      <Avatar
        className={classnames({
          [classes.avatar]: true,
          [classes.homePoint]: pointType === PointType.homePoint,
          [classes.wayPoint]: pointType === PointType.newWayPoint,
          [classes.tankPoint]: pointType === PointType.tankPoint,
        })}
      >
        {selectAvatarIcon(pointType as PointType)}
      </Avatar>
    </div>
  );
};

const AvatarWithStyles = withStyles(styles)(AvatarIcon);

const displayBadge = pointType => pointType === (PointType.newWayPoint || PointType.homePoint);

interface AwatarWayPointProps {
  pointType?: PointType;
  index: number;
  loading?: boolean;
  onClick?: () => void;
}

interface InjectedAwatarWayPoint extends AwatarWayPointProps, InjectedStylesInterface {
}

export const AvatarWayPoint: React.FC<AwatarWayPointProps> = (props) => {
  const {pointType, index, loading, onClick} = props;
  const {classes} = props as InjectedAwatarWayPoint;
  return (
    <Grow in={true}>
      {displayBadge(pointType) ? (
        <Badge
          badgeContent={index + 1}
          color="primary"
          className={classnames([classes.badge])}
        >
          <AvatarWithStyles pointType={pointType} loading={loading} onClick={onClick ? onClick : () => void (0)}/>
        </Badge>
      ) : (
        <AvatarWithStyles pointType={pointType} onClick={onClick ? onClick : () => void (0)}/>
      )}
    </Grow>
  );
};

export default withStyles(styles)(observer(AvatarWayPoint));
