import * as Yup from 'yup';

export const loginValidator = Yup.string()
  .required()
  .email()
  .min(5)
  .max(50);

export const passwordValidator = Yup.string()
  .required()
  .min(5)
  .max(15);

export const loginFormValidator = Yup.object()
  .shape({
    email: loginValidator,
    password: passwordValidator,
  });
