import {Position} from '@turf/turf';

import {catchError, http} from '../functions/HttpAuth';

export const getAddressByCoords = (coordinates: Position): Promise<any> => {
  const [lat, lng] = coordinates;
  return http.get(`/api/address/${lat}/${lng}`).catch(catchError);
};

export const lookupAddress = (addressQuery: string): Promise<any> => http.post('/api/geocoder/search', {addressQuery}).catch(catchError);
