export enum RouteCalculationType {
  Initial = 'initialCalculationFor2Points',
  AddRouteAtTheEnd = 'AddRouteAtTheEnd',
  AddRouteAtTheBeginning = 'AddRouteAtTheBeginning'
}

export interface RoutesInterface {
  uuid: string;
  routes: RouteInterface[];
}

export interface RouteInterface {
  type: string,
  properties: RoutePropertiesInterface,
  geometry: {
    type: string,
    coordinates: number[][];
  }
}

export interface LegsInterface {
  annotation: {
    duration: DurationType;
  },
  summary: string;
  steps: any[];
  distance: number;
  duration: number;
  weight: number;
}

export interface RoutePropertiesInterface {
  distance: number;
  duration: number;
  legs: LegsInterface[]
}

export type DurationType = number[];
