import React from 'react';
import {withRouter} from 'react-router';
import {useToggle} from 'hooks/useToggle';

import IconButton from '@material-ui/core/IconButton';

import {UserMapsInterface} from 'interfaces/UserMaps';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import ConfirmDialog from 'shared/components/ConfirmDialog/ConfirmDialog';
import {useRootStore} from 'shared/stores/RootStore';

import MapTitle from '../MapTitle';
import {MAP_LINK} from '../../../../shared/const/NavigationLinks';

interface Props {
  item: UserMapsInterface;
  history: {
    push: (url: string) => void;
  }
}

const MapActions: React.FC<Props> = ({item, history}) => {
  const [deleteModalState, {on: showDeleteModal, off: hideDeleteModal}] = useToggle(false);
  const {userMapsStore} = useRootStore();

  const removeMap = () => {
    userMapsStore.deleteUserMap(item._id as string);
    hideDeleteModal();
  };

  const editClick = () => {
    userMapsStore.loadMap(item as UserMapsInterface);
    history.push(MAP_LINK);
  };

  return (
    <React.Fragment>
      <IconButton onClick={editClick}>
        <EditIcon/>
      </IconButton>
      <IconButton onClick={showDeleteModal}>
        <DeleteIcon/>
      </IconButton>
      <ConfirmDialog
        title={'Are you sure you want to delete this map?'}
        open={deleteModalState}
        onClose={hideDeleteModal}
        onConfirm={removeMap}
      >
        <>
          <MapTitle {...item} count={4}/>
        </>
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default withRouter(MapActions);
