import React, {useContext} from 'react';

import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';

import {AuthDisplay} from '../AuthDisplay/AuthDisplay';
import {DrawersContext} from '../../../providers/DrawersProvider';

const MainMenuButtonBar: React.FC = () => {
  const {toggleMainDrawer} = useContext(DrawersContext);
  return (
    <AuthDisplay>
      <IconButton onClick={() => toggleMainDrawer()}>
        <MenuIcon/>
      </IconButton>
    </AuthDisplay>
  );
};

export default MainMenuButtonBar;
