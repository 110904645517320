import React, {useCallback, useMemo} from 'react';

import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';

import {CircleButton} from 'shared/components/buttons/circle/CircleButton';

import {useZoomIn, useZoomOut} from '../mapHooks/zoomMapHook';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: 'fixed',
    right: '10px',
    bottom: '30px',
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.common.white,
  },
}));

type Props = {
  zoom: number;
}
const MapZoomControl: React.FC<Props> = ({zoom}) => {
  const classes = useStyles();
  const increaseZoom = useZoomIn();
  const decreaseZoom = useZoomOut();

  const zoomIn = useCallback(() => {
    decreaseZoom();
  }, [decreaseZoom]);

  const zoomOut = useCallback(() => {
    increaseZoom();
  }, [increaseZoom]);

  const zoomValue = useMemo(() => {
    return Math.ceil(zoom);
  }, [zoom]);

  return (
    <div className={classes.container}>
      <CircleButton onClick={zoomOut} color="primary">
        <PlusIcon/>
      </CircleButton>
      <div>
        <Typography align="center">{zoomValue}</Typography>
      </div>
      <CircleButton onClick={zoomIn} color="primary">
        <MinusIcon/>
      </CircleButton>
    </div>
  );
};

export default MapZoomControl;
