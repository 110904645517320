export const add = (key, item) => {
	if (typeof localStorage === 'undefined') {
		return;
	}
	// eslint-disable-next-line no-undef
	localStorage.setItem(key, JSON.stringify(item));
};

export const get = key => {
	try {
		// eslint-disable-next-line no-undef
		return JSON.parse(localStorage.getItem(key));
	} catch (err) {
		return undefined;
	}
};

export const removeKey = key => {
	// eslint-disable-next-line no-undef
	localStorage.removeItem(key);
};
