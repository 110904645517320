import {action, computed, makeObservable, observable, toJS} from 'mobx';

import {Feature} from '@turf/turf';

import {RootStoreInterface} from '../../interfaces/stores/RootStoreInterface';
import {PointInterface, PointProperties} from '../../interfaces/PointInterface';
import {addFavoritePlace, deletePlace, getFavorites, setAsHomePoint} from '../requests/favorite.requests';
import {getForecast} from '../requests/weather.request';
import {dateToString} from '../utils/timeUtils';

export interface FavoritePlacesInterface {
  _id: string;
  point: Feature<PointInterface>
  defaultStartingPoint?: boolean
}

class FavoritePlacesStore {
  private readonly rootStore: RootStoreInterface<any>;
  _favoritesPlaces: FavoritePlacesInterface[] = [];
  _selectedPlace: number | undefined;
  _isFetching = false;

  constructor(rootStore: RootStoreInterface<any>) {
    this.rootStore = rootStore;
    makeObservable(this, {
      _favoritesPlaces: observable,
      _selectedPlace: observable,
      _isFetching: observable,
      favoritesPlaces: computed,
      homePoint: computed,
      selectedPlace: observable,
      addNewPlace: action,
      fetchPlaces: action,
      updatePointForecast: action,
      removePlace: action,
      setSelectedPlace: action,
      setAsHome: action,
    });
  }

  async fetchPlaces() {
    this._isFetching = true;
    this._favoritesPlaces = await getFavorites();

    if (!this._selectedPlace && this._favoritesPlaces.length > 0) {
      action(() => {
        this._selectedPlace = 0;
        this._isFetching = false;
      });
    }
  }

  selectedPlace() {
    return this._selectedPlace;
  }

  get favoritesPlaces(): FavoritePlacesInterface[] {
    return this._favoritesPlaces;
  }

  get homePoint() {
    return this._favoritesPlaces.find(point => point.defaultStartingPoint);
  }

  addNewPlace(point: Feature<PointInterface>) {
    (point.properties as PointProperties).forecastLastUpdateDate = dateToString();
    const isDefault = this._favoritesPlaces.length === 0;
    addFavoritePlace(point, isDefault)
      .then(action((response: FavoritePlacesInterface) => {
        this._favoritesPlaces.push(response);
      }));
  }

  updatePointForecast(_id: string) {
    const favoritePoint = this.findPoint(_id);
    if (!favoritePoint) {
      return;
    }

    getForecast(favoritePoint.point.geometry?.coordinates as number[])
      .then(
        (forecast) => {
          (favoritePoint.point.properties as PointProperties).forecast = forecast.list;
          (favoritePoint.point.properties as PointProperties).forecastLastUpdateDate = dateToString();
        },
      );
  }

  removePlace(_id: string) {
    const point = toJS(this.findPoint(_id));

    this._favoritesPlaces = this._favoritesPlaces
      .filter((favoritePoint) => favoritePoint._id !== _id);

    deletePlace(_id)
      .then(() => {
        if (point && point.defaultStartingPoint && this._favoritesPlaces.length > 0) {
          this._favoritesPlaces[0].defaultStartingPoint = true;
          this.setAsHome(this._favoritesPlaces[0]._id);
        }
      });
  }

  setSelectedPlace(_id: string) {
    const index = this._favoritesPlaces
      .findIndex((favoritePoint) => favoritePoint._id === _id);
    this._selectedPlace = (index !== -1) ? index : undefined;
  }

  setAsHome(_id: string) {
    if (this.findPoint(_id)) {
      setAsHomePoint(_id)
        .then(() => {
          this._favoritesPlaces.forEach(place => {
            place.defaultStartingPoint = place._id === _id;
          });
        });
    }
  }

  private findPoint(_id: string) {
    return this._favoritesPlaces
      .find((favoritePoint) => favoritePoint._id === _id);
  }
}

export default FavoritePlacesStore;
