import {makeStyles} from '@material-ui/core';

import {Theme} from '@material-ui/core/styles';

import {fullWidth} from '../../../shared/const/stylesDef';

export const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    flex: 2,
  },
  imgWrapper: {
    marginTop: 8,
  },
  card: {
    margin: 8 * 2,
    ...fullWidth,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
  },
  cardActions: {
    flex: 1,
    backgroundColor: theme.palette.grey['100'],
    justifyContent: 'space-between',
  },
  description: {
    marginTop: 8,
    '&>p': {
      marginTop: 8,
    },
  },
}));
