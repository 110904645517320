import {action, observable, runInAction} from 'mobx';
import moment from 'moment';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';

import {fetchLangList, fetchTranslations} from '../requests/applanga.resource';
import {add, get} from '../functions/Storage';
import Log from '../utils/Log';

export default class TranslationStore {
  private readonly rootStore: RootStoreInterface<any>;
  private readonly defaultLang = 'gb';

  constructor(rootStore: RootStoreInterface<any>) {
    this.rootStore = rootStore;
    this.getStorageLang();
    this.getLangList();
    moment.locale(this.lang);
  }

  @observable
  translations = {};

  @observable
  langs: string[] = [];

  @observable
  public lang = this.defaultLang;

  getStorageLang = () => {
    const currentLang = get('lang');
    if (currentLang) {
      this.lang = currentLang;
    } else {
      this.lang = this.defaultLang;
    }
  };

  setStorageLang = (): void => {
    add('lang', this.lang);
  };

  @action
  setCurrentLang(lang: string) {
    if (this.langs.includes(lang)) {
      Log.info(`Change language from ${this.lang} to ${lang}`);
      this.lang = lang;
      moment.locale(lang);
      this.setStorageLang();
    } else {
      Log.error(`Languages doesn't includes ${lang}`);
    }
  }

  @action
  async getLangList() {
    try {
      const data = await fetchLangList();
      this.langs = Object.keys(data);
      this.setTranslationsData(data);
    } catch (err) {
      Log.error(err);
    }
  }

  @action
  getTranslations(lang) {
    fetchTranslations(lang || this.lang).then(this.setTranslationsData);
  }


  setTranslationsData = data => {
    runInAction(() => {
      if (data.translations) {
        this.translations = data.translations;
      }

      if (data.langList) {
        this.langs = data.langList;
      }
    });
  };
}
