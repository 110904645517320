import React, {useCallback, useState} from 'react';
import {useToggle} from 'hooks/useToggle';

import {PointInterface} from 'interfaces/PointInterface';

import {Feature} from '@turf/turf';

import {PointType} from 'shared/const/wayPointType';

import {MapPointCoords} from '../index';
import {doNothing} from '../../../shared/utils/functionsUtils';

const initialPoindData = {
  pointType: undefined,
  contextMenuOpen: false,
  openContextMenu: doNothing,
  closeContextMenu: doNothing,
  contextMenuCoords: undefined,
  setContextMenuCoords: doNothing,
  setPointCoords: doNothing,
  clearPointType: doNothing,
  selectedPointIndex: undefined,
  setSelectedPointIndex: doNothing,
  openConfirmDeleteDialog: false,
  showConfirmDialog: doNothing,
  hideDeleteDialog: doNothing,
  selectedPoint: undefined,
  pointModalVisible: false,
  showPointModalChooser: doNothing,
  hidePointModalChooser: doNothing,
  tempPoint: undefined,
  setTempPoint: doNothing,
  routePointDragging: false,
  routePointDraggingStart: doNothing,
  routePointDraggingStop: doNothing,
};

interface PointProps {
  pointType?: PointType;
  contextMenuOpen: boolean;
  openContextMenu: (pointType: PointType) => void;
  closeContextMenu: () => void;
  clearPointType: () => void;
  contextMenuCoords: MapPointCoords | undefined;
  setContextMenuCoords: (position: MapPointCoords) => void;
  setPointCoords: (position: MapPointCoords, pointType?: PointType) => void;
  selectedPointIndex: number | undefined;
  setSelectedPointIndex: (index?: number) => void;
  openConfirmDeleteDialog: boolean;
  showConfirmDialog: (wayPoint: Feature<PointInterface>) => void;
  hideDeleteDialog: () => void;
  selectedPoint: Feature<PointInterface> | undefined;
  pointModalVisible: boolean;
  showPointModalChooser: () => void;
  hidePointModalChooser: () => void;
  tempPoint?: Feature<PointInterface>;
  setTempPoint: (point: Feature<PointInterface>) => void;
  routePointDragging: boolean;
  routePointDraggingStart: () => void;
  routePointDraggingStop: () => void;
}


export const PointContext = React.createContext<PointProps>(initialPoindData);

export const MapPointProvider = ({children}) => {
  const [contextMenuOpen, setContextMenuState] = useState<boolean>(initialPoindData.contextMenuOpen);
  const [pointModalVisible, {
    on: showPointModalChooser,
    off: hidePointModalChooser,
  }] = useToggle(initialPoindData.pointModalVisible);
  const [contextMenuCoords, setContextMenuCoords] = useState<MapPointCoords>();
  const [tempPoint, setTempPoint] = useState<Feature<PointInterface>>();
  const [pointType, setPointType] = useState<PointType | undefined>(undefined);
  const [selectedPointIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const [selectedPoint, setSelectedPoint] = useState<Feature<PointInterface> | undefined>(undefined);
  const [openConfirmDeleteDialog, {on: showDeleteDialog, off: hideDeleteDialog}] = useToggle(false);
  const [routePointDragging, {on: routePointDraggingStart, off: routePointDraggingStop}] = useToggle(false);

  const openContextMenu = (pointType: PointType) => {
    setPointType(pointType);
    setContextMenuState(true);
  };

  const closeContextMenu = () => {
    setContextMenuState(false);
  };

  const setPointCoords = useCallback((position: MapPointCoords) => {
    setContextMenuCoords(position);
  }, []);

  const clearPointType = () => {
    setPointType(undefined);
  };

  const setSelectedPointIndex = index => {
    setSelectedIndex(index);
  };

  const showConfirmDialog = useCallback((wayPoint) => {
    setSelectedPoint(wayPoint);
    showDeleteDialog();
  }, [showDeleteDialog]);

  return <PointContext.Provider value={{
    selectedPoint,
    openConfirmDeleteDialog,
    showConfirmDialog,
    hideDeleteDialog,
    selectedPointIndex,
    setSelectedPointIndex,
    setPointCoords,
    pointType,
    clearPointType,
    contextMenuOpen,
    openContextMenu,
    closeContextMenu,
    contextMenuCoords,
    setContextMenuCoords,
    pointModalVisible,
    showPointModalChooser,
    hidePointModalChooser,
    tempPoint,
    setTempPoint,
    routePointDragging,
    routePointDraggingStart,
    routePointDraggingStop,
  }}>
    {children}
  </PointContext.Provider>;
};
