import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Button, DialogActions, DialogContent} from '@material-ui/core';
import moment from 'moment';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import {useToggle} from '../../../../hooks/useToggle';
import AddressDisplay from '../../Address/AddressDisplay';
import {AddressInfoInterface} from '../../../../interfaces/address/AddressInfo';
import {ItemForecastInterface} from '../../../../interfaces/weather/ForecastWeatherInterface';
import {ForecastByDayType, useForecastByDay} from '../../../../hooks/useForecastByDay';
import ConditionalWrapper from '../../ConditionalWrapper/ConditionalWrapper';
import MapEvents, {clearMapEvent} from '../../../../pages/maps/MapEvents';
import {mapEventType} from '../../../const/mapEvents';
import {PointInterface, PointProperties} from '../../../../interfaces/PointInterface';
import {doNothing} from '../../../utils/functionsUtils';
import {DateFormat} from '../../DateDisplay/DateDisplay';

import ForecastDaysButtons from './ForecastDaysButtons';
import {WeatherForecastList} from './WeatherForecastList';

interface Props {
  usePointContext: boolean;
  fullScreen?: boolean;
}

interface ModalContextInterface {
  open: boolean,
  modalData?: ModalDataInterface;
  hideModal: () => void;
  openModal: (modalData: ModalDataInterface) => void;
}

const initialData = {
  open: false,
  modalData: undefined,
  days: [],
  hideModal: doNothing,
  openModal: doNothing,
};

type ModalDataInterface = {
  addressInfo: AddressInfoInterface | undefined;
  forecastByDay?: ForecastByDayType;
  initialDay: string,
  days?: string[];
};

export const WeatherModalContext = React.createContext<ModalContextInterface>(initialData);

export const WeatherModalWrapper: React.FC = ({children}) => {
  const [modalState, {on: showModal, off: hideModal}] = useToggle(false);
  const [modalData, setModalData] = useState<ModalDataInterface | undefined>();
  const groupForecast = useForecastByDay();

  const openModal = useCallback(
    (modalData: PointProperties) => {
      const {forecastByDay, days} = groupForecast(modalData.forecast as ItemForecastInterface[]);

      const getInitialDay = () => {
        const date = modalData?.forecast && modalData.forecast[modalData.forecastIndex ?? 0].dt_txt;
        return moment(date).format(DateFormat.DefaultDate);
      };

      setModalData({
        addressInfo: modalData.addressInfo,
        forecastByDay,
        days,
        initialDay: getInitialDay()
      });
      showModal();
    },
    [groupForecast, showModal],
  );

  useEffect(() => {
      MapEvents.listen(mapEventType.SelectWeatherPoint, (e) => {
        const {point} = e.detail;
        openModal(((point as PointInterface).properties as PointProperties));
      });
      return MapEvents.stopListening(mapEventType.SelectWeatherPoint, hideModal);
    }
    , [hideModal, openModal],
  );

  return (
    <WeatherModalContext.Provider
      value={{
        open: modalState,
        modalData,
        openModal,
        hideModal,
      }}
    >
      {children}
    </WeatherModalContext.Provider>
  );
};

const WeatherMapPointModal: React.FC<Props> = (props) => {
  const {open, hideModal, modalData} = useContext(WeatherModalContext);
  const initialDay = modalData?.days?.indexOf(modalData?.initialDay) ?? 0;
  const [selectedDay, setSelectedDay] = useState<number>(initialDay);

  useEffect(() => {
    open && setSelectedDay(initialDay);
  }, [initialDay, open]);

  const onSelectDay = useCallback((day: number) => {
    setSelectedDay(day);
  }, []);

  const selectedDayForecast = useMemo(() => {
    if (open && modalData?.forecastByDay) {
      return Array.from(modalData.forecastByDay.entries())[selectedDay][1];
    }
  }, [modalData, open, selectedDay]);

  const closeModal = useCallback(() => {
    hideModal();
    setSelectedDay(initialDay);
    clearMapEvent();
  }, [hideModal, initialDay]);

  return (
    <Dialog
      maxWidth='md'
      fullScreen={props.fullScreen}
      scroll="paper"
      onClose={closeModal}
      open={open}
    >
      <DialogTitle>
        Forecast
        <AddressDisplay addressInfo={modalData?.addressInfo} count={4}/>
      </DialogTitle>
      <DialogContent>
        <ConditionalWrapper condition={open}>
          <>
            {selectedDayForecast &&
						<WeatherForecastList
							list={selectedDayForecast as ItemForecastInterface[]}
						/>}
          </>
        </ConditionalWrapper>
      </DialogContent>
      <DialogActions>
        <ForecastDaysButtons
          days={modalData?.days}
          onSelectDay={onSelectDay}
          selectedIndex={selectedDay}
        />
        <Button onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WeatherMapPointModal;
