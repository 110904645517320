export const flexContainer = {
  display: 'flex',
};

export const positionFixed = {
  position: 'fixed',
};

const centerStyle = {
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
};

export const flexContainerCenter = {
  ...flexContainer,
  ...centerStyle,
};

export const flexContainerCenterRow = {
  ...flexContainer,
  ...centerStyle,
};

export const flexContainerCenterColumn = {
  ...flexContainer,
  ...centerStyle,
  'flex-direction': 'column',
};

export const flexContainerStartRow = {
  ...flexContainer,
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const flexContainerStartColumn = {
  ...flexContainer,
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

export const fullWidth = {
  width: '100%',
};

export const fullHeight = {
  height: '100%',
};
