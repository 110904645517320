import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

const styles = () => ({
  root: {
    marginTop: 10 * 7,
    flex: 1,
  },
});

const ContentWrapper = ({children, classes}) => (
  <Grid
    className={classes.root}
    container
    direction="row"
    justify="center"
    alignItems="stretch"
  >
    <Grid item xs={12} lg={8}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(ContentWrapper);
