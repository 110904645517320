import React from 'react';

import Typography from '@material-ui/core/Typography';

import {formatDurationTime} from '../../functions/formaDurationTime';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

interface Props {
  duration?: number;
  roundTime?: boolean;
}

const Duration: React.FC<Props> = (props) => {
  const {duration = 0, roundTime = false} = props;
  return (
    <ConditionalWrapper condition={duration > 0}>
      <Typography noWrap>{formatDurationTime(duration, roundTime)}</Typography>
    </ConditionalWrapper>
  );
};

export default Duration;
