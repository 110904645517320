import {AnyShapeCoordinates} from 'react-mapbox-gl/lib/util/types';
import {Feature, Layer} from 'react-mapbox-gl';
import React, {useCallback} from 'react';

import Log from '../../../shared/utils/Log';
import {useRootStore} from '../../../shared/stores/RootStore';

import {home} from './LayersIcons';
import {useMapBoxTheme} from './LayerPointStyle';

interface Props {
  id: string;
}

type FeatureProps = {
  onDragEnd: (any) => void;
  onMouseEnter: (any) => void;
  onClick: (any) => void;
  coordinates: AnyShapeCoordinates;
}

const FeatureLayer: React.FC<FeatureProps> = ({onDragEnd, onMouseEnter, coordinates}) =>
  <Feature
    key="home-feature"
    onDragEnd={onDragEnd}
    onMouseEnter={onMouseEnter}
    coordinates={coordinates}
    draggable={true}
  />;


export const HomeLayer: React.FC<Props> = (props) => {
  const mapBoxTheme = useMapBoxTheme();
  const {favoritePlacesStore} = useRootStore();

  const onDragEnd = useCallback(event => {
    Log.info(event);
  }, []);

  const onMouseEnter = useCallback(event => {
    Log.info(event);
  }, []);

  const onClick = useCallback(() => {
    document.dispatchEvent(new CustomEvent('mapEvent', {bubbles: false, detail: 'some fucking cool data'}));
  }, []);

  const coords = favoritePlacesStore.homePoint?.point.geometry?.coordinates ?? [];

  return (
    <Layer
      id={props.id}
      type="symbol"
      layout={{
        'icon-image': 'home-icon',
        'icon-size': 1,
      }}
      paint={mapBoxTheme.paintHomeIcon}
      images={home}
    >
      <FeatureLayer
        onDragEnd={onDragEnd}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        coordinates={coords}
      />
    </Layer>
  );
};
