import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { InjectedStylesInterface } from 'interfaces/Styles';

import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

import UserDefaultIcon from './UserDefaultIcon';

interface Props {
  click?: () => void;
  size?: string;
  avatarUrl?: string;
}

interface InjectedProps extends Props, InjectedStylesInterface {}

const AvatarProfileComponent: React.FC<Props> = (props) => {
	const { click, size } = props;
	const { classes } = props as InjectedProps;

	return (
		<ConditionalWrapper
			condition={!!props.avatarUrl}
			skeleton={<UserDefaultIcon />}
		>
			<Avatar
				src={props.avatarUrl}
				onClick={click}
				className={size ? classes[size] : classes.big}
			/>
		</ConditionalWrapper>
	);
};

const smallDimensions = '40px';
const bigDimensions = '150px';
const styles = {
	big: {
		height: bigDimensions,
		margin: 0,
		width: bigDimensions
	},
	small: {
		height: smallDimensions,
		margin: 0,
		width: smallDimensions
	},
};

export default React.memo(withStyles(styles)(AvatarProfileComponent));
