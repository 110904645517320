import React, {useContext} from 'react';
import {createStyles, makeStyles, Slide, Typography} from '@material-ui/core';
import {observer} from 'mobx-react-lite';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {TransitionProps} from '@material-ui/core/transitions';
import DialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/CloseOutlined';

import {SearchAddressContext} from 'shared/components/Address/SearchAddressProvider';
import AddressLookup from 'shared/components/AddressLookup/AddressLookup';
import {useRootStore} from 'shared/stores/RootStore';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      padding: '8px 24px 8px 24px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    scrollPaper: {
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
  }));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});


const ModalAddressLookup: React.FC = () => {
  const {visible, hide, options} = useContext(SearchAddressContext);
  const {fuelStationsStore, addressStore} = useRootStore();
  const classes = useStyles();

  const onModalClose = () => {
    hide();
    fuelStationsStore.clearAll();
  };

  return (
    <Dialog
      open={visible}
      draggable
      maxWidth='sm'
      fullWidth
      classes={{
        scrollPaper: classes.scrollPaper,
      }}
      TransitionComponent={Transition}
    >
      <div className={classes.title}>
        <div>
          <Typography variant={'h6'}>{options?.label ?? 'Search'}</Typography>
        </div>
        <div>
          <IconButton onClick={onModalClose} disableRipple>
            <CloseIcon/>
          </IconButton>
        </div>
      </div>
      {addressStore.loadingAddresses && <LinearProgress color="primary"/>}
      <DialogContent>
        <AddressLookup/>
      </DialogContent>
      <DialogActions>
        <Button onClick={onModalClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(ModalAddressLookup);
