import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ClearMapButton from './ClearMapButton';
import SaveMapButton from './SaveMapButton';
import SelectDateButton from './SelectDateButton';

const MapToolbar: React.FC = () => (
  <AppBar position="static">
    <Toolbar>
      <SaveMapButton/>
      <ClearMapButton/>
      <SelectDateButton/>
    </Toolbar>
  </AppBar>
);

export default MapToolbar;
