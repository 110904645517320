export const useTimer = (onTimeout: () => void, timeout: number): {onStartTimer: () => void, stopTimer: () => void} => {
  let timer;

  const onStartTimer = (): void => {
    timer = setTimeout(() => {
      onTimeout();
    }, timeout);
  };

  const stopTimer = (): void => {
    clearTimeout(timer);
  };

  return {onStartTimer, stopTimer};
};
