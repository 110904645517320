import React from 'react';
import {CardContent} from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';

import {UserMapsInterface} from 'interfaces/UserMaps';

import MapActions from './MapActions/MapActions';
import MapTitle from './MapTitle';
import RouteParams from './RouteParams';
import {useStyles} from './styles';
import {MapImage} from './MapImage';


interface Props {
  item: UserMapsInterface;
  path: string;
}

const MapCard: React.FC<Props> = ({item, path}) => {
  const {cardActions, card, cardHeader} = useStyles();

  return (
    <Card className={card}>
      <CardHeader
        className={cardHeader}
        title={<MapTitle {...item} />}
      />
      <CardContent>
        <MapImage path={path} imageFileName={item.imageFilename}/>
      </CardContent>
      <RouteParams params={item.source.routes}/>
      <CardActions className={cardActions}>
        <MapActions item={item}/>
      </CardActions>
    </Card>
  );
};


export default MapCard;
