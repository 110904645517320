import {action, computed, observable, runInAction} from 'mobx';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';

class UploadImagesStore {
  private readonly rootStore: RootStoreInterface<any>;

  @observable _openDialog;
  @observable _multiple = false;
  @observable _files;
  @observable _uploadStatus;
  @observable _uploadAction;
  @observable _arguments;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get openDialog() {
    return this._openDialog;
  }

  @action
  setOpenDialog(open) {
    this._openDialog = open;
  }

  @computed
  get multiple() {
    return this._multiple;
  }

  @action
  setMultiple(multiple) {
    this._multiple = multiple;
  }

  @computed
  get files() {
    return this._files;
  }

  @action
  setFiles(files) {
    this._files = files;
    this._uploadAction(this._files, this._arguments);
  }

  @computed
  get uploadStatus() {
    return this._uploadStatus;
  }

  @action
  setUploadStatus(status) {
    this._uploadStatus = status;
  }

  @action
  setUploadAction(actionName, args = []) {
    runInAction(() => {
      this._uploadAction = actionName;
      this._arguments = args;
    });
  }
}

export default UploadImagesStore;
