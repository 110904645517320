import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';

import Drawer from '@material-ui/core/Drawer';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {DrawersContext} from '../../../providers/DrawersProvider';

import {MapDrawerButtonSwitch} from './MapToolbar/MapDrawerButtonSwitch';
import WayPointsList from './WayPointsList/WaypointList';
import MapToolbar from './MapToolbar/MapToolbar';
import {RoutesInformation} from './WayPointsList/RoutesInformation/RoutesInformation';
import {WayPointsListContextWrapper} from './WayPointsList/WayPointsListContextWrapper';

export const drawerWidth = 320;
const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    flexShrink: 0,
    height: '100hh',
    flex: 1,
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  drawerPaper: {
    width: drawerWidth,
    flexDirection: 'column',
  },
}));

const MapDrawer: React.FC = observer(() => {
  const {mapDrawerState} = useContext(DrawersContext);
  const classes = useStyles();
  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={mapDrawerState}
        className={classes.drawer}
        classes={{paper: classes.drawerPaper}}
      >
        <MapToolbar/>
        <RoutesInformation/>
        <WayPointsListContextWrapper>
          <WayPointsList/>
        </WayPointsListContextWrapper>
      </Drawer>
      <MapDrawerButtonSwitch/>
    </>
  );
});

export default MapDrawer;
