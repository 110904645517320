import React from 'react';

import IconButton from '@material-ui/core/IconButton';

const ToolbarButton = ({isDisabled, onClick, children, ...rest}) => (
  <IconButton
    color="inherit"
    onClick={onClick}
    disabled={isDisabled}
    {...rest}
  >
    {children}
  </IconButton>
);

export default ToolbarButton;
