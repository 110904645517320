import React from 'react';
import {ListSubheader, Typography} from '@material-ui/core';

import List from '@material-ui/core/List';

import {ItemForecastInterface} from 'interfaces/weather/ForecastWeatherInterface';

import ForecastRow from '../ForecastRow/ForecastRow';
import DateDisplay from '../../DateDisplay/DateDisplay';

interface Props {
  list: ItemForecastInterface[];
  slideIndex?: string | number;
}

export const WeatherForecastList: React.FC<Props> = ({list}) => {
  return (
    <List>
      <ListSubheader style={{marginBottom: '20px', paddingLeft: 0}}>
        <Typography variant='h6'>
          <DateDisplay date={list[0].dt_txt}/>
        </Typography>
      </ListSubheader>
      {list.map((item: ItemForecastInterface, index: number) => (
        <ForecastRow key={`forecast-cell${index}`} {...item}/>
      ))}
    </List>
  );
};
