import React, {useCallback} from 'react';

import Button from '@material-ui/core/Button';

import DateDisplay, {DateFormat} from '../../DateDisplay/DateDisplay';

interface Props {
  days?: string[];
  onSelectDay?: (day: number) => void;
  selectedIndex?: number;
}

const ForecastDaysButtons: React.FC<Props> =
  ({
     selectedIndex,
     days = [],
     onSelectDay,
   }) => {

    const onClick = useCallback((day: number) => {
      onSelectDay && onSelectDay(day);
    }, [onSelectDay]);

    return (
      <>{
        days.map((day, index) => (
          <Button
            disabled={selectedIndex === index}
            key={index}
            onClick={() => onClick(index)}
            color={selectedIndex === index ? 'primary' : 'secondary'}
          >
            <DateDisplay date={day} format={DateFormat.DayNameShort}/>
          </Button>
        ))}
      </>
    );
  };


export default ForecastDaysButtons;
