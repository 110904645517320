import {Theme} from '@material-ui/core';

import {makeStyles} from '@material-ui/core/styles';

import {flexContainerCenter} from '../../../shared/const/stylesDef';

import {radius} from './mapContextMenu';

export const useStyles = makeStyles((theme: Theme) => ({
  centerDot: {
    height: radius * 2 + 'px',
    width: radius * 2 + 'px',
    borderRadius: radius + 'px',
    background: theme.palette.background.paper,
    borderColor: theme.palette.grey[400],
    borderStyle: 'solid',
    borderWidth: '1px',
    position: 'absolute',
    top: '-' + radius + 'px',
    left: '-' + radius + 'px',
    zIndex: theme.zIndex.modal,
    opacity: '1',
    flexDirection: 'row',
    ...flexContainerCenter,
  },
  timeLabel: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    position: 'absolute',
    bottom: radius * 2 + 20 + 'px',
    padding: '6px',
    borderRadius: '4px',
  },
  existingPoint: {
    backgroundColor: theme.palette.info.light,
  },
  pointOnRoute: {
    backgroundColor: theme.palette.info.main,
  },
  root: {},
  addressContainer: {
    height: radius * 1.65,
    width: radius * 1.65,
    background: theme.palette.grey[100],
    borderRadius: radius,
    '& p': {
      textAlign: 'center',
    },
    ...flexContainerCenter,
  },
}));
