import * as Yup from 'yup';

export const firstNameValidator = Yup.string()
  .min(0)
  .max(20);

export const lastNameValidator = Yup.string()
  .min(0)
  .max(20);

export const nickValidator = Yup.string()
  .required()
  .min(4)
  .max(50);

export const phoneValidator = Yup.string()
  .min(0)
  .max(15);

export const formValidator = Yup.object()
  .shape({
    firstName: firstNameValidator,
    lastName: lastNameValidator,
    phone: phoneValidator,
    nick:nickValidator
  });
