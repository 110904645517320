import {Feature, Layer} from 'react-mapbox-gl';
import React from 'react';
import {Feature as PointFeature} from '@turf/helpers/lib/geojson';
import {observer} from 'mobx-react-lite';

import {useTheme} from '@material-ui/core/styles';

import {PointInterface} from 'interfaces/PointInterface';

type Props = {
  wayPoints: PointFeature<PointInterface>[];
  id: string;
  before: string;
}

const directionLayerPointLayout = {
  'text-field': '{index}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0],
  'text-anchor': 'center',
};

export const WayPointNumberLayer: React.FC<Props> = observer(({wayPoints, id, before}) => {
  const theme = useTheme();
  return (<Layer
    id={id}
    before={before}
    type="symbol"
    paint={{
      'text-color': theme.palette.common.white,
    }}
    layout={directionLayerPointLayout}
  >
    {wayPoints.map((wayPoint, index) =>
      (<Feature
        properties={{index: index + 1}}
        coordinates={wayPoint?.geometry?.coordinates ?? [0, 0]}
        key={`feature${index}`}
      />))
    }
  </Layer>);
});
