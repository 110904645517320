import React, {useLayoutEffect, useMemo, useState} from 'react';

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import {flexContainerCenter, positionFixed} from 'shared/const/stylesDef';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    ...flexContainerCenter,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.common.white,
  },
}));

type Props = {
  styles?: any;
  children: any,
};

type ScreenDimensions = {
  height: undefined | number;
  width: undefined | number;
}
export const ContainerFullSize: React.FC<Props> = ({children, styles}) => {
  const [dimension, setDimensions] = useState<ScreenDimensions>({width: 0, height: 0});
  const classes = useStyles();

  useLayoutEffect(() => {
    setDimensions({width: window.innerWidth, height: window.innerHeight});
  }, []);

  const containerStyle = useMemo(() => {
    return {
      ...dimension,
      ...positionFixed,
    };
  }, [dimension]);

  return (
    <div
      style={{...styles, ...containerStyle}}
      className={classes.container}>
      {children}
    </div>
  );
};
