import React, {useCallback, useContext, useEffect, useState} from 'react';

import {profileResource, saveProfileRequest} from '../shared/requests/profile.resource';
import Log from '../shared/utils/Log';
import {doNothing} from '../shared/utils/functionsUtils';
import {useToggle} from '../hooks/useToggle';
import {useLogoutListener} from '../hooks/useLogoutListener';

import {AuthContext} from './AuthWrapper';

export interface ProfileInterface {
  _id?: string;
  firstName: string;
  lastName: string;
  phone: string;
  nick: string;
  directory: string;
  avatar?: string;
  unit: string;
  language: string;
}

export const DEFAULT_LANGUAGE = 'en';

const initialUserData: UserProfileInterface = {
  profile: {
    firstName: '',
    lastName: '',
    phone: '',
    nick: '',
    directory: '',
    unit: '',
    language: DEFAULT_LANGUAGE,
  },
  updateProfileProperty: doNothing,
  saveProfile: doNothing,
  loadDefaultProfileData: doNothing,
  formTouched: false,
  touched: doNothing,
  untouched: doNothing,
};

interface UserProfileInterface {
  profile: ProfileInterface;
  updateProfileProperty: (propertyName: string, propertyValue: string) => void;
  saveProfile: () => void;
  loadDefaultProfileData: () => void;
  formTouched: boolean;
  touched: () => void;
  untouched: () => void;
}

export const UserContext = React.createContext<UserProfileInterface>(initialUserData);

export const UserWrapper = ({children}) => {
  const [profile, setProfile] = useState<ProfileInterface>(initialUserData.profile);
  const [formTouched, {on: touched, off: untouched}] = useToggle(false);
  const [defaultProfileData, setDefaultProfile] = useState<ProfileInterface>(initialUserData.profile);
  const {isAuth} = useContext(AuthContext);

  useEffect(() => {
    const getUserProfileData = async () => {
      const userData: ProfileInterface = await profileResource();
      setDefaultProfile(currentProfile => ({...currentProfile, ...userData}));
      setProfile(currentProfile => ({...currentProfile, ...userData}));
      Log.info('user profile data loaded');
    };
    isAuth && getUserProfileData();
  }, [isAuth]);

  const updateProfileProperty = useCallback((propertyName, propertyValue) => {
    setProfile({...profile, [propertyName]: propertyValue});
  }, [profile]);

  const saveProfile = useCallback(async () => {
    await saveProfileRequest(profile);
  }, [profile]);

  const loadDefaultProfileData = useCallback(() => {
    setProfile(defaultProfileData);
  }, [defaultProfileData]);

  const onLogout = useCallback(() => {
    setProfile(initialUserData.profile);
    setDefaultProfile(initialUserData.profile);
  }, []);

  useLogoutListener(onLogout);

  return <UserContext.Provider value={{
    profile,
    updateProfileProperty,
    saveProfile,
    loadDefaultProfileData,
    formTouched,
    touched,
    untouched,
  }}>{children}</UserContext.Provider>;
};
