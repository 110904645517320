import {randomString} from '../../functions/randomString';

export enum NotificationType {
  info = 'info',
  error = 'error',
  success = 'success'
}

export interface NotificationPayload {
  id?: string;
  name: string;
  type: NotificationType;
  message: string;
  timeout?: number;
}

export const EVENT_NOTIFY = 'notify';

export const dispatchEventMessages = (payload: NotificationPayload): void => {
  payload.id = randomString();
  if (!payload.timeout) {
    switch (payload.type) {
      case NotificationType.success :
        payload.timeout = 3000;
        break;
      case NotificationType.error:
        payload.timeout = 5000;
        break;
      case NotificationType.info:
        payload.timeout = 1500;
        break;
    }
  }

  document.dispatchEvent(new CustomEvent(EVENT_NOTIFY, {
    detail: {payload},
  }));
};
