import React from 'react';

import {WeatherModalWrapper} from '../shared/components/weather/WeatherMapPointModal/WeatherMapPointModal';
import {SearchAddressProvider} from '../shared/components/Address/SearchAddressProvider';

import {AuthWrapper} from './AuthWrapper';
import {UserWrapper} from './UserWrapper';
import {OverlayWrapper} from './OverlayWrapper';
import {CurrentWeatherWrapper} from './CurrentWeatherWrapper';
import {UserLocationWrapper} from './UserLocationWrapper';
import {CurrentHomeForecastProvider} from './CurrentHomeForecastProvider';
import {DrawersProvider} from './DrawersProvider';

const AppProvider = ({children}) => {
  return (
    <AuthWrapper>
      <UserWrapper>
        <UserLocationWrapper>
          <CurrentWeatherWrapper>
            <WeatherModalWrapper>
              <CurrentHomeForecastProvider>
                <OverlayWrapper>
                  <DrawersProvider>
                    <SearchAddressProvider>
                      {children}
                    </SearchAddressProvider>
                  </DrawersProvider>
                </OverlayWrapper>
              </CurrentHomeForecastProvider>
            </WeatherModalWrapper>
          </CurrentWeatherWrapper>
        </UserLocationWrapper>
      </UserWrapper>
    </AuthWrapper>
  );
};

export default AppProvider;
