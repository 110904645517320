import {Badge, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {useMemo} from 'react';

import Button from '@material-ui/core/Button';
import Zoom from '@material-ui/core/Zoom';

import {flexContainerCenter} from 'shared/const/stylesDef';

import {buttonWidth, radius} from './mapContextMenu';
import buttonCoords from './ButtonsPositions';

type Props = {
  click: () => void;
  deg: number;
  icon?: any;
  disabled?: boolean;
  text?: string;
  component?: any;
}

const BUTTON_DIMENSION = 46;
const BADGE_DIMENSION = 27;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      ...flexContainerCenter,
      minWidth: BADGE_DIMENSION+ 15 + 'px',
      minHeight: BADGE_DIMENSION + 'px',
      position: 'absolute',
      top: '-18px',
      left: '-10px',
      backgroundColor: theme.palette.common.white,
      borderRadius: Math.round(BADGE_DIMENSION/2),
      border: `1px solid ${theme.palette.grey.A100}`,
    },
    button: {
      position: 'absolute',
      backgroundColor: theme.palette.common.white,
      width: BUTTON_DIMENSION + 'px',
      minWidth: BUTTON_DIMENSION + 'px',
      height: BUTTON_DIMENSION + 'px',
      borderRadius: BUTTON_DIMENSION / 2 + 'px',
    },
  }));

export const ContextMenuButton: React.FC<Props> = ({click, deg = 0, icon, disabled = false, text, component}) => {
  const classes = useStyles();
  const coords = useMemo(() => {
    return new buttonCoords({radius, buttonWidth});
  }, []);

  return (
    <Zoom in timeout={500}>
      <Button
        onClick={click}
        variant="outlined"
        disabled={disabled}
        className={classes.button}
        style={{
          ...coords.pointCords(deg),
        }}
      >
        {icon && icon}
        {text && <Badge className={classes.badge}>{text}</Badge>}
        {component && <Badge className={classes.badge}>{component}</Badge>}
      </Button>
    </Zoom>
  );
};
