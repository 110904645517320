import React, {useEffect, useMemo} from 'react';
import {Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, Zoom} from '@material-ui/core';
import {observer} from 'mobx-react-lite';

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';

import HomeIcon from '@material-ui/icons/Home';
import MoreHorIcon from '@material-ui/icons/MoreHoriz';

import {Feature} from '@turf/turf';

import {PointInterface} from '../../../interfaces/PointInterface';
import AddressDisplay from '../Address/AddressDisplay';
import WeatherIcon from '../weather/WeatherIcon/WeatherIcon';
import Temp from '../weather/WeatherTemp/WeatherTemp';
import {flexContainer, flexContainerCenterRow, fullHeight} from '../../const/stylesDef';
import {WeatherDescription} from '../weather/WeatherDescription/WeatherDescription';
import {useRootStore} from '../../stores/RootStore';
import {isForecastIsOutdated} from '../../utils/timeUtils';
import {selectWeatherPoint} from '../../../pages/maps/MapEvents';
import If from '../If/If';

import {CardMenu} from './CardMenu';

export interface FavoritePlaceInterface {
  point: Feature<PointInterface>;
  _id: string;
  defaultStartingPoint?: boolean;
  index?: number;
}

const FavoritePlaceCard: React.FC<FavoritePlaceInterface> = observer((
  {
    point,
    _id,
    index,
    defaultStartingPoint = false,
  }) => {
  const {favoritePlacesStore} = useRootStore();
  const classes = useStyles();

  const shouldUpdateForecast = useMemo(() => {
    if (!point?.properties?.forecastLastUpdateDate) {
      return true;
    }

    return isForecastIsOutdated(point.properties.forecastLastUpdateDate);
  }, [point?.properties?.forecastLastUpdateDate]);

  useEffect(() => {
    if (shouldUpdateForecast) {
      favoritePlacesStore.updatePointForecast(_id);
    }
  }, [_id, favoritePlacesStore, shouldUpdateForecast]);

  const onClick = () => {
    favoritePlacesStore.setSelectedPlace(_id);
  };

  const onShowMoreClick = () => {
    selectWeatherPoint(point);
  };

  return (
    <>
      <Card onClick={onClick} className={classes.container}>
        <CardHeader
          className={`${classes.header} ${index === favoritePlacesStore._selectedPlace ? classes.selected : null}`}
          avatar={
            <Avatar>
              <WeatherIcon icon={point?.properties?.forecast[0].weather[0].icon}/>
            </Avatar>
          }
          title={
            <Temp
              temp={point?.properties?.forecast[0].main.temp}
              showLabel={false}
              variant='h6'
              ignoreColors={true}
            />
          }

          action={
            <CardMenu
              point={point}
              _id={_id}
              defaultStartingPoint={defaultStartingPoint}
            />
          }
        />
        <CardContent className={classes.cardContent}>
          <AddressDisplay
            addressInfo={point?.properties?.addressInfo}
            count={2}
            variant='subtitle2'
          />
          <Divider className={classes.divider}/>
          <Zoom in={true} timeout={600}>
            <WeatherDescription description={point?.properties?.forecast[0].weather[0].description}/>
          </Zoom>
        </CardContent>

        <CardActions className={classes.actions}>
          <Zoom in={true} timeout={800}>
            <div className={classes.actionButtonContainer}>
              <If condition={defaultStartingPoint}>
                <IconButton size="small" color={'primary'}>
                  <HomeIcon/>
                </IconButton>
              </If>
              <IconButton size="small" onClick={onShowMoreClick}>
                <MoreHorIcon/>
              </IconButton>
            </div>
          </Zoom>
        </CardActions>
      </Card>
    </>
  );
});

const useStyles = makeStyles((theme: Theme) => createStyles({
  actionButtonContainer: {
    backgroundColor: '#FFF',
    borderRadius: '24px',
  },
  header: {
    backgroundColor: theme.palette.info.light,
  },
  actions: {
    ...flexContainerCenterRow,
    flex: 1,
  },
  cardContent: {
    flexDirection: 'column',
    display: 'flex',
    flex: 3,
  },
  cardWeatherList: {
    flexDirection: 'row',
    display: 'flex',
    flex: 2,
  },
  selected: {
    backgroundColor: theme.palette.info.main,
  },
  divider: {
    marginTop: '8px',
    marginBottom: '8px',
    backgroundColor: theme.palette.info.light,
  },
  container: {
    ...fullHeight,
    ...flexContainer,
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
      cursor: 'pointer',
    },
  },
}));

export default FavoritePlaceCard;
