import React, {useContext, useMemo} from 'react';
import {Feature, Layer} from 'react-mapbox-gl';

import {PointContext} from '../../MapProviders/MapPointProvider';
import {useMapBoxTheme} from '../LayerPointStyle';
import {onRouteClick} from '../../MapEvents';

import {DirectionLayerPointContext} from './DirectionLayerProvider';

export const DirectionLayerWayRouteWayPoint: React.FC = () => {
  const mapBoxTheme = useMapBoxTheme();
  const {coordinates} = useContext(DirectionLayerPointContext);
  const {selectedPointIndex} = useContext((PointContext));

  const isPointSelected = useMemo(() => {
    return selectedPointIndex === undefined;
  }, [selectedPointIndex]);

  const onClick = () => {
    onRouteClick(coordinates as number[]);
  };

  return (!!coordinates && isPointSelected) ? (
    <Layer
      type="circle"
      paint={mapBoxTheme.circlePaintHoover}
      onClick={onClick}
    >
      <Feature
        coordinates={coordinates}
        draggable
      />
    </Layer>
  ) : null;
};
