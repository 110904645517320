import {fuelIcon, homeIcon, tintIcon} from 'shared/const/icons';

export const svgIcon = (icon: string): HTMLElement => {
  const image = new Image();
  image.src = 'data:image/svg+xml;charset=utf-8;base64,' + btoa(icon);
  return image;
};

export const home = ['home-icon', svgIcon(homeIcon)];
export const tint = ['tint', svgIcon(tintIcon)];
export const fuel = ['fuel-icon', svgIcon(fuelIcon)];
