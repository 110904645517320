import React, {useState} from 'react';
import {useToggle} from 'hooks/useToggle';

import {doNothing} from '../../utils/functionsUtils';

export enum AddingPointType {
  AddPointToMap = 'AddPointToMap',
  AddToFavorites = 'AddToFavorites',
}

export interface ModalAddressOptions {
  label?: string;
  addingType: AddingPointType;
}

export interface AddressModalContextInterface {
  visible: boolean;
  show: () => void;
  hide: () => void;
  setOptions: (options: ModalAddressOptions) => void;
  options: ModalAddressOptions
}

const initialAddresshModalData: AddressModalContextInterface = {
  visible: false,
  show: doNothing,
  hide: doNothing,
  setOptions: doNothing,
  options: {
    addingType: AddingPointType.AddPointToMap,
  },
};

export const SearchAddressContext = React.createContext<AddressModalContextInterface>(initialAddresshModalData);

export const SearchAddressProvider: React.FC = ({children}) => {
  const [visible, {on: show, off: hide}] = useToggle(false);
  const [options, setOptionsValues] = useState(initialAddresshModalData.options);

  const setOptions = (options: ModalAddressOptions) => {
    setOptionsValues(options);
  };

  return (
    <SearchAddressContext.Provider value={{visible, show, hide, options, setOptions}}>
      {children}
    </SearchAddressContext.Provider>
  );
};
