import React from 'react';
import {useToggle} from 'hooks/useToggle';
import {observer} from 'mobx-react-lite';

import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from 'shared/components/ConfirmDialog/ConfirmDialog';
import {useRootStore} from 'shared/stores/RootStore';

import ToolbarButton from './ToolbarButton';

const ClearMapButton: React.FC = () => {
  const [open, {on, off}] = useToggle(false);
  const {directionsStore, wayPointsStore, fuelStationsStore} = useRootStore();

  const openDialog = () => {
    on();
  };

  const onClose = () => {
    off();
  };

  const clearMap = () => {
    wayPointsStore.clearAll();
    directionsStore.clearAll();
    fuelStationsStore.clearAll()
    onClose();
  };

  return (
    <>
      <ToolbarButton onClick={openDialog} isDisabled={wayPointsStore.mergedPoints.length === 0}>
        <DeleteIcon/>
      </ToolbarButton>
      <ConfirmDialog
        title="Confirm"
        open={open}
        okLabel="ok"
        cancelLabel="cancel"
        onConfirm={clearMap}
        onClose={onClose}
      >
        <span>Are you sure to clean up route ?</span>
      </ConfirmDialog>
    </>
  );
};

export default observer(ClearMapButton);
