import {Theme} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import {useCallback, useMemo} from 'react';

export const lineLayout = {
  'line-cap': 'round',
  'line-join': 'round',
};

const lineWidthBase = 1;
const lineGapBase = 1;

const lineWidthRange = [
  [6, 2],
  [9, 4],
  [10, 5],
  [12, 6],
];

const lineGapRange = [
  [9, 1],
  [10, 2],
];

const lineProps = {
  'line-gap-width': {
    base: lineGapBase,
    stops: lineGapRange,
  },
  'line-opacity': 1,
  'line-width': {
    base: lineWidthBase,
    stops: lineWidthRange,
  },
};


export const routeSelectedHover = {
  ...lineProps,
  'line-opacity': 0,
  'line-gap-width': 20,
};

export const tankPoint = {
  'circle-radius': {
    base: 3,
    stops: [
      [5, 5],
      [8, 10],
    ],
  },
  'circle-stroke-color': '#000',
  'circle-stroke-width': 1,
  'circle-color': '#fff',
};


const defaultStyle = {
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-offset': [0, 0],
  'text-anchor': 'center',
};

export const directionLayerPointLayout = {
  'text-field': '{index}',
  ...defaultStyle,
};

export const countUsersLayout = {
  'text-field': '{count}',
  ...defaultStyle,
};

export const useMapBoxTheme = () => {
  const theme = useTheme();

  const paintTankPointsIcon = (theme: Theme) => ({
    'icon-color': theme.palette.primary.main,
    'icon-halo-color': theme.palette.common.white,
    'icon-halo-width': 2,
  });

  const mapPointPaint = (theme: Theme) => ({
    'text-color': theme.palette.common.white,
  });

  const paintHomeIcon = (theme: Theme) => ({
    'icon-color': theme.palette.common.white,
    'icon-halo-color': theme.palette.primary.light,
    'icon-halo-width': 2,
  });

  const circlePaintHoover = (theme: Theme) => ({
    'circle-radius': {
      base: 10,
      stops: [
        [8, 10],
        [12, 8],
      ],
    },
    'circle-color': theme.palette.common.white,
    'circle-stroke-color': theme.palette.primary.dark,
    'circle-stroke-width': lineProps['line-width'],
  });

  const circlePaint = (theme: Theme) => ({
    'circle-radius': {
      base: 3,
      stops: [
        [5, 5],
        [6, 7],
        [13, 10],
        [17, 18],
      ],
    },
    'circle-stroke-width': {
      base: 3,
      stops: [
        [4, 5],
        [8, 6],
      ],
    },
    'circle-color': theme.palette.primary.light,
    'circle-stroke-color': theme.palette.primary.main,
  });

  const selectionPoint = useCallback((theme: Theme) => ({
    ...circlePaint(theme),
    'circle-radius': {
      base: 3,
      stops: [
        [5, 8],
        [6, 10],
        [13, 16],
        [17, 23],
      ],
    },
    'circle-opacity': 0.1,
    'circle-color': theme.palette.info.light,
    'circle-stroke-color': theme.palette.secondary.main,
  }), []);

  const alternativeRoute = (theme: Theme) => ({
    ...lineProps,
    'line-opacity': 0.6,
    'line-color': theme.palette.primary.light,
  });

  const routeSelected = (theme: Theme) => ({
    ...lineProps,
    'line-color': theme.palette.primary.main,
  });

  return useMemo(() => {
    return {
      alternativeRoute: alternativeRoute(theme),
      routeSelected: routeSelected(theme),
      paintHomeIcon: paintHomeIcon(theme),
      circlePaintHoover: circlePaintHoover(theme),
      circlePaint: circlePaint(theme),
      selectionPoint: selectionPoint(theme),
      mapPointPaint: mapPointPaint(theme),
      paintTankPointsIcon: paintTankPointsIcon(theme),
    };
  }, [selectionPoint, theme]);
};
