import {action, computed, makeObservable, observable} from 'mobx';
import {Observable, Subscription} from 'rxjs';

import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {DirectionsWayPointsStoreInterface} from 'interfaces/stores/DirectionsWayPointsStoreInterface';
import {DurationType, RouteCalculationType, RouteInterface, RoutesInterface} from 'interfaces/RoutesInterface';

import {postDirection} from '../requests/direction.requests';
import MapBoxHandler from '../../pages/maps/MapBoxHandler';
import Log from '../utils/Log';

class DirectionsStore {
  private readonly rootStore: RootStoreInterface<any>;
  wayPointsStore: DirectionsWayPointsStoreInterface<any>;
  _duration: DurationType = [];
  _selectedRouteIndex = 0;
  _timingDirection = [];
  _routesIsSet = false;
  _uuid: string | undefined = undefined;
  _routesCollection: RouteInterface[] = [];

  subscription: Subscription | undefined;
  subscriptionRoutePart;

  constructor(rootStore: RootStoreInterface<any>) {
    this.rootStore = rootStore;
    this.wayPointsStore = rootStore.wayPointsStore;
    makeObservable(this, {
      _duration: observable,
      _selectedRouteIndex: observable,
      _timingDirection: observable,
      _routesIsSet: observable,
      _uuid: observable,
      _routesCollection: observable,
      routeIsSet: computed,
      setRouteSet: action,
      createRouteDirections: action,
      routesCollection: computed,
      selectRoute: action,
      selectedRouteIndex: computed,
      selectedRoute: computed,
      isRouteSet: computed,
      routesCount: computed,
      routes: computed,
      uuid: computed,
      clearAll: action,
      clearRoutes: action,
      loadRoutes: action,
    });
    this.selectRoute(0);
  }

  private directionStream$ = (wayPoints: number[][], alternatives = true): Observable<any> =>
    new Observable(observer => {
      postDirection(wayPoints, alternatives)
        .then(response => {
          observer.next(response);
          observer.complete();
        })
        .catch(error => {
          Log.warn(error);
          observer.error(error);
        });
    });

  get routeIsSet(): boolean {
    return this._routesIsSet;
  }

  setRouteSet(value: boolean) {
    this._routesIsSet = value;
  }

  private extendRoute(route: RouteInterface) {
    const eachRoute = (existingRoute: RouteInterface) => {
      existingRoute.geometry.coordinates = [
        ...existingRoute.geometry.coordinates,
        ...route.geometry.coordinates,
      ];
      existingRoute.properties.legs[0].annotation.duration = [
        ...existingRoute.properties.legs[0].annotation.duration,
        ...route.properties.legs[0].annotation.duration,
      ];
      existingRoute.properties.distance = existingRoute.properties.distance + route.properties.distance;
      existingRoute.properties.duration = existingRoute.properties.duration + route.properties.duration;
    };

    this.rootStore.wayPointsStore.forEachRoute(eachRoute);
  }

  createRouteDirections(calculationType?: RouteCalculationType) {
    const wayPoints: number[][] = this.wayPointsStore.wayPointsCordsMap;
    this._routesIsSet = false;

    const onComplete = () => {
      // recalculation arriving time and route to the point;
      this.wayPointsStore.recalculateWayPointsDistances();
      this.subscription?.unsubscribe();
    };

    const catchPostDirectionError = (error): void => {
      Log.error(error);
    };

    switch (calculationType) {
      case RouteCalculationType.AddRouteAtTheEnd:
        this.subscription = this.directionStream$(wayPoints.slice(wayPoints.length - 2), false)
          .subscribe({
            next: ({routes}) => {
              this.extendRoute(routes[0]);
            },
            complete: onComplete,
            error: catchPostDirectionError,
          });
        break;
      //calculate route for two way points;
      default:
      case RouteCalculationType.Initial:
        this.subscription = this.directionStream$(wayPoints)
          .subscribe({
            next: ({uuid, routes}) => {
              this.loadRoutes({uuid, routes});
            },
            complete: onComplete,
            error: catchPostDirectionError,
          });
        break;
    }
  }

  get routesCollection(): RouteInterface[] {
    return this._routesCollection;
  }

  selectRoute(index: number): void {
    this._selectedRouteIndex = index;
  }

  get selectedRouteIndex(): number {
    return this._selectedRouteIndex;
  }

  get selectedRoute(): RouteInterface {
    return this.routesCollection[this.selectedRouteIndex];
  }

  get isRouteSet(): boolean {
    return this._uuid !== undefined;
  }

  get routesCount(): number {
    return this._routesCollection.length;
  }

  get routes(): RouteInterface[] {
    return this._routesCollection;
  }

  get uuid(): string {
    return this._uuid as string;
  }

  clearAll(): void {
    //@ts-ignore
    this.wayPointsStore._wayPoints.clear();
    this.clearRoutes();
  }

  clearRoutes(): void {
    // @ts-ignore
    this._routesCollection = [];
    this._routesIsSet = false;
    this._uuid = undefined;
    this.selectRoute(0);
    this.rootStore.fuelStationsStore.clearAll();
  }

  loadRoutes({routes, uuid}: RoutesInterface): void {
    this._routesCollection = routes;
    this._uuid = uuid;
    this.selectRoute(0);
    try {
      MapBoxHandler.fitToRouteBounds(this.rootStore.wayPointsStore.wayPoints);
    } catch (e) {
      Log.warn(e);
    }
  }
}

export default DirectionsStore;
