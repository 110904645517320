import React, {useReducer} from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {useRootStore} from 'shared/stores/RootStore';

interface Props {
  mapId?: string;
  open: boolean;
  onClose: () => void;
}


export type MapTitleType = {
  mapName: string;
  description: string;
}

const initialState: MapTitleType = {mapName: '', description: ''};

enum DispatchAction {
  SetTitle,
  SetDescription
}

type actionType = {
  type: DispatchAction;
  value?: string;
}

function reducer(state, action: actionType) {
  switch (action.type) {
    case DispatchAction.SetTitle:
      return {...state, mapName: action.value};
    case DispatchAction.SetDescription:
      return {...state, description: action.value};
    default:
      throw new Error();
  }
}

const MapTitleModal: React.FC<Props> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {userMapsStore} = useRootStore();

  const handleClose = () => {
    props.onClose();
  };

  const saveAndClose = async () => {
    userMapsStore.updateMapDescription(state);
    await userMapsStore.saveMap();
    handleClose();
  };

  const isNewMap = () => !props.mapId;

  const title = () => (isNewMap() ? 'Create new map' : 'Save Map as...');

  const onTitleChange = e => {
    e.persist();
    dispatch({type: DispatchAction.SetTitle, value: e.target.value});
  };

  const onDescriptionChange = e => {
    e.persist();
    dispatch({type: DispatchAction.SetDescription, value: e.target.value});
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>{title()}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          maybe you would like to add some information ?
        </DialogContentText>
        <TextField
          autoFocus
          id="title"
          name="title"
          label="Map title"
          type="text"
          fullWidth
          value={state.mapName}
          onChange={onTitleChange}
        />
      </DialogContent>
      <DialogContent>
        <TextField
          id="description"
          label="Description"
          type="text"
          fullWidth
          value={state.description}
          onChange={onDescriptionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={saveAndClose} color="primary" variant="outlined">
          {isNewMap() ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapTitleModal;
