import React, {ReactElement, useCallback, useContext} from 'react';
import {Theme, withStyles} from '@material-ui/core';
import {withRouter} from 'react-router';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {InjectedStylesInterface, StyleType} from 'interfaces/Styles';

import {DrawersContext} from '../../../providers/DrawersProvider';

const styles: StyleType = (theme: Theme) => ({
  activeLink: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    opacity: 1,
    '&:hover > * ': {
      color: 'inherits',
    },
    '& > *': {
      color: theme.palette.common.white,
    },
    '& > * > span': {
      color: theme.palette.common.white,
    },
  },
});

interface Props {
  label: string;
  icon: ReactElement;
  url: string;
  state?: any,
}

interface InjectedProps extends Props, InjectedStylesInterface {
  history: {
    push: (url: string, state: any) => void;
    location?: {
      pathname?: string;
    }
  }
}

const ListElement: React.FC<Props> = (props) => {
  const {label, icon, url, state = {}} = props;
  const {classes, history} = props as InjectedProps;
  const isActive = () => url === history?.location?.pathname;
  const {toggleMainDrawer} = useContext(DrawersContext);

  const goToPage = useCallback(() => {
    history.push(url, state);
    setTimeout(() => {
      toggleMainDrawer();
    }, 800);
  }, [history, state, toggleMainDrawer, url]);

  return (
    <ListItem
      button
      disabled={isActive()}
      onClick={goToPage}
      className={isActive() ? classes.activeLink : null}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText inset primary={label}/>
    </ListItem>
  );
};

export default withRouter(withStyles(styles)(ListElement));
