import {action, computed, observable} from 'mobx';

import {MakeInterface} from 'interfaces/motorcycles/MakeInterface';
import {MotorcycleInterface} from 'interfaces/stores/MotorcycleInterface';
import {RootStoreInterface} from 'interfaces/stores/RootStoreInterface';
import {UserMotorcycleInterface} from 'interfaces/stores/UserMotorcycleInterface';
import {YearsInterface} from 'interfaces/motorcycles/YearsInterface';

import {
  addImageResource,
  deleteMotorcyclePhotoRequest,
  deletetUserMotorcyclesResource,
  getUserMotorcyclesResource,
  updateMotorcycleDataRequest,
} from '../requests/motorcycles.resource';

class UserMotorcycleStore<T, ParamsType>
  implements UserMotorcycleInterface<T, ParamsType> {
  private readonly rootStore: RootStoreInterface<unknown>;
  @observable _userMotorcycles: MotorcycleInterface[] = [];
  @observable _saveFormData = false;
  @observable _selectedMotorcycle: MotorcycleInterface | undefined;
  @observable producers: MakeInterface[] = [];
  @observable years: YearsInterface[] = [];

  constructor(rootStore: RootStoreInterface<unknown>) {
    this.rootStore = rootStore;
  }

  @action
  setSelectedMotorcycle(id: string): void {
    this._selectedMotorcycle = this.findUserMotorcycle(id);
  }

  @computed
  get selectedMotorcycle(): MotorcycleInterface {
    return this._selectedMotorcycle as MotorcycleInterface;
  }

  @action.bound
  async addImage(images, _id) {
    const updatePhotos = (photos) => {
      const motorcycleData = this.findUserMotorcycle(_id);
      if (motorcycleData) {
        const existingPhotos = motorcycleData.photos;
        if (!motorcycleData.favouritePhotoIndex) {
          motorcycleData.favouritePhotoIndex = 0;
        }
        if (existingPhotos) {
          motorcycleData.photos = [...existingPhotos, ...photos];
        }
      }

      return this.updateMotorcycleData(motorcycleData);
    };

    const catchError = (err) => {
      console.log(err);
    };

    return await addImageResource(images, _id)
      .then(updatePhotos)
      .catch(catchError);
  }

  @action
  async updateMotorcycleDataByIndex(index: number) {
    const data = this._userMotorcycles[index];
    await this.updateMotorcycleData(data);
  }

  @action
  async updateMotorcycleData(data) {
    return await updateMotorcycleDataRequest(data, data._id);
  }

  @action updateStoreData(data) {
    const motorcycleData = this.findUserMotorcycle(data._id);
    Object.keys(data).forEach((key) => {
      motorcycleData[key] = data[key];
    });
  }

  private findUserMotorcycle(id): MotorcycleInterface {
    return this._userMotorcycles.find(
      ({_id}) => _id === id,
    ) as MotorcycleInterface;
  }

  private findIndex(id) {
    return this._userMotorcycles.findIndex(({_id}) => _id === id);
  }

  @action
  async deleteImage(motorcycleId, filename) {
    const motorcycleData = this.findUserMotorcycle(motorcycleId);

    const removePhoto = (motorcycleData) => (filename: string) => {
      const index = motorcycleData.photos.indexOf(filename);
      motorcycleData.photos.splice(index, 1);
    };

    const catchError = () => {
      return false;
    };

    const updateData = () => {
      removePhoto(motorcycleData)(filename);
      return this.updateMotorcycleData(motorcycleData).then(notifySuccess);
    };

    const notifySuccess = () => {
      return true;
    };

    if (motorcycleData) {
      return deleteMotorcyclePhotoRequest(filename)
        .then(updateData)
        .catch(catchError);
    } else {
      return false;
    }
  }

  @action
  setIndex(index, motorcycleId) {
    this._userMotorcycles[this.findIndex(motorcycleId)].currentIndex = index;
  }

  @action
  async getUserMotorcycles() {
    this._userMotorcycles = await this.fetchUserMotorcycles();
  }

  fetchUserMotorcycles = async () => {
    const data = await getUserMotorcyclesResource();

    data.map((item) => {
      if (item.modelData.length) {
        const {Make, Model, Year} = item.modelData[0];
        item.Make = Make;
        item.Year = Year;
        item.Model = Model;
      }
      if (!item.photos) {
        item.photos = [];
      }

      if (item.favouritePhotoIndex === '') {
        item.favouritePhotoIndex = 0;
      }

      if (item.photos.length > 1 && item.favouritePhotoIndex > 0) {
        item.currentIndex = item.favouritePhotoIndex;
      } else {
        item.currentIndex = 0;
      }

      return item;
    });

    if (data.length === 1) {
      if (!data[0].isFavourite) {
        data[0].isFavourite = true;
      }
    }

    return data;
  };

  @computed
  get userMotorcycles() {
    return this._userMotorcycles;
  }

  @action
  isProducerAvailable(producer) {
    return this.producers.filter((e) => e._id === producer);
  }

  @action
  isYearAvailable(year) {
    return this.years.filter((e) => e._id === year);
  }

  @action
  async deleteUserMotorcycle(id) {
    await deletetUserMotorcyclesResource(id);
    await this.getUserMotorcycles();
  }

  @action
  toggleFavourite(id: string) {
    const favourite = this.userMotorcycles.find(
      (motorcycle) => motorcycle.isFavourite === true,
    );

    const updateData = (data) =>
      this.updateMotorcycleData(data);

    const setFavourite = () => {
      const moto = this.findUserMotorcycle(id);
      moto.isFavourite = true;
      updateData(moto);
    };

    if (favourite) {
      favourite.isFavourite = false;
      updateData(favourite).then(setFavourite);
    } else {
      setFavourite();
    }
  }

  @action
  async markAsFavouritePhoto(id: string, index: number) {
    const moto = this.findUserMotorcycle(id);
    moto.favouritePhotoIndex = index;
    await this.updateMotorcycleData(moto);
  }

  @action
  saveForm(save) {
    this._saveFormData = save;
  }

  @computed
  get favouriteUserMotorcycle() {
    return this.findFavouriteUserMotorcycle();
  }

  private findFavouriteUserMotorcycle(): MotorcycleInterface {
    return this._userMotorcycles.find(
      (motorcycle) => motorcycle.isFavourite === true,
    ) as MotorcycleInterface;
  }

  private calculateRange = ({tankCapacity, fuelConsumption}) =>
    Math.round((tankCapacity / fuelConsumption) * 100);

  @computed
  get selectedUserMotorcycleRange() {
    const favourite = this.findFavouriteUserMotorcycle();
    return favourite ? this.calculateRange(favourite) : 200;
  }
}

export default UserMotorcycleStore;
